/* eslint-disable quotes */
/* eslint-disable */
/* eslint-disable prefer-template */
/* eslint-disable max-len */
// react
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

// third-party
import { Helmet } from 'react-helmet-async';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import axios from 'axios';
import { MyApiUrl } from '../services/service';

// application
import PageHeader from '../shared/PageHeader';

// blocks
// import BlockMap from '../blocks/BlockMap';

// data stubs
import theme from '../../data/theme';

function SitePageAboutUs() {
    const history = useHistory();
    const breadcrumb = [
        { title: 'Home', url: '' },
        { title: 'Career', url: '' },
    ];
    const myStyle = {
        page: {
            marginLeft: 0,
            marginRight: 0,
            backgroundColor: '#f7f7f7',
            borderRadius: 10,
        },
        para: {
            margiTop: 5,
            marginBottom: 0,
            fontSize: 13,
            color: 'gray',
        },
    };

    const [CareerForm, setCareerForm] = useState({
        FirstName: '',
        LastName: '',
        Email: '',
        PhoneNumber: '',
        DoB: '',
        YearsOfExp: '',
        StreetAddress: '',
        Appartment: '',
        city: '',
        state: '',
        country: '',
        zipcode: '',
        AcademicBackground: '',
        ArtisticSkill: '',
        Character: '',
        Ambition: '',
        EmotionalStability: '',
        AbilityToworkwithOthers: '',
        CommunicationSkills: '',
        CoverLetter: '',
        Confirmation: '',
        CheckConfirmed: '',
    });

    const SubmitForm = () => {
        const ValidEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
        if (CareerForm.FirstName === '' || CareerForm.FirstName == null) {
            toast.warn('Please Enter First Name!', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
            });
        } else if (CareerForm.LastName === '' || CareerForm.LastName == null) {
            toast.warn('Please Enter Last Name!', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
            });
        } else if (CareerForm.Email === '' || CareerForm.Email == null) {
            toast.warn('Please Enter Email Address!', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
            });
        } else if (ValidEmail.test(CareerForm.Email) === false) {
            toast.warn('Please Enter Valid Email!', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
            });
        } else if (CareerForm.PhoneNumber === '' || CareerForm.PhoneNumber == null) {
            toast.warn('Please Enter Phone Number!', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
            });
        } else if (CareerForm.PhoneNumber.length < 10 || CareerForm.PhoneNumber.length >10) {
            toast.warn('Phone Number Should Be 10 Digits!', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
            });
        } else if (CareerForm.DoB === '' || CareerForm.DoB == null) {
            toast.warn('Please Select Date Of Birth!', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
            });
        } else if (CareerForm.YearsOfExp === '' || CareerForm.YearsOfExp == null) {
            toast.warn('Please Select No of Year Experience!', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
            });
        } else if (CareerForm.StreetAddress === '' || CareerForm.StreetAddress == null) {
            toast.warn('Please Enter Street Address!', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
            });
        } else if (CareerForm.Appartment === '' || CareerForm.Appartment == null) {
            toast.warn('Please Enter Apartment Details!', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
            });
        } else if (CareerForm.city === '' || CareerForm.city == null) {
            toast.warn('Please Enter City!', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
            });
        } else if (CareerForm.state === '' || CareerForm.state == null) {
            toast.warn('Please Enter State!', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
            });
        } else if (CareerForm.country === '' || CareerForm.country == null) {
            toast.warn('Please Enter Country!', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
            });
        } else if (CareerForm.zipcode === '' || CareerForm.zipcode == null) {
            toast.warn('Please Enter Zip Code!', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
            });
        } else if (CareerForm.AcademicBackground === '' || CareerForm.AcademicBackground == null) {
            toast.warn('Please select Academic Background level!', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
            });
        } else if (CareerForm.ArtisticSkill === '' || CareerForm.ArtisticSkill == null) {
            toast.warn('Please select Artistic Skill level!', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
            });
        } else if (CareerForm.Character === '' || CareerForm.Character == null) {
            toast.warn('Please select Character level!', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
            });
        } else if (CareerForm.Ambition === '' || CareerForm.Ambition == null) {
            toast.warn('Please select Ambition level!', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
            });
        } else if (CareerForm.EmotionalStability === '' || CareerForm.EmotionalStability == null) {
            toast.warn('Please select Emotional Stability level!', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
            });
        } else if (CareerForm.AbilityToworkwithOthers === '' || CareerForm.AbilityToworkwithOthers == null) {
            toast.warn('Please select Ability To work with Others level!', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
            });
        } else if (CareerForm.CommunicationSkills === '' || CareerForm.CommunicationSkills == null) {
            toast.warn('Please select Communication Skills!', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
            });
        } else if (CareerForm.CoverLetter === '' || CareerForm.CoverLetter == null) {
            toast.warn('Please Enter Cover Letter!', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
            });
        } else if (CareerForm.CheckConfirmed === '' || CareerForm.CheckConfirmed == null) {
            toast.warn('Please Confirm the Check Box Before Submit!', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
            });
        } else {
            axios.post(`${MyApiUrl}Career/`, CareerForm).then((response) => {
                if (response.data === '1' || response.data === 1) {
                    toast.success('Your Details Are Submited!', {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'dark',
                    });
                    setCareerForm({
                        ...CareerForm,
                        FirstName: '',
                        LastName: '',
                        Email: '',
                        PhoneNumber: '',
                        DoB: '',
                        YearsOfExp: '',
                        StreetAddress: '',
                        Appartment: '',
                        city: '',
                        state: '',
                        country: '',
                        zipcode: '',
                        AcademicBackground: '',
                        ArtisticSkill: '',
                        Character: '',
                        Ambition: '',
                        EmotionalStability: '',
                        AbilityToworkwithOthers: '',
                        CommunicationSkills: '',
                        CoverLetter: '',
                        Confirmation: '',
                        CheckConfirmed: '',
                    });
                    history.push('/site/SitePageCareer');
                } else if (response.data === '2' || response.data === 2) {
                    toast.error('Failed!', {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'dark',
                    });
                } else {
                    toast.warn('Your Email Is Already Exist!', {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'dark',
                    });
                }
            });
        }
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Career — ${theme.name}`}</title>
            </Helmet>
            <PageHeader header="Career" breadcrumb={breadcrumb} />
            <div className="block">
                <div className="container">
                    <div className="card mb-1" style={myStyle.page}>
                        <div className="card-header" style={{ borderBottom: '1px solid #c1c1c1' }}>
                            <div className="row">
                                <div className="col-md-12">
                                    <h3 style={{ textAlign: 'center' }}>Career Application Form</h3>
                                    <p style={{ textAlign: 'center' }}>
                                        Please fill out the form below to apply for a job
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="card-body contact-us" style={{ padding: '2rem 3rem 2rem 3rem' }}>
                            <div className="contact-us__container">
                                <div className="row">
                                    <h4 style={{ color: '#0484cc' }}>General Details</h4>
                                    <div
                                        className="col-md-12"
                                        style={{ border: '1px solid #c1c1c1', borderRadius: 10, paddingTop: 15 }}
                                    >
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="checkout-first-name" className="designLabel">
                                                    First Name
                                                    <span style={{ color: 'red' }}> *</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control designInputs"
                                                    id="checkout-first-name"
                                                    placeholder="Enter Your First Name"
                                                    value={CareerForm.FirstName}
                                                    onChange={(event) => {
                                                        const re = /^[A-Z a-z]+$/;
                                                        if (event.target.value === "" || re.test(event.target.value)) {
                                                            setCareerForm({
                                                                ...CareerForm,
                                                                FirstName: event.target.value,
                                                            });
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="checkout-last-name" className="designLabel">
                                                    Last Name
                                                    <span style={{ color: 'red' }}> *</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control designInputs"
                                                    id="checkout-last-name"
                                                    placeholder="Enter Your Last Name"
                                                    value={CareerForm.LastName}
                                                    onChange={(event) => {
                                                        const re = /^[A-Z a-z]+$/;
                                                        if (event.target.value === "" || re.test(event.target.value)) {
                                                            setCareerForm({
                                                                ...CareerForm,
                                                                LastName: event.target.value,
                                                            });
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <label htmlFor="checkout-email" className="designLabel">
                                                    Email Address
                                                    <span style={{ color: 'red' }}> *</span>
                                                </label>
                                                <input
                                                    type="email"
                                                    className="form-control designInputs"
                                                    id="checkout-email"
                                                    placeholder="Enter Your Email Address"
                                                    value={CareerForm.Email}
                                                    onChange={(event) => {
                                                        setCareerForm({
                                                            ...CareerForm,
                                                            Email: event.target.value,
                                                        });
                                                    }}
                                                />
                                                <p style={myStyle.para}>
                                                    We will never share your email with anyone else.
                                                </p>
                                            </div>
                                        </div>

                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="checkout-No" className="designLabel">
                                                    Phone Number
                                                    <span style={{ color: 'red' }}> *</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control designInputs"
                                                    id="checkout-No"
                                                    placeholder="Enter Your Phone Number"
                                                    value={CareerForm.PhoneNumber}
                                                    onChange={(event) => {
                                                        const re = /^[0-9]+$/;
                                                        if (event.target.value === "" || re.test(event.target.value)) {
                                                            setCareerForm({
                                                                ...CareerForm,
                                                                PhoneNumber: event.target.value,
                                                            });
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="checkout-DOB" className="designLabel">
                                                    Date Of Birth
                                                    <span style={{ color: 'red' }}> *</span>
                                                </label>
                                                <input
                                                    type="date"
                                                    value={CareerForm.DoB}
                                                    onChange={(event) => {
                                                        setCareerForm({
                                                            ...CareerForm,
                                                            DoB: event.target.value,
                                                        });
                                                    }}
                                                    className="form-control designInputs"
                                                    id="checkout-DOB"
                                                />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <label htmlFor="checkout-No" className="designLabel">
                                                    How many years of experience do you have?
                                                    <span style={{ color: 'red' }}> *</span>
                                                </label>
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <input
                                                            type="radio"
                                                            name="exp"
                                                            id="exp1"
                                                            value="0-1"
                                                            onChange={(event) => {
                                                                setCareerForm({
                                                                    ...CareerForm,
                                                                    YearsOfExp: event.target.value,
                                                                });
                                                            }}
                                                        />
                                                        <label htmlFor="exp1" style={{ marginLeft: 10 }} className="designInputs">
                                                            {' '}
                                                            0 - 1
                                                        </label>
                                                        <br />
                                                        <input
                                                            type="radio"
                                                            name="exp"
                                                            id="exp2"
                                                            value="3-5"
                                                            onChange={(event) => {
                                                                setCareerForm({
                                                                    ...CareerForm,
                                                                    YearsOfExp: event.target.value,
                                                                });
                                                            }}
                                                        />
                                                        <label htmlFor="exp2" style={{ marginLeft: 10 }} className="designInputs">
                                                            {' '}
                                                            3 - 5
                                                        </label>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <input
                                                            type="radio"
                                                            name="exp"
                                                            id="exp3"
                                                            value="1-3"
                                                            onChange={(event) => {
                                                                setCareerForm({
                                                                    ...CareerForm,
                                                                    YearsOfExp: event.target.value,
                                                                });
                                                            }}
                                                        />
                                                        <label htmlFor="exp3" style={{ marginLeft: 10 }} className="designInputs">
                                                            {' '}
                                                            1 - 3
                                                        </label>
                                                        <br />
                                                        <input
                                                            type="radio"
                                                            name="exp"
                                                            id="exp4"
                                                            value="5+"
                                                            onChange={(event) => {
                                                                setCareerForm({
                                                                    ...CareerForm,
                                                                    YearsOfExp: event.target.value,
                                                                });
                                                            }}
                                                        />
                                                        <label htmlFor="exp4" style={{ marginLeft: 10 }} className="designInputs">
                                                            {' '}
                                                            5 +
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <h4 style={{ color: '#0484cc', marginTop: 25 }}>Address</h4>
                                    <div
                                        className="col-md-12"
                                        style={{ border: '1px solid #c1c1c1', borderRadius: 10, paddingTop: 15 }}
                                    >
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="checkout-street-address" className="designLabel">
                                                    Street Address
                                                    <span style={{ color: 'red' }}> *</span>
                                                </label>
                                                <textarea
                                                    className="form-control designInputs"
                                                    id="checkout-street-address"
                                                    placeholder="Enter Your Street Address"
                                                    value={CareerForm.StreetAddress}
                                                    onChange={(event) => {
                                                        setCareerForm({
                                                            ...CareerForm,
                                                            StreetAddress: event.target.value,
                                                        });
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="checkout-address" className="designLabel">
                                                    Apartment, suite, unit etc.
                                                    <span style={{ color: 'red' }}> *</span>
                                                </label>
                                                <textarea
                                                    className="form-control designInputs"
                                                    id="checkout-address"
                                                    value={CareerForm.Appartment}
                                                    onChange={(event) => {
                                                        setCareerForm({
                                                            ...CareerForm,
                                                            Appartment: event.target.value,
                                                        });
                                                    }}
                                                    placeholder="Enter Your Apartment, suite, unit etc."
                                                />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="checkout-city" className="designLabel">
                                                    Town / City
                                                    <span style={{ color: 'red' }}> *</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control designInputs"
                                                    id="checkout-city"
                                                    value={CareerForm.city}
                                                    onChange={(event) => {
                                                        setCareerForm({
                                                            ...CareerForm,
                                                            city: event.target.value,
                                                        });
                                                    }}
                                                    placeholder="Enter Your Town / City"
                                                />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="checkout-state" className="designLabel">
                                                    State / County
                                                    <span style={{ color: 'red' }}> *</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control designInputs"
                                                    id="checkout-state"
                                                    value={CareerForm.state}
                                                    onChange={(event) => {
                                                        setCareerForm({
                                                            ...CareerForm,
                                                            state: event.target.value,
                                                        });
                                                    }}
                                                    placeholder="Enter Your State / County"
                                                />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="checkout-Country" className="designLabel">
                                                    Country
                                                    <span style={{ color: 'red' }}> *</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control designInputs"
                                                    id="checkout-Country"
                                                    value={CareerForm.country}
                                                    onChange={(event) => {
                                                        setCareerForm({
                                                            ...CareerForm,
                                                            country: event.target.value,
                                                        });
                                                    }}
                                                    placeholder="Enter Your Country"
                                                />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="checkout-postcode" className="designLabel">
                                                    Postcode / ZIP
                                                    <span style={{ color: 'red' }}> *</span>
                                                </label>
                                                <input
                                                    type="number"
                                                    className="form-control designInputs"
                                                    id="checkout-postcode"
                                                    value={CareerForm.zipcode}
                                                    onChange={(event) => {
                                                        setCareerForm({
                                                            ...CareerForm,
                                                            zipcode: event.target.value,
                                                        });
                                                    }}
                                                    placeholder="Enter Your Postcode / ZIP"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <h4 style={{ color: '#0484cc', marginTop: 25 }}>Other Details</h4>
                                    <div
                                        className="col-md-12"
                                        style={{ border: '1px solid #c1c1c1', borderRadius: 10, paddingTop: 15 }}
                                    >
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <label htmlFor="checkout-cover" className="designLabel">
                                                    Please mark the appropriate box that best describes this candidate
                                                    <span style={{ color: 'red' }}> *</span>
                                                </label>
                                                <table
                                                    style={{ width: '100%', textAlign: 'center' }}
                                                    className="table table-bordered table-primary"
                                                >
                                                    <thead>
                                                        <th> </th>
                                                        <th>EXCELLENT</th>
                                                        <th>GOOD</th>
                                                        <th>AVERAGE</th>
                                                        <th>BELOW AVERAGE</th>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <th>Academic Background</th>
                                                            <td>
                                                                <input
                                                                    type="radio"
                                                                    name="aca1"
                                                                    value="Excellent"
                                                                    onChange={(event) => {
                                                                        setCareerForm({
                                                                            ...CareerForm,
                                                                            AcademicBackground: event.target.value,
                                                                        });
                                                                    }}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="radio"
                                                                    name="aca1"
                                                                    value="Good"
                                                                    onChange={(event) => {
                                                                        setCareerForm({
                                                                            ...CareerForm,
                                                                            AcademicBackground: event.target.value,
                                                                        });
                                                                    }}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="radio"
                                                                    name="aca1"
                                                                    value="Average"
                                                                    onChange={(event) => {
                                                                        setCareerForm({
                                                                            ...CareerForm,
                                                                            AcademicBackground: event.target.value,
                                                                        });
                                                                    }}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="radio"
                                                                    name="aca1"
                                                                    value="Below Average"
                                                                    onChange={(event) => {
                                                                        setCareerForm({
                                                                            ...CareerForm,
                                                                            AcademicBackground: event.target.value,
                                                                        });
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>Artistic Skill</th>
                                                            <td>
                                                                <input
                                                                    type="radio"
                                                                    name="aca2"
                                                                    value="Excellent"
                                                                    onChange={(event) => {
                                                                        setCareerForm({
                                                                            ...CareerForm,
                                                                            ArtisticSkill: event.target.value,
                                                                        });
                                                                    }}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="radio"
                                                                    name="aca2"
                                                                    value="Good"
                                                                    onChange={(event) => {
                                                                        setCareerForm({
                                                                            ...CareerForm,
                                                                            ArtisticSkill: event.target.value,
                                                                        });
                                                                    }}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="radio"
                                                                    name="aca2"
                                                                    value="Average"
                                                                    onChange={(event) => {
                                                                        setCareerForm({
                                                                            ...CareerForm,
                                                                            ArtisticSkill: event.target.value,
                                                                        });
                                                                    }}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="radio"
                                                                    name="aca2"
                                                                    value="Below Average"
                                                                    onChange={(event) => {
                                                                        setCareerForm({
                                                                            ...CareerForm,
                                                                            ArtisticSkill: event.target.value,
                                                                        });
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>Character</th>
                                                            <td>
                                                                <input
                                                                    type="radio"
                                                                    name="aca3"
                                                                    value="Excellent"
                                                                    onChange={(event) => {
                                                                        setCareerForm({
                                                                            ...CareerForm,
                                                                            Character: event.target.value,
                                                                        });
                                                                    }}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="radio"
                                                                    name="aca3"
                                                                    value="Good"
                                                                    onChange={(event) => {
                                                                        setCareerForm({
                                                                            ...CareerForm,
                                                                            Character: event.target.value,
                                                                        });
                                                                    }}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="radio"
                                                                    name="aca3"
                                                                    value="Average"
                                                                    onChange={(event) => {
                                                                        setCareerForm({
                                                                            ...CareerForm,
                                                                            Character: event.target.value,
                                                                        });
                                                                    }}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="radio"
                                                                    name="aca3"
                                                                    value="Below Average"
                                                                    onChange={(event) => {
                                                                        setCareerForm({
                                                                            ...CareerForm,
                                                                            Character: event.target.value,
                                                                        });
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>Ambition</th>
                                                            <td>
                                                                <input
                                                                    type="radio"
                                                                    name="aca4"
                                                                    value="Excellent"
                                                                    onChange={(event) => {
                                                                        setCareerForm({
                                                                            ...CareerForm,
                                                                            Ambition: event.target.value,
                                                                        });
                                                                    }}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="radio"
                                                                    name="aca4"
                                                                    value="Good"
                                                                    onChange={(event) => {
                                                                        setCareerForm({
                                                                            ...CareerForm,
                                                                            Ambition: event.target.value,
                                                                        });
                                                                    }}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="radio"
                                                                    name="aca4"
                                                                    value="Average"
                                                                    onChange={(event) => {
                                                                        setCareerForm({
                                                                            ...CareerForm,
                                                                            Ambition: event.target.value,
                                                                        });
                                                                    }}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="radio"
                                                                    name="aca4"
                                                                    value="Below Average"
                                                                    onChange={(event) => {
                                                                        setCareerForm({
                                                                            ...CareerForm,
                                                                            Ambition: event.target.value,
                                                                        });
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>Emotional Stability</th>
                                                            <td>
                                                                <input
                                                                    type="radio"
                                                                    name="aca5"
                                                                    value="Excellent"
                                                                    onChange={(event) => {
                                                                        setCareerForm({
                                                                            ...CareerForm,
                                                                            EmotionalStability: event.target.value,
                                                                        });
                                                                    }}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="radio"
                                                                    name="aca5"
                                                                    value="Good"
                                                                    onChange={(event) => {
                                                                        setCareerForm({
                                                                            ...CareerForm,
                                                                            EmotionalStability: event.target.value,
                                                                        });
                                                                    }}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="radio"
                                                                    name="aca5"
                                                                    value="Average"
                                                                    onChange={(event) => {
                                                                        setCareerForm({
                                                                            ...CareerForm,
                                                                            EmotionalStability: event.target.value,
                                                                        });
                                                                    }}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="radio"
                                                                    name="aca5"
                                                                    value="Below Average"
                                                                    onChange={(event) => {
                                                                        setCareerForm({
                                                                            ...CareerForm,
                                                                            EmotionalStability: event.target.value,
                                                                        });
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>Ability To work with Others</th>
                                                            <td>
                                                                <input
                                                                    type="radio"
                                                                    name="aca6"
                                                                    value="Excellent"
                                                                    onChange={(event) => {
                                                                        setCareerForm({
                                                                            ...CareerForm,
                                                                            AbilityToworkwithOthers: event.target.value,
                                                                        });
                                                                    }}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="radio"
                                                                    name="aca6"
                                                                    value="Good"
                                                                    onChange={(event) => {
                                                                        setCareerForm({
                                                                            ...CareerForm,
                                                                            AbilityToworkwithOthers: event.target.value,
                                                                        });
                                                                    }}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="radio"
                                                                    name="aca6"
                                                                    value="Average"
                                                                    onChange={(event) => {
                                                                        setCareerForm({
                                                                            ...CareerForm,
                                                                            AbilityToworkwithOthers: event.target.value,
                                                                        });
                                                                    }}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="radio"
                                                                    name="aca6"
                                                                    value="Below Average"
                                                                    onChange={(event) => {
                                                                        setCareerForm({
                                                                            ...CareerForm,
                                                                            AbilityToworkwithOthers: event.target.value,
                                                                        });
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>Communication Skills</th>
                                                            <td>
                                                                <input
                                                                    type="radio"
                                                                    name="aca7"
                                                                    value="Excellent"
                                                                    onChange={(event) => {
                                                                        setCareerForm({
                                                                            ...CareerForm,
                                                                            CommunicationSkills: event.target.value,
                                                                        });
                                                                    }}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="radio"
                                                                    name="aca7"
                                                                    value="Good"
                                                                    onChange={(event) => {
                                                                        setCareerForm({
                                                                            ...CareerForm,
                                                                            CommunicationSkills: event.target.value,
                                                                        });
                                                                    }}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="radio"
                                                                    name="aca7"
                                                                    value="Average"
                                                                    onChange={(event) => {
                                                                        setCareerForm({
                                                                            ...CareerForm,
                                                                            CommunicationSkills: event.target.value,
                                                                        });
                                                                    }}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="radio"
                                                                    name="aca7"
                                                                    value="Below Average"
                                                                    onChange={(event) => {
                                                                        setCareerForm({
                                                                            ...CareerForm,
                                                                            CommunicationSkills: event.target.value,
                                                                        });
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label htmlFor="checkout-cover" className="designLabel">
                                                    Cover Letter
                                                    <span style={{ color: 'red' }}> *</span>
                                                </label>
                                                <textarea
                                                    style={{ height: 250 }}
                                                    className="form-control designInputs"
                                                    id="checkout-cover"
                                                    value={CareerForm.CoverLetter}
                                                    onChange={(event) => {
                                                        setCareerForm({
                                                            ...CareerForm,
                                                            CoverLetter: event.target.value,
                                                        });
                                                    }}
                                                    placeholder="Enter Your Cover Letter Here"
                                                />
                                                <p style={myStyle.para}>
                                                    Please tell us why you are a good fit for this position and why you
                                                    want to work for us.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer">
                            <div className="form-row">
                                <div className="form-group col-md-12">
                                    <input
                                        type="checkbox"
                                        onChange={(event) => {
                                            setCareerForm({
                                                ...CareerForm,
                                                CheckConfirmed: event.target.value,
                                            });
                                        }}
                                        value="YES"
                                        name="consent"
                                        id="consent"
                                    />
                                    <label htmlFor="consent" style={{ marginLeft: 10 }} className="designLabel">
                                        I consent to the processing of my personal data for the purposes of this job
                                        application.
                                    </label>
                                </div>
                            </div>
                            <div className="form-group mb-0">
                                <button className="btn btn-primary" onClick={SubmitForm} type="button">
                                    Submit Application
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default SitePageAboutUs;
