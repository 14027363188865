/* eslint-disable import/prefer-default-export */
/* eslint-disable max-len */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable no-undef */
// eslint-disable-next-line quotes
// import axios from "axios";
// import { MyApiUrl } from '../../components/services/service';
// eslint-disable-next-line quotes
import { makeIdGenerator } from "../utils";
import brandsData from "./brands";
import { categoriesListData, prepareCategory } from "./categories";

const getId = makeIdGenerator();

const attributesDef = [
    {
        name: "Color",
        slug: "color",
        values: [
            { name: "White", slug: "white" },
            { name: "Silver", slug: "silver" },
            { name: "Light Gray", slug: "light-gray" },
            { name: "Gray", slug: "gray" },
            { name: "Dark Gray", slug: "dark-gray" },
            { name: "Coal", slug: "coal" },
            { name: "Black", slug: "black" },
            { name: "Red", slug: "red" },
            { name: "Orange", slug: "orange" },
            { name: "Yellow", slug: "yellow" },
            { name: "Pear Green", slug: "pear-green" },
            { name: "Green", slug: "green" },
            { name: "Emerald", slug: "emerald" },
            { name: "Shamrock", slug: "shamrock" },
            { name: "Shakespeare", slug: "shakespeare" },
            { name: "Blue", slug: "blue" },
            { name: "Dark Blue", slug: "dark-blue" },
            { name: "Violet", slug: "violet" },
            { name: "Purple", slug: "purple" },
            { name: "Cerise", slug: "cerise" },
        ],
    },
    {
        name: "Speed",
        slug: "speed",
        values: [{ name: "750 RPM", slug: "750-rpm" }],
    },
    {
        name: "Power Source",
        slug: "power-source",
        values: [{ name: "Cordless-Electric", slug: "cordless-electric" }],
    },
    {
        name: "Battery Cell Type",
        slug: "battery-cell-type",
        values: [{ name: "Lithium", slug: "lithium" }],
    },
    {
        name: "Voltage",
        slug: "voltage",
        values: [{ name: "20 Volts", slug: "20-volts" }],
    },
    {
        name: "Battery Capacity",
        slug: "battery-capacity",
        values: [{ name: "2 Ah", slug: "2-Ah" }],
    },
];

// eslint-disable-next-line import/prefer-default-export

// export const fetchData = async () => {
//     let response;
//     try {
//         response = await axios.get(`${MyApiUrl}Products`);
//     } catch (e) {
//         throw new Error(e.message);
//     }
//     console.log(response.data);
//     return Promise.resolve(response.data.map((productDef) => {
//         let badges = [];

//         if (productDef.badges) {
//             badges = typeof productDef.badges === "string" ? [productDef.badges] : productDef.badges;
//         }

//         const categories = categoriesListData
//             .filter((category) => productDef.categories.includes(category.slug))
//             .map((category) => prepareCategory(category));

//         const attributes = (productDef.attributes || [])
//             .map((productAttributeDef) => {
//                 const attributeDef = attributesDef.find((x) => x.slug === productAttributeDef.slug);

//                 if (!attributeDef) {
//                     return null;
//                 }

//                 let valuesDef = [];

//                 if (typeof productAttributeDef.values === "string") {
//                     valuesDef = [productAttributeDef.values];
//                 } else if (productAttributeDef.values) {
//                     valuesDef = productAttributeDef.values;
//                 }

//                 const values = valuesDef
//                     .map((valueSlug) => {
//                         const valueDef = attributeDef.values.find((x) => x.slug === valueSlug);

//                         if (!valueDef) {
//                             return null;
//                         }

//                         return {
//                             ...valueDef,
//                             customFields: {},
//                         };
//                     })
//                     .filter((x) => x !== null);

//                 if (!values.length) {
//                     return null;
//                 }

//                 return {
//                     name: attributeDef.name,
//                     slug: attributeDef.slug,
//                     featured: !!productAttributeDef.featured,
//                     values,
//                     customFields: {},
//                 };
//             })
//             .filter((x) => x !== null);

//         return {
//             id: getId(),
//             name: productDef.name,
//             sku: "83690/32",
//             slug: productDef.slug,
//             price: productDef.price,
//             description: productDef.description,
//             compareAtPrice: productDef.compareAtPrice || null,
//             images: productDef.images.slice(),
//             badges: badges.slice(),
//             rating: productDef.rating,
//             reviews: productDef.reviews,
//             availability: productDef.availability,
//             specification: productDef.specification,
//             brand: brandsData.find((x) => x.slug === productDef.brand) || null,
//             categories,
//             attributes,
//             customFields: {},
//         };
//     }));
// };

const productsDef = [
    {
        slug: "Vitamin D31",
        name: "Vitamin D31",
        desc: "Vitamin D31",
        price: 0,
        description:
            "Globally, 50% of the population has vitamin D insufficiency. AA Probics vitamin D gummies increase calcium absorption for strong bones and teeth.",
        images: ["images/products/product-1.jpg", "images/products/product-1-1.jpg"],
        badges: "new",
        rating: 5,
        reviews: 2,
        availability: "in-stock",
        brand: "brandix",
        categories: ["Human Nutrition"],
        specification: [
            {
                name: "General",
                features: [
                    { name: "Active Ingredients", value: "Vitamin D3" },
                    { name: "Each Gummy Contains", value: "850IU" },
                    { name: "Serving Size", value: "Adults : 1 gummy/day" },
                    { name: "Available Flavours", value: "Berry" },
                    { name: "Available Shapes", value: "Gum Drop and Coin Shape" },
                ],
            },
            {
                name: "Features",
                features: [
                    { name: "Stronger Teeth", value: "" },
                    { name: "Helps build Muscle Strength", value: "" },
                    { name: "Helps in Calcium Absorption", value: "" },
                ],
            },
        ],
    },
    {
        slug: "Curcumin",
        name: "Curcumin",
        desc: "Vitamin D3",
        price: 0,
        description:
            "Management inflammatory conditions as curcumin has natural anti-inflammatory phytonutrient. Low level inflammatory conditions can cause degenerative conditions, metabolic syndrome, Alzheimer.",
        images: ["images/products/product-2.jpg", "images/products/product-2-1.jpg"],
        badges: "new",
        rating: 4,
        reviews: 1,
        availability: "in-stock",
        brand: "brandix",
        categories: ["Human Nutrition"],
        specification: [
            {
                name: "General",
                features: [
                    { name: "Active Ingredients", value: "Curcumin C-3 Complex" },
                    { name: "Each Gummy Contains", value: "500mg" },
                    { name: "Serving Size", value: "Adults : 1 gummy/day" },
                    { name: "Available Flavours", value: "Mango" },
                    { name: "Available Shapes", value: "Gum Drop and Coin Shape" },
                ],
            },
            {
                name: "Features",
                features: [
                    { name: "Enhances Brain Function", value: "" },
                    { name: "Supports Immunity", value: "" },
                ],
            },
        ],
    },
    {
        slug: "Apple Cider Vinegar",
        name: "Apple Cider Vinegar",
        desc: "Vitamin D3",
        price: 0,
        description: "Aids in visceral weight loss, Improves GI in blood, Detoxifies the body.",
        images: ["images/products/product-3.jpg", "images/products/product-3-1.jpg"],
        badges: "new",
        rating: 4,
        reviews: 5,
        availability: "in-stock",
        brand: "brandix",
        categories: ["Human Nutrition"],
        specification: [
            {
                name: "General",
                features: [
                    { name: "Active Ingredients", value: "Apple Cider Vinegar" },
                    { name: "Each Gummy Contains", value: "500mg" },
                    { name: "Serving Size", value: "Adults : 1 gummy/day" },
                    { name: "Available Flavours", value: "Ginger-Lemon" },
                    { name: "Available Shapes", value: "Gum Drop and Coin Shape" },
                ],
            },
            {
                name: "Features",
                features: [
                    { name: "Rich in Antioxidanth", value: "" },
                    { name: "Weight Loss", value: "" },
                    { name: "Promotes Overall Health", value: "" },
                ],
            },
        ],
    },
    {
        slug: "Kokum (Garcinia)",
        name: "Kokum (Garcinia)",
        desc: "Vitamin D3",
        price: 0,
        description: "Kokum has Hydroxy citric acid that aids in fast metabolism and in turn weight management.",
        images: ["images/products/product-4.jpg", "images/products/product-4-1.jpg"],
        badges: "new",
        rating: 4,
        reviews: 10,
        availability: "in-stock",
        brand: "brandix",
        categories: ["Human Nutrition"],
        specification: [
            {
                name: "General",
                features: [
                    { name: "Active Ingredients", value: "HCA – Hydroxy Citric Acid" },
                    { name: "Each Gummy Contains", value: "500mg" },
                    { name: "Serving Size", value: "Adults : 1 gummy/day" },
                    { name: "Available Flavours", value: "Tamarind" },
                    { name: "Available Shapes", value: "Gum Drop and Coin Shape" },
                ],
            },
            {
                name: "Features",
                features: [
                    { name: "Helps in Weight-loss", value: "" },
                    { name: "Enhances Immunity", value: "" },
                    { name: "Diabetic Friendly", value: "" },
                ],
            },
        ],
    },
    {
        slug: "Probiotics",
        name: "Probiotics",
        desc: "Vitamin D3",
        price: 0,
        description: "Keeps your gut healthy, boost immunity, improves skin quality.",
        images: ["images/products/product-5.jpg", "images/products/product-5-1.jpg"],
        badges: "new",
        rating: 4,
        reviews: 0,
        availability: "in-stock",
        brand: "brandix",
        categories: ["Human Nutrition"],
        specification: [
            {
                name: "General",
                features: [
                    { name: "Active Ingredients", value: "B.Coagulans" },
                    { name: "Each Gummy Contains", value: "500 million cfu/gm" },
                    { name: "Serving Size", value: "Adults : 2 gummy/day" },
                    { name: "Available Flavours", value: "Orange" },
                    { name: "Available Shapes", value: "Gum Drop and Coin Shape" },
                ],
            },
            {
                name: "Features",
                features: [
                    { name: "Keep Your Gut Healthy", value: "" },
                    { name: "Enhances Immunity", value: "" },
                    { name: "Improves Skin Quality", value: "" },
                ],
            },
        ],
    },
    {
        slug: "Omega 3",
        name: "Omega 3",
        desc: "Vitamin D3",
        price: 0,
        description:
            "Omega-3 fatty acid is a type of polyunsaturated fatty acid contains alpha-linolenic acid (ALA), linolenic acid (ALA), linolenic acid (ALA), linolic acid and oleic acid.",
        images: ["images/products/product-6.jpg", "images/products/product-6-1.jpg"],
        badges: "new",
        rating: 3,
        reviews: 6,
        availability: "in-stock",
        brand: "brandix",
        categories: ["Human Nutrition"],
        specification: [
            {
                name: "General",
                features: [
                    { name: "Active Ingredients", value: "Omega" },
                    { name: "Omega", value: "500mg" },
                    { name: "Serving Size", value: "Adults : 1 gummy/day" },
                    { name: "Available Flavours", value: "Coffee" },
                    { name: "Available Shapes", value: "Gum Drop and Coin Shape" },
                ],
            },
            {
                name: "Features",
                features: [
                    { name: "Reduce Fatty Liver", value: "" },
                    { name: "Improves Mental Health", value: "" },
                    { name: "Improves Overall Wellbeing", value: "" },
                ],
            },
        ],
    },
    {
        slug: "Biotin",
        name: "Biotin",
        desc: "Vitamin D3",
        price: 0,
        description: "Biotin Known to maintain hair growth and helps with inflammation.",
        images: ["images/products/product-7.jpg", "images/products/product-7-1.jpg"],
        badges: "new",
        rating: 4,
        reviews: 0,
        availability: "in-stock",
        brand: "brandix",
        categories: ["Human Nutrition"],
        specification: [
            {
                name: "General",
                features: [
                    { name: "Active Ingredients", value: "Biotin" },
                    { name: "Each Gummy Contains", value: "30mcg" },
                    { name: "Serving Size", value: "Adults : 1 gummy/day" },
                    { name: "Available Flavours", value: "Guava" },
                    { name: "Available Shapes", value: "Bear Shape" },
                ],
            },
            {
                name: "Features",
                features: [
                    { name: "Improves Hair Follicle", value: "" },
                    { name: "Improves Nails Quality", value: "" },
                    { name: "Improves Skin Quality", value: "" },
                ],
            },
        ],
    },
    {
        slug: "Multi Vitamin",
        name: "Multi Vitamin",
        desc: "Vitamin D3",
        price: 0,
        description: "Keeps your gut healthy, boost immunity, improves skin quality.",
        images: ["images/products/product-8.jpg", "images/products/product-8-1.jpg"],
        badges: "new",
        rating: 4,
        reviews: 0,
        availability: "in-stock",
        brand: "brandix",
        categories: ["Human Nutrition"],
        specification: [
            {
                name: "General",
                features: [
                    { name: "Active Ingredients", value: "Multi Vitamin Mixture" },
                    { name: "Each Gummy Contains", value: "13.3gm" },
                    { name: "Serving Size", value: "Adults : 1 gummy/day" },
                    { name: "Available Flavours", value: "Strawberry" },
                    { name: "Available Shapes", value: "Gum Drop and Coin Shape" },
                ],
            },
            {
                name: "Features",
                features: [
                    { name: "Keep Your Gut Healthy", value: "" },
                    { name: "Enhances Immunity", value: "" },
                    { name: "Improves Skin Quality", value: "" },
                ],
            },
        ],
    },
];

const productsData = productsDef.map((productDef) => {
    let badges = [];

    if (productDef.badges) {
        badges = typeof productDef.badges === "string" ? [productDef.badges] : productDef.badges;
    }

    const categories = categoriesListData
        .filter((category) => productDef.categories.includes(category.slug))
        .map((category) => prepareCategory(category));

    const attributes = (productDef.attributes || [])
        .map((productAttributeDef) => {
            const attributeDef = attributesDef.find((x) => x.slug === productAttributeDef.slug);

            if (!attributeDef) {
                return null;
            }

            let valuesDef = [];

            if (typeof productAttributeDef.values === "string") {
                valuesDef = [productAttributeDef.values];
            } else if (productAttributeDef.values) {
                valuesDef = productAttributeDef.values;
            }

            const values = valuesDef
                .map((valueSlug) => {
                    const valueDef = attributeDef.values.find((x) => x.slug === valueSlug);

                    if (!valueDef) {
                        return null;
                    }

                    return {
                        ...valueDef,
                        customFields: {},
                    };
                })
                .filter((x) => x !== null);

            if (!values.length) {
                return null;
            }

            return {
                name: attributeDef.name,
                slug: attributeDef.slug,
                featured: !!productAttributeDef.featured,
                values,
                customFields: {},
            };
        })
        .filter((x) => x !== null);

    return {
        id: getId(),
        name: productDef.name,
        sku: "83690/32",
        slug: productDef.slug,
        price: productDef.price,
        description: productDef.description,
        compareAtPrice: productDef.compareAtPrice || null,
        images: productDef.images.slice(),
        badges: badges.slice(),
        rating: productDef.rating,
        reviews: productDef.reviews,
        availability: productDef.availability,
        specification: productDef.specification,
        brand: brandsData.find((x) => x.slug === productDef.brand) || null,
        categories,
        attributes,
        customFields: {},
    };
});

export default productsData;
