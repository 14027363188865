// react
/* eslint-disable */
/* eslint-disable prefer-template */
/* eslint-disable max-len */
/* eslint linebreak-style: ["error", "windows"] */
// react
import React, { useState } from 'react';
import axios from 'axios';
import { MyApiUrl, ViewImg } from '../services/service';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link, useLocation, useHistory } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Currency from '../shared/Currency';
// data stubs
import theme from '../../data/theme';
import { Rating } from 'react-simple-star-rating';
import '../../scss/Feedback.css';

export default function AccountPageGiveFeedback() {
    const history = useHistory();
    const location = useLocation();
    const orderDetails = location.state?.orderDetails;
    const UserID = localStorage.getItem("UserID");
    const [ProductData, setProductData] = useState([]);

    const [FeedbackFields, setFeedbackFields] = useState({
        ProductID: '-1',
        Rating: '0',
        Review: '',
    });

    const GetAddress = async () => {
        await axios.get(MyApiUrl + "GetProductsForReviews/" + orderDetails.ORDERS_PKID + "/" + UserID + "").then((response) => {
            console.log(response);
            if (response.data.length > 0) {
                const CountryOption = response.data.map((item) => (
                    <option value={item.PRODUCT_PKID}>{item.PRODUCT_NAME}</option>
                ));
                setProductData(CountryOption);
            }
            else {
                setProductData([]);
            }
        });
    };

    React.useEffect(() => {
        GetAddress();
    }, []);
    const postReview = () => {
        if (FeedbackFields.ProductID === '-1' || FeedbackFields.ProductID === null) {
            toast.warn('Please Select Product!', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
            });
        } else if (FeedbackFields.Rating === '0' || FeedbackFields.Rating === null) {
            toast.warn('Please Select Rating!', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
            });
        } else if (FeedbackFields.Review === '' || FeedbackFields.Review === null) {
            toast.warn('Please Enter Review!', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
            });
        } else {
            const obj = {
                PRODUCT_REVIEWS_USER_FKID: UserID,
                PRODUCT_REVIEWS_PRODUCT_FKID: FeedbackFields.ProductID,
                PRODUCT_REVIEWS_STAR_RATINGS: FeedbackFields.Rating,
                PRODUCT_REVIEWS_COMMENTS: FeedbackFields.Review,
            };
            axios.post(MyApiUrl + "addUserReviews", obj).then((response) => {
                if (response.data == true || response.data == "true") {
                    toast.success('Feedback & Reviews Posted Successfully!', {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'dark',
                    });
                    history.goBack();
                } else if (response.data == false || response.data == "false") {
                    toast.error('Failed To Post Feedback & Reviews!', {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'dark',
                    });
                }
            });
        }
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Order Details — ${theme.name}`}</title>
            </Helmet>
            <div className="card">
                <div className="card-header">
                    <h5 style={{ float: 'left' }}>Give Feeback & Reviews</h5>
                    <Link
                        to={{
                            pathname: "/account/orderDetails",
                            state: { orderDetails: orderDetails },
                        }}
                        className="btn btn-xs btn-secondary"
                        style={{ float: 'right' }}
                    >
                        Back to Order Details
                    </Link>
                </div>
                <div className="card-body" style={{ padding: '0px 50px' }}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="register-email" className="designLabel">
                                    Product&nbsp;
                                    <span style={{ color: 'red' }}>*</span>
                                </label>
                                <select
                                    className="form-control designInputs"
                                    value={FeedbackFields.ProductID}
                                    onChange={(event) => {
                                        setFeedbackFields({
                                            ...FeedbackFields,
                                            ProductID: event.target.value,
                                        });
                                    }}
                                >
                                    <option value="-1">Select Product</option>
                                    {ProductData}
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="review-stars" className="designLabel">
                                    Review Stars&nbsp;
                                    <span style={{ color: 'red' }}>*</span>
                                </label>
                                <div>
                                    <Rating
                                        onClick={(event) => {
                                            setFeedbackFields({
                                                ...FeedbackFields,
                                                Rating: event,
                                            });
                                        }}
                                    /* Available Props */
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="review-text" className="designLabel">
                                    Your Review&nbsp;
                                    <span style={{ color: 'red' }}>*</span>
                                </label>
                                <textarea
                                    className="form-control designInputs"
                                    id="review-text"
                                    rows="6"
                                    value={FeedbackFields.Review}
                                    onChange={(event) => {
                                        setFeedbackFields({
                                            ...FeedbackFields,
                                            Review: event.target.value,
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12" style={{ marginBottom: 10 }}>
                            <button
                                type="button"
                                className="btn btn-primary"
                                style={{ float: 'right' }}
                                onClick={postReview}
                            >
                                Post Your Review
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}