/* eslint-disable array-callback-return */
/* eslint-disable max-len */
/* eslint-disable quotes */
// react
import React, { useState } from "react";

// third-party
import classNames from 'classnames';
import { connect } from 'react-redux';
import axios from "axios";
import { MyApiUrl } from "../services/service";

// application
import MobileLinks from './MobileLinks';
import { Cross20Svg } from '../../svg';
import { currencyChange } from '../../store/currency';
import { localeChange } from '../../store/locale';
import { mobileMenuClose } from '../../store/mobile-menu';

// data stubs
import currencies from '../../data/shopCurrencies';
// import mobileMenuLinks from '../../data/mobileMenu';

function MobileMenu(props) {
    const {
        mobileMenuState,
        closeMobileMenu,
        changeLocale,
        changeCurrency,
    } = props;

    const [ScienceType, setScienceType] = useState([]);

    const GetAboutUs = async () => {
        await axios({
            method: "GET",
            url: `${MyApiUrl}ScienceTypeWebTwo`,
            headers: {
                "content-type": "application/json",
            },
        })
            .then((response) => {
                setScienceType(response.data);
                console.log(ScienceType);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    React.useEffect(() => {
        GetAboutUs();
    }, []);

    const mobileMenuLinks = [
        {
            type: 'link',
            label: 'Home',
            url: '/',
        },

        {
            type: 'link',
            label: 'About Us',
            url: '/site/about-us',
        },

        {
            type: 'link',
            label: 'Our Products',
            url: '/Products/OurProducts',
        },

        {
            type: 'link',
            label: 'Science',
            children: ScienceType,
        },
        {
            type: 'link',
            label: 'Career',
            url: '/site/SitePageCareer',
        },

        {
            type: 'link',
            label: 'Contact',
            url: '/site/contact-us',
        },
    ];

    const classes = classNames('mobilemenu', {
        'mobilemenu--open': mobileMenuState.open,
    });

    const handleItemClick = (item) => {
        if (item.data) {
            if (item.data.type === 'language') {
                changeLocale(item.data.locale);
                closeMobileMenu();
            }
            if (item.data.type === 'currency') {
                const currency = currencies.find((x) => x.currency.code === item.data.code);

                if (currency) {
                    changeCurrency(currency.currency);
                    closeMobileMenu();
                }
            }
        }
        if (item.type === 'link') {
            closeMobileMenu();
        }
    };

    return (
        <div className={classes}>
            {/* eslint-disable-next-line max-len */}
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
            <div className="mobilemenu__backdrop" onClick={closeMobileMenu} />
            <div className="mobilemenu__body">
                <div className="mobilemenu__header">
                    <div className="mobilemenu__title">Menu</div>
                    <button type="button" className="mobilemenu__close" onClick={closeMobileMenu}>
                        <Cross20Svg />
                    </button>
                </div>
                <div className="mobilemenu__content">
                    <MobileLinks links={mobileMenuLinks} onItemClick={handleItemClick} />
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    mobileMenuState: state.mobileMenu,
});

const mapDispatchToProps = {
    closeMobileMenu: mobileMenuClose,
    changeLocale: localeChange,
    changeCurrency: currencyChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);
