/* eslint-disable quotes */
/* eslint-disable */
/* eslint-disable prefer-template */
/* eslint-disable max-len */
// react
import { toast } from "react-toastify";
import {
    CART_ADD_ITEM,
    CART_REMOVE_ITEM,
    CART_UPDATE_QUANTITIES,
    CART_UPDATE_COUPONS,
    CART_REMOVE_COUPONS,
    EMPTY_CART_DETAILS,
} from "./cartActionTypes";

export function cartAddItemSuccess(product, options = [], quantity, UnitAmount, Unit, unitPkid, GeneralDiscount = []) {
    toast.success(`Product "${product.name}" added to cart!`, { theme: "colored" });

    return {
        type: CART_ADD_ITEM,
        product,
        options,
        quantity,
        UnitAmount,
        Unit,
        unitPkid,
        GeneralDiscount,
    };
}

export function cartRemoveItemSuccess(itemId, GeneralDiscount = []) {
    toast.success(`Product removed from cart!`, { theme: "colored" });
    return {
        type: CART_REMOVE_ITEM,
        itemId,
        GeneralDiscount,
    };
}
export function cartRemoveCouponSuccess() {
    toast.success(`Coupon Details Removed!`, { theme: "colored" });
    return {
        type: CART_REMOVE_COUPONS,
    };
}

export function cartUpdateQuantitiesSuccess(quantities) {
    toast.success(`Product Details Updated!`, { theme: "colored" });
    return {
        type: CART_UPDATE_QUANTITIES,
        quantities,
    };
}

export function cartUpdateCouponsSuccess(coupons, coupontype, couponpkid, couponcode) {
    return {
        type: CART_UPDATE_COUPONS,
        coupons,
        coupontype,
        couponpkid,
        couponcode,
    };
}

export function cartEmptyItemSuccess() {
    return {
        type: EMPTY_CART_DETAILS,
    };
}

export function cartAddItem(product, options = [], quantity, UnitAmount, Unit, unitPkid, GeneralDiscount = []) {
    // sending request to server, timeout is used as a stub
    return (dispatch) =>
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(cartAddItemSuccess(product, options, quantity, UnitAmount, Unit, unitPkid, GeneralDiscount));
                resolve();
            }, 500);
        });
}

export function cartRemoveItem(itemId, GeneralDiscount = []) {
    // sending request to server, timeout is used as a stub
    return (dispatch) =>
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(cartRemoveItemSuccess(itemId, GeneralDiscount));
                resolve();
            }, 500);
        });
}

export function cartUpdateQuantities(quantities) {
    // sending request to server, timeout is used as a stub
    return (dispatch) =>
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(cartUpdateQuantitiesSuccess(quantities));
                resolve();
            }, 500);
        });
}

export function cartUpdateCoupons(coupons, coupontype, couponpkid, couponcode) {
    // sending request to server, timeout is used as a stub
    return (dispatch) =>
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(cartUpdateCouponsSuccess(coupons, coupontype, couponpkid, couponcode));
                resolve();
            }, 500);
        });
}
export function cartRemoveCoupon() {
    // sending request to server, timeout is used as a stub
    return (dispatch) =>
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(cartRemoveCouponSuccess());
                resolve();
            }, 500);
        });
}
export function cartEmptyItem() {
    // sending request to server, timeout is used as a stub
    return (dispatch) =>
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(cartEmptyItemSuccess());
                resolve();
            }, 500);
        });
}
