export default [
    {
        id: 3,
        title: 'Certificate Of Recognition ( Vriddhi Learning Program )',
        image: 'images/posts/post-3.jpg',
        categories: ['New Arrivals'],
        date: 'February 20, 2023',
    },
    {
        id: 2,
        title: 'E - Commerce & Digital Marketing Training Program for Women Entrepreneurs',
        image: 'images/posts/post-2.jpg',
        categories: ['Latest News'],
        date: 'January 24, 2023',
    },
    {
        id: 1,
        title: 'The Digital Transformation Certificate',
        image: 'images/posts/post-1.jpg',
        categories: ['Special Offers'],
        date: 'January 23, 2023',
    },
    {
        id: 4,
        title: 'Business Women, Certificate By Business Sight Magazine',
        image: 'images/posts/post-4.jpg',
        categories: ['Special Offers'],
        date: 'July 30, 2019',
    },
    {
        id: 5,
        title: 'TiE Mysuru - Founder Series',
        image: 'images/posts/post-5.jpg',
        categories: ['New Arrivals'],
        date: 'June 12, 2019',
    },
    {
        id: 6,
        title: 'Women Achiever Award',
        image: 'images/posts/post-6.jpg',
        categories: ['Special Offers'],
        date: 'May 21, 2019',
    },
];
