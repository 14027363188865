/* eslint-disable quotes */
/* eslint-disable max-len */
// react
import React, { useState } from "react";

// third-party
import classNames from "classnames";
import PropTypes from "prop-types";

// application
// import ProductTabDescription from './ProductTabDescription';
import ProductTabSpecification from "./ProductTabSpecification";
import ProductTabReviews from "./ProductTabReviews";

const ProductTabs = (props) => {
    const { product, withSidebar } = props;
    console.log('product');
    console.log(product);
    const [currentTab, setcurrentTab] = useState("description");
    const setTab = (newTab) => {
        setcurrentTab(newTab);
    };

    const classes = classNames("product-tabs", {
        "product-tabs--layout--sidebar": withSidebar,
    });

    const descriptionData = `<div className="typography"><h3>Product Full Description</h3><p>${product.description}</p></div>`;
    const description = <div dangerouslySetInnerHTML={{ __html: descriptionData }} />;

    const tabs = [
        { key: "description", title: "Description", content: description },
        { key: "specification", title: "Specification", content: <ProductTabSpecification product={product} /> },
        { key: "reviews", title: "Reviews", content: <ProductTabReviews product={product} /> },
    ];

    const tabsButtons = tabs.map((tab) => {
        const classes = classNames("product-tabs__item", {
            "product-tabs__item--active": currentTab === tab.key,
        });

        return (
            <button key={tab.key} type="button" onClick={() => setTab(tab.key)} className={classes}>
                {tab.title}
            </button>
        );
    });

    const tabsContent = tabs.map((tab) => {
        const classes = classNames("product-tabs__pane", {
            "product-tabs__pane--active": currentTab === tab.key,
        });

        return (
            <div key={tab.key} className={classes}>
                {tab.content}
            </div>
        );
    });

    return (
        <div className={classes}>
            <div className="product-tabs__list">{tabsButtons}</div>
            <div className="product-tabs__content">{tabsContent}</div>
        </div>
    );
};

ProductTabs.propTypes = {
    withSidebar: PropTypes.bool,
};

ProductTabs.defaultProps = {
    withSidebar: false,
};

export default ProductTabs;
