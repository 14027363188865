// react
/* eslint-disable */
/* eslint-disable prefer-template */
/* eslint-disable max-len */
// react
import React, { useState } from 'react';
import axios from 'axios';
import { MyApiUrl, ViewImg } from '../services/service';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

// data stubs
import addresses from '../../data/accountAddresses';
import allOrders from '../../data/accountOrders';
import theme from '../../data/theme';

import Currency from '../shared/Currency';

export default function AccountPageDashboard() {
    const id = localStorage.getItem("UserID");
    const [ProfileName, setProfileName] = useState("");
    const [ProfileEmail, setProfileEmail] = useState("");
    const [ProfileImg, setProfileImg] = useState("");
    const [CardData, setCardData] = useState([]);
    const [OrderData, setOrderData] = useState([]);

    const getMonthName = (monthNumber) => {
        const date = new Date();
        date.setMonth(monthNumber - 1);

        return date.toLocaleString('en-US', { month: 'long' });
    }

    const SplitDate = (OrderDate) => {
        const MainDate = OrderDate.split("T");
        const SplitT = MainDate[0];
        const OrderDates = SplitT.split("-");
        const FinalDate = OrderDates[2] + " " + getMonthName(OrderDates[1]) + ", " + OrderDates[0];
        return FinalDate;
    }

    const GetProfile = () => {
        axios.get(MyApiUrl + "UserProfile/" + id + "").then((response) => {
            setProfileName(response.data[0].REGISTERED_USERS_NAME);
            setProfileEmail(response.data[0].REGISTERED_USERS_EMAIL);
            setProfileImg(response.data[0].REGISTERED_USERS_PROFILE);
        });
    };

    const fetchAddresses = () => {
        axios.get(MyApiUrl + "UserAddressDefault/" + id + "").then((response) => {
            console.log(response);
            if (response.data.length != "0") {
                setCardData(response.data);
            }
        });
    }
    const fetchRecentOrders = () => {
        axios.get(MyApiUrl + "RecentOrders/" + id + "").then((response) => {
            console.log(response);
            if (response.data.length > 0) {
                setOrderData(response.data);
            }
            else {
                setOrderData([]);
            }
        });
    }
    React.useEffect(() => {
        GetProfile();
        fetchAddresses();
        fetchRecentOrders();
    }, []);

    return (
        <div className="dashboard">
            <Helmet>
                <title>{`My Account — ${theme.name}`}</title>
            </Helmet>
            <div className="dashboard__profile card profile-card">
                <div className="card-body profile-card__body">
                    <div className="profile-card__avatar">
                        <img src={ViewImg + ProfileImg} alt="" />
                    </div>
                    <div className="profile-card__name">{ProfileName}</div>
                    <div className="profile-card__email">{ProfileEmail}</div>
                    <div className="profile-card__edit">
                        <Link to="profile" className="btn btn-secondary btn-sm">Edit Profile</Link>
                    </div>
                </div>
            </div>
            <div className="dashboard__address card address-card address-card--featured">
                {CardData.map((val, key) => {
                    return (
                        <div>
                            <div className="address-card__badge">Default Address</div>
                            <div className="address-card__body">
                                <div className="address-card__name">{val.USER_ADDRESS_NAME}</div>
                                <div className="address-card__row">
                                    {val.USER_ADDRESS_HOUSE_NO}, {val.USER_ADDRESS_STREET}
                                    ,
                                    <br />
                                    {val.AREA_NAME}, {val.CITY_NAME}, {val.STATE_NAME}
                                    ,
                                    <br />
                                    {val.COUNTRY_NAME}, {val.USER_ADDRESS_ZIPCODE}
                                    <br />
                                </div>
                                <div className="address-card__row">
                                    <div className="address-card__row-title">Phone Number</div>
                                    <div className="address-card__row-content">{val.USER_ADDRESS_PHONE}</div>
                                </div>
                                <div className="address-card__row">
                                    <div className="address-card__row-title">Email Address</div>
                                    <div className="address-card__row-content">{ProfileEmail}</div>
                                </div>
                                <div className="address-card__row">
                                    <div className="address-card__row-title">Address Type</div>
                                    <div className="address-card__row-content">{val.USER_ADDRESS_TYPE}</div>
                                </div>
                                <div className="address-card__footer">
                                    <Link to="/account/addresses"
                                        className="btn btn-warning"
                                        style={{
                                            padding: '4px 15px',
                                            height: '27px',
                                            fontWeight: '200',
                                            width: '47%',
                                            fontSize: 13,
                                        }}
                                    >Manage Addresses</Link>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className="dashboard__orders card">
                <div className="card-header">
                    <h5>Recent Orders</h5>
                </div>
                <div className="card-divider" />
                <div className="card-table">
                    <div className="table-responsive-sm">
                        <table>
                            <thead>
                                <tr>
                                    <th>Order</th>
                                    <th>Date</th>
                                    <th>Status</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {OrderData.map((order, key) => {
                                    return (
                                        <tr key={order.ORDERS_PKID}>
                                            <td>
                                                <Link to={{
                                                    pathname: "/account/orderDetails",
                                                    state: { orderDetails: order },
                                                }}>
                                                    {order.ORDERS_NUMBER}
                                                </Link>
                                            </td>
                                            <td>{SplitDate(order.ORDERS_DATE)}</td>
                                            <td>{order.ORDER_STATUS}</td>
                                            <td><Currency value={order.ORDERS_GRAND_TOTAL} /> for {order.ORDERS_NO_OF_ITEMS} item(s)</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}
