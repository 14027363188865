// react
/* eslint-disable */
/* eslint-disable prefer-template */
/* eslint-disable max-len */
// react
import React, { useState } from 'react';
import axios from 'axios';
import { MyApiUrl, ViewImg } from '../services/service';

// third-party
import { Helmet } from 'react-helmet-async';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// data stubs
import theme from '../../data/theme';

export default function AccountPageProfile() {
    // var hours = 1; // Reset when storage is more than 24hours
    // var now = new Date().getTime();
    // var setupTime = localStorage.getItem('setupTime');
    // if (setupTime == null) {
    //     localStorage.setItem('setupTime', now)
    // } else {
    //     if (now - setupTime > hours * 60 * 60 * 1000) {
    //         localStorage.clear()
    //         localStorage.setItem('setupTime', now);
    //     }
    // }
    const [ProfileField, setProfileField] = useState({
        Name: '',
        Email: '',
        Phno: '',
        Password: '',
        ProfileImg: '',
    });

    const id = localStorage.getItem("UserID");
    const UpdateProfile = () => {
        const ValidEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
        if (ProfileField.Name === '' || ProfileField.Name === null) {
            toast.warn('Please Enter Name!', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
            });
        } else if (ProfileField.Email === '' || ProfileField.Email === null) {
            toast.warn('Please Enter Email!', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
            });
        } else if (ValidEmail.test(ProfileField.Email) === false) {
            toast.warn('Please Enter Valid Email!', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
            });
        } else if (ProfileField.Phno === '' || ProfileField.Phno === null) {
            toast.warn('Please Enter Contact Number!', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
            });
        } else {
            const obj = {
                REGISTERED_USERS_NAME: ProfileField.Name,
                REGISTERED_USERS_EMAIL: ProfileField.Email,
                REGISTERED_USERS_PHONE: ProfileField.Phno,
                REGISTERED_USERS_PASSWORD: ProfileField.Password,
                REGISTERED_USERS_PROFILE: ProfileField.ProfileImg,
            };
            console.log(obj);
            axios.put(MyApiUrl + "RegisteredUsers/" + id + "", obj).then((response) => {
                if (response.data == false) {
                    toast.error('Failed to Update Profile!', {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'dark',
                    });
                } else if (response.data == true) {
                    toast.success('Profile Updated Successfully!', {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'dark',
                    });
                }
            });
        }
    };
    const GetProfile = () => {
        axios.get(MyApiUrl + "UserProfile/" + id + "").then((response) => {
            console.log(response);
            setProfileField({
                ...ProfileField,
                Name: response.data[0].REGISTERED_USERS_NAME,
                Email: response.data[0].REGISTERED_USERS_EMAIL,
                Phno: response.data[0].REGISTERED_USERS_PHONE,
                Password: response.data[0].REGISTERED_USERS_PASSWORD,
                ProfileImg: response.data[0].REGISTERED_USERS_PROFILE,
            });
        });
    };

    React.useEffect(() => {
        GetProfile();
    }, []);
    return (
        <div className="card">
            <Helmet>
                <title>{`Profile — ${theme.name}`}</title>
            </Helmet>

            <div className="card-header">
                <h5>Edit Profile</h5>
            </div>
            <div className="card-divider" />
            <div className="card-body" style={{ paddingTop: '0%' }}>
                <div className="row no-gutters">
                    <div className="col-12 col-lg-12 col-xl-12" style={{ padding: 10 }}>
                        <div className="form-group" style={{ float: 'right', marginBottom: '0%' }}>
                            <p style={{ marginBottom: '5px', fontWeight: '600' }}>
                                Existing Profile
                            </p>
                            <img
                                style={{ marginBottom: '0%', marginLeft: '0.6em' }}
                                src={ViewImg + ProfileField.ProfileImg}
                                className="profile-card__avatar"
                            />
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 col-xl-6" style={{ padding: 10 }}>
                        <div className="form-group">
                            <label htmlFor="profile-first-email" className="designLabel">
                                Name&nbsp;
                                <span style={{ color: 'red' }}>*</span>
                            </label>
                            <input
                                id="profile-first-name"
                                type="text"
                                className="form-control designInputs"
                                placeholder="Enter Your Name"
                                value={ProfileField.Name}
                                onChange={(event) => {
                                    setProfileField({
                                        ...ProfileField,
                                        Name: event.target.value,
                                    });
                                }}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="profile-email" className="designLabel">
                                Contact Number&nbsp;
                                <span style={{ color: 'red' }}>*</span>
                            </label>
                            <input
                                id="profile-phone"
                                type="text"
                                className="form-control designInputs"
                                placeholder="Enter Your Contact Number"
                                value={ProfileField.Phno}
                                onChange={(event) => {
                                    setProfileField({
                                        ...ProfileField,
                                        Phno: event.target.value,
                                    });
                                }}
                            />
                        </div>
                    </div>

                    <div className="col-12 col-lg-6 col-xl-6" style={{ padding: 10 }}>
                        <div className="form-group">
                            <label htmlFor="profile-email" className="designLabel">
                                Email Address&nbsp;
                                <span style={{ color: 'red' }}>*</span>
                            </label>
                            <input
                                id="profile-email"
                                type="email"
                                className="form-control designInputs"
                                placeholder="Enter Your Email"
                                value={ProfileField.Email}
                                onChange={(event) => {
                                    setProfileField({
                                        ...ProfileField,
                                        Email: event.target.value,
                                    });
                                }}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="profile-email" className="designLabel">
                                Profile Image
                            </label>
                            <input
                                type="file"
                                className="form-control designInputs"
                                style={{ padding: "0px", lineHeight: "27px" }}
                                onChange={(event) => {
                                    var formData = new FormData();
                                    formData.append(
                                        "file",
                                        event.target.files[0]
                                    );
                                    axios.post(MyApiUrl + "upload", formData)
                                        .then((response) => {
                                            setProfileField({
                                                ...ProfileField,
                                                ProfileImg: response.data,
                                            });
                                        });
                                }}
                            />
                        </div>

                        <div className="form-group mt-5 mb-0">
                            <button
                                type="button"
                                onClick={UpdateProfile}
                                className="btn btn-success mt-2 mt-md-3 mt-lg-4"
                                style={{ float: 'right', marginRight: 10 }}
                            >
                                Update
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
