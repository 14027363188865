/* eslint-disable */
/* eslint-disable prefer-template */
/* eslint-disable max-len */
// react
import React, { useState } from "react";
import axios from "axios";
import { MyApiUrl, ViewImg } from "../services/service";
import ReactSwitch from "react-switch";
import { Link } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// third-party
import { Helmet } from "react-helmet-async";

// data stubs
import theme from "../../data/theme";
import { useLocation } from "react-router-dom";

export default function AccountPageEditAddress() {
    const location = useLocation();
    const AddressPkid = location.state?.pkid;
    console.log(AddressPkid);
    const UserEmail = localStorage.getItem("UserEmail");
    const UserID = localStorage.getItem("UserID");

    const [Countrydata, setCountryData] = useState([]);
    const [StateData, setStateData] = useState([]);
    const [CityData, setCityData] = useState([]);
    const [AreaData, setAreaData] = useState([]);
    const [Country, setCountry] = useState();
    const [State, setState] = useState();
    const [City, setCity] = useState();
    const [Area, setArea] = useState();
    const [Name, setName] = useState();
    const [PhNo, setPhNo] = useState();
    const [Pincode, setPincode] = useState();
    const [HouseNumber, setHouseNumber] = useState();
    const [Street, setStreet] = useState();
    const [AddressType, setAddressType] = useState();
    const [AddressDefault, setAddressDefault] = useState(false);
    const [DefaultAddressPkid, setDefaultAddressPkid] = useState();

    const NameChange = (event) => {
        setName(event.target.value);
    };
    const PhNoChange = (event) => {
        setPhNo(event.target.value);
    };
    const HouseNumberChange = (event) => {
        setHouseNumber(event.target.value);
    };
    const StreetChange = (event) => {
        setStreet(event.target.value);
    };
    const AddressTypeChange = (event) => {
        setAddressType(event.target.value);
    };
    const AddressDefaultChange = (event) => {
        if (event == false) {
            setAddressDefault(event);
        } else {
            axios.get(MyApiUrl + "UserAddressDefault/" + UserID + "").then((response) => {
                console.log(response);
                if (response.data.length != "0") {
                    if (response.data[0].USER_ADDRESS_PKID == AddressPkid) {
                        setAddressDefault(event);
                    } else {
                        toast.error("Default Address Already Exist!", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "dark",
                        });
                    }
                } else {
                    setAddressDefault(event);
                }
            });
        }
    };
    const CountryChange = (event) => {
        if (event.target.value === "-1") {
            toast.warn("Please Select Country!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            setCountry(event.target.value);
        } else {
            setCountry(event.target.value);
            GetState(event.target.value);
        }
    };

    const StateChange = (event) => {
        if (event.target.value === "-1") {
            toast.warn("Please Select State!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            setState(event.target.value);
        } else {
            setState(event.target.value);
            GetCity(event.target.value);
        }
    };

    const CityChange = (event) => {
        if (event.target.value === "-1") {
            toast.warn("Please Select City!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            setCity(event.target.value);
        } else {
            setCity(event.target.value);
            GetArea(event.target.value);
        }
    };

    const AreaChange = (event) => {
        if (event.target.value === "-1") {
            toast.warn("Please Select Area!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            setArea(event.target.value);
        } else {
            setArea(event.target.value);
            GetPincode(event.target.value);
        }
    };

    const GetPincode = async (AreaID) => {
        await axios.get(MyApiUrl + "PincodeByCity/" + AreaID + "").then((response) => {
            setPincode(response.data[0].AREA_PINCODE);
        });
    };

    const ChangePincode = async (event) => {
        setPincode(event.target.value);
        await axios.get(MyApiUrl + "GetCompleteLocationByPincode/" + event.target.value + "").then((response) => {
            const CountryOption = response.data[0].Country.map((item) => (
                <option value={item.AREA_COUNTRY_FKID}>{item.COUNTRY_NAME}</option>
            ));
            setCountryData(CountryOption);
            if (response.data[0].Country.length == 1) {
                setCountry(response.data[0].Country[0].AREA_COUNTRY_FKID);
            } else {
                setCountry("-1");
            }

            const StateOption = response.data[0].State.map((item) => (
                <option value={item.AREA_STATE_FKID}>{item.STATE_NAME}</option>
            ));
            setStateData(StateOption);
            if (response.data[0].State.length == 1) {
                setState(response.data[0].State[0].AREA_STATE_FKID);
            } else {
                setState("-1");
            }

            const CityOption = response.data[0].City.map((item) => (
                <option value={item.AREA_CITY_FKID}>{item.CITY_NAME}</option>
            ));
            setCityData(CityOption);
            if (response.data[0].City.length == 1) {
                setCity(response.data[0].City[0].AREA_CITY_FKID);
            } else {
                setCity("-1");
            }

            const AreaOption = response.data[0].Area.map((item) => (
                <option value={item.AREA_PKID}>{item.AREA_NAME}</option>
            ));
            setAreaData(AreaOption);
            if (response.data[0].Area.length == 1) {
                setArea(response.data[0].Area[0].AREA_PKID);
            } else {
                setArea("-1");
            }
        });
    };

    const GetCountry = () => {
        axios.get(MyApiUrl + "Country").then((response) => {
            const CountryOption = response.data.map((item) => (
                <option value={item.COUNTRY_PKID}>{item.COUNTRY_NAME}</option>
            ));
            setCountryData(CountryOption);
        });
    };

    const GetState = (Countrypkid) => {
        axios.get(MyApiUrl + "StateByCountry/" + Countrypkid + "").then((response) => {
            const StateOption = response.data.map((item) => <option value={item.STATE_PKID}>{item.STATE_NAME}</option>);
            setStateData(StateOption);
        });
    };

    const GetCity = (Stateid) => {
        axios.get(MyApiUrl + "CityByState/" + Stateid + "").then((response) => {
            const CityOption = response.data.map((item) => <option value={item.CITY_PKID}>{item.CITY_NAME}</option>);
            setCityData(CityOption);
        });
    };

    const GetArea = (CityID) => {
        console.log(CityID);
        axios.get(MyApiUrl + "AreaByCityID/" + CityID + "").then((response) => {
            const AreaOption = response.data.map((item) => <option value={item.AREA_PKID}>{item.AREA_NAME}</option>);
            setAreaData(AreaOption);
        });
    };

    const GetAddress = React.useCallback(() => {
        axios.get(MyApiUrl + "UserAddressByAddressID/" + AddressPkid + "").then((response) => {
            setCountryStateCity(
                response.data[0].USER_ADDRESS_COUNTRY_FKID,
                response.data[0].USER_ADDRESS_STATE_FKID,
                response.data[0].USER_ADDRESS_CITY_FKID,
                response.data[0].USER_ADDRESS_AREA_FKID
            );
            setName(response.data[0].USER_ADDRESS_NAME);
            setPhNo(response.data[0].USER_ADDRESS_PHONE);
            setHouseNumber(response.data[0].USER_ADDRESS_HOUSE_NO);
            setStreet(response.data[0].USER_ADDRESS_STREET);
            setAddressType(response.data[0].USER_ADDRESS_TYPE);
            setAddressDefault(response.data[0].USER_ADDRESS_DEFAULT);
            setPincode(response.data[0].USER_ADDRESS_ZIPCODE);
            setDefaultAddressPkid(response.data[0].USER_ADDRESS_PKID);
        });
    }, []);
    const setCountryStateCity = async (Country, State, City, Area) => {
        await axios.get(MyApiUrl + "Country").then((response) => {
            const CountryOption = response.data.map((item) => (
                <option value={item.COUNTRY_PKID}>{item.COUNTRY_NAME}</option>
            ));
            setCountryData(CountryOption);
            setCountry(Country);
        });
        await axios.get(MyApiUrl + "StateByCountry/" + Country + "").then((response) => {
            const StateOption = response.data.map((item) => <option value={item.STATE_PKID}>{item.STATE_NAME}</option>);
            setStateData(StateOption);
            setState(State);
        });
        await axios.get(MyApiUrl + "CityByState/" + State + "").then((response) => {
            const CityOption = response.data.map((item) => <option value={item.CITY_PKID}>{item.CITY_NAME}</option>);
            setCityData(CityOption);
            setCity(City);
        });

        await axios.get(MyApiUrl + "AreaByCityID/" + City + "").then((response) => {
            const AreaOption = response.data.map((item) => <option value={item.AREA_PKID}>{item.AREA_NAME}</option>);
            setAreaData(AreaOption);
            setArea(Area);
        });
    };

    React.useEffect(() => {
        GetCountry();
        GetAddress();
    }, []);

    const UpdateAddress = () => {
        if (Name == "" || Name == null) {
            toast.warn("Please Enter Name!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        } else if (PhNo == "" || PhNo == null) {
            toast.warn("Please Enter Contact Number!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        } else if (HouseNumber == "" || HouseNumber == null) {
            toast.warn("Please Enter House No.,Building Name!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        } else if (Street == "" || Street == null) {
            toast.warn("Please Enter Street Address!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        } else if (Country == "-1" || Country == null) {
            toast.warn("Please Select Country!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        } else if (State == "-1" || State == null) {
            toast.warn("Please Select State!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        } else if (City == "-1" || City == null) {
            toast.warn("Please Select City!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        } else if (AddressType == "" || AddressType == null) {
            toast.warn("Please Select Address Type!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        } else {
            const obj = {
                USER_ADDRESS_NAME: Name,
                USER_ADDRESS_PHONE: PhNo,
                USER_ADDRESS_TYPE: AddressType,
                USER_ADDRESS_HOUSE_NO: HouseNumber,
                USER_ADDRESS_STREET: Street,
                USER_ADDRESS_AREA_FKID: Area,
                USER_ADDRESS_CITY_FKID: City,
                USER_ADDRESS_STATE_FKID: State,
                USER_ADDRESS_COUNTRY_FKID: Country,
                USER_ADDRESS_ZIPCODE: Pincode,
                USER_ADDRESS_DEFAULT: AddressDefault,
                USER_ADDRESS_USER_FKID: UserID,
            };
            console.log(obj);
            axios.put(MyApiUrl + "UserAddress/" + AddressPkid + "", obj).then((response) => {
                console.log(response);
                if (response.data == true) {
                    toast.success("Address Updated Successfully!", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                    window.location.href = "/account/addresses";
                } else {
                    toast.error("Failed To Update Address!", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                }
            });
        }
    };

    return (
        <div className="card">
            <Helmet>
                <title>{`Add Address — ${theme.name}`}</title>
            </Helmet>

            <div className="card-header" style={{ padding: "10px 20px" }}>
                <div style={{ float: "left", marginTop: 7 }}>
                    <h5>Edit Address</h5>
                </div>
                <div style={{ float: "right" }}>
                    <Link to="/account/addresses" className="btn btn-danger" style={{ padding: "0px !important" }}>
                        Back
                    </Link>
                </div>
            </div>
            <div className="card-divider" />
            <div className="card-body">
                <div className="row no-gutters">
                    <div className="col-12 col-lg-12 col-xl-12">
                        <div className="form-row">
                            <div className="form-group col-md-4">
                                <label htmlFor="checkout-first-name" className="designLabel">
                                    Name&nbsp;
                                    <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                    type="text"
                                    value={Name}
                                    onChange={NameChange}
                                    className="form-control designInputs"
                                    id="checkout-first-name"
                                    placeholder="Enter Name"
                                />
                            </div>
                            <div className="form-group col-md-4">
                                <label htmlFor="checkout-company-name" className="designLabel">
                                    Email
                                </label>
                                <input
                                    readOnly={true}
                                    type="text"
                                    value={UserEmail}
                                    className="form-control designInputs"
                                    id="checkout-company-name"
                                />
                            </div>
                            <div className="form-group col-md-4">
                                <label htmlFor="checkout-company-name" className="designLabel">
                                    Contact Number&nbsp;
                                    <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                    type="number"
                                    value={PhNo}
                                    onChange={PhNoChange}
                                    className="form-control designInputs"
                                    id="checkout-company-name"
                                    placeholder="Enter Contact Number"
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-4">
                                <label htmlFor="checkout-company-name" className="designLabel">
                                    House No.,Building Name&nbsp;
                                    <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                    type="text"
                                    value={HouseNumber}
                                    onChange={HouseNumberChange}
                                    className="form-control designInputs"
                                    id="checkout-company-name"
                                    placeholder="Enter House No.,Building Name"
                                />
                            </div>
                            <div className="form-group col-md-4">
                                <label htmlFor="checkout-company-name" className="designLabel">
                                    Street Address&nbsp;
                                    <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                    type="text"
                                    value={Street}
                                    onChange={StreetChange}
                                    className="form-control designInputs"
                                    id="checkout-company-name"
                                    placeholder="Enter Street Address"
                                />
                            </div>
                            <div className="form-group col-md-4">
                                <label htmlFor="checkout-last-name" className="designLabel">
                                    Pincode
                                </label>
                                <input
                                    type="number"
                                    value={Pincode}
                                    onChange={ChangePincode}
                                    className="form-control designInputs"
                                    id="checkout-last-name"
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-4">
                                <label htmlFor="checkout-country" className="designLabel">
                                    Country&nbsp;
                                    <span style={{ color: "red" }}>*</span>
                                </label>
                                <select
                                    id="checkout-country"
                                    value={Country}
                                    className="form-control form-control-select2 designInputs"
                                    onChange={CountryChange}
                                >
                                    <option value="-1">Select Country</option>
                                    {Countrydata}
                                </select>
                            </div>
                            <div className="form-group col-md-4">
                                <label htmlFor="checkout-country" className="designLabel">
                                    State&nbsp;
                                    <span style={{ color: "red" }}>*</span>
                                </label>
                                <select
                                    id="checkout-country"
                                    value={State}
                                    className="form-control form-control-select2 designInputs"
                                    onChange={StateChange}
                                >
                                    <option value="-1">Select State</option>
                                    {StateData}
                                </select>
                            </div>
                            <div className="form-group col-md-4">
                                <label htmlFor="checkout-country" className="designLabel">
                                    City&nbsp;
                                    <span style={{ color: "red" }}>*</span>
                                </label>
                                <select
                                    id="checkout-country"
                                    value={City}
                                    onChange={CityChange}
                                    className="form-control form-control-select2 designInputs"
                                >
                                    <option value="-1">Select City</option>
                                    {CityData}
                                </select>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-4">
                                <label htmlFor="checkout-country" className="designLabel">
                                    Area&nbsp;
                                    <span style={{ color: "red" }}>*</span>
                                </label>
                                <select
                                    id="checkout-country"
                                    value={Area}
                                    onChange={AreaChange}
                                    className="form-control form-control-select2 designInputs"
                                >
                                    <option value="-1">Select Area</option>
                                    {AreaData}
                                </select>
                            </div>
                            <div className="form-group col-md-4" style={{ paddingLeft: "3%", paddingTop: "2%" }}>
                                <label htmlFor="checkout-country" className="designLabel">
                                    Address Type&nbsp;
                                    <span style={{ color: "red" }}>*</span>
                                </label>
                                <br />

                                {AddressType == "Home" ? (
                                    <input
                                        type="radio"
                                        name="AddressType"
                                        value="Home"
                                        checked
                                        id="exp2"
                                        onChange={AddressTypeChange}
                                    />
                                ) : (
                                    <input
                                        type="radio"
                                        name="AddressType"
                                        value="Home"
                                        id="exp2"
                                        onChange={AddressTypeChange}
                                    />
                                )}
                                <label htmlFor="exp2" style={{ marginLeft: 10 }} className="designLabel">
                                    {" "}
                                    Home
                                </label>

                                {AddressType == "Work" ? (
                                    <input
                                        style={{ marginLeft: 20 }}
                                        type="radio"
                                        name="AddressType"
                                        id="exp1"
                                        value="Work"
                                        checked
                                        onChange={AddressTypeChange}
                                    />
                                ) : (
                                    <input
                                        style={{ marginLeft: 20 }}
                                        type="radio"
                                        name="AddressType"
                                        id="exp1"
                                        value="Work"
                                        onChange={AddressTypeChange}
                                    />
                                )}
                                <label htmlFor="exp1" style={{ marginLeft: 10 }} className="designLabel">
                                    {" "}
                                    Work
                                </label>
                            </div>

                            <div className="form-group col-md-4" style={{ paddingLeft: "1%", paddingTop: "1%" }}>
                                <label htmlFor="checkout-country" className="designLabel">
                                    Default Address
                                </label>
                                <div>
                                    <ReactSwitch checked={AddressDefault} onChange={AddressDefaultChange} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-12 col-xl-12">
                        <div className="form-group mt-3 mb-0" style={{ float: "right" }}>
                            <button className="btn btn-success" type="button" onClick={UpdateAddress}>
                                Update
                            </button>
                            <Link
                                className="btn btn-primary"
                                type="button"
                                to="/account/addresses"
                                style={{ marginLeft: 10 }}
                            >
                                Cancel
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
