/* eslint-disable object-curly-spacing */
/* eslint-disable quotes */
/* eslint-disable no-lonely-if */
/* eslint-disable max-len */
// react
import React from "react";

// data stubs
// import specification from '../../data/shopProductSpec';

function ProductTabSpecification(props) {
    const { product } = props;
    let features = "";
    const sections = product.specification.map((section, index) => {
        console.log(section.name);
        if (section.name === "Features") {
            features = section.features.map((feature, index) => (
                <React.Fragment>
                    <div key={index} className="spec__row">
                        <div className="spec__value">
                            <div dangerouslySetInnerHTML={{ __html: feature.name }} />
                        </div>
                    </div>
                </React.Fragment>
            ));
        } else {
            if (section.name === "Product Benefits") {
                features = section.features.map((feature, index) => (
                    <React.Fragment>
                        <div key={index} className="spec__row" style={{ width: "100%" }}>
                            <div className="spec__name" style={{ width: "100%" }}>
                                <div dangerouslySetInnerHTML={{ __html: feature.name }} />
                            </div>
                        </div>
                    </React.Fragment>
                ));
            } else {
                features = section.features.map((feature, index) => (
                    <React.Fragment>
                        <div key={index} className="spec__row">
                            <div className="spec__name">
                                <div dangerouslySetInnerHTML={{ __html: feature.name }} style={{ width: '200%' }} />
                            </div>
                            <div className="spec__value">
                                <div dangerouslySetInnerHTML={{ __html: feature.value }} />
                            </div>
                        </div>
                    </React.Fragment>
                ));
            }
        }
        return (
            <div key={index} className="spec__section">
                <h4 className="spec__section-title">{section.name}</h4>
                {features}
            </div>
        );
    });

    return (
        <div className="spec">
            <h3 className="spec__header">Specification</h3>
            {sections}
            {/* <div className="spec__disclaimer">
                Information on technical characteristics, the delivery set, the country of manufacture and the
                appearance of the goods is for reference only and is based on the latest information available at the
                time of publication.
            </div> */}
        </div>
    );
}

export default ProductTabSpecification;
