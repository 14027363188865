// react
import React from 'react';

// third-party
import { Helmet } from 'react-helmet-async';

// application
import PageHeader from '../shared/PageHeader';

// blocks
// import BlockMap from '../blocks/BlockMap';

// data stubs
import theme from '../../data/theme';

function SitePageContactUs() {
    const breadcrumb = [
        { title: 'Home', url: '' },
        { title: 'Contact Us', url: '' },
    ];
    const mystyle = {
        page: {
            marginRight: 10,
            backgroundColor: '#0484cc',
            color: 'white',
            padding: 10,
            borderRadius: 100,
            width: 35,
        },
    };
    return (
        <React.Fragment>
            <Helmet>
                <title>{`Contact Us — ${theme.name}`}</title>
            </Helmet>
            <PageHeader header="Contact Us" breadcrumb={breadcrumb} />
            <div className="block">
                <div className="container">
                    <div className="card mb-0">
                        <div className="card-body contact-us">
                            <div className="contact-us__container">
                                <div className="row">
                                    <div className="col-12 col-lg-6 pb-4 pb-lg-0">
                                        <h4 className="contact-us__header card-title">Get In Touch</h4>

                                        <div className="contact-us__address">
                                            <p>
                                                <i className="footer-contacts__icon far fa-envelope" style={mystyle.page} />
                                                <span style={{ fontSize: 20, color: '#0484cc', fontWeight: 600 }}>
                                                    Email
                                                </span>
                                                <br />
                                                <span style={{ marginLeft: 44 }}>
                                                    <a href="mailto: info@aaprobics.com" target="_blank" rel="noreferrer"> info@aaprobics.com</a>
                                                </span>
                                                <br />
                                                <br />
                                                <i className="footer-contacts__icon fas fa-mobile-alt" style={mystyle.page} />
                                                <span style={{ fontSize: 20, color: '#0484cc', fontWeight: 600 }}>
                                                    Phone
                                                </span>
                                                <br />
                                                <span style={{ marginLeft: 44 }}>
                                                    +91 9041835625
                                                </span>
                                                <br />
                                                <br />
                                                <i className="footer-contacts__icon fas fa-globe-americas" style={mystyle.page} />
                                                <span style={{ fontSize: 20, color: '#0484cc', fontWeight: 600 }}>
                                                    Address
                                                </span>
                                                <br />
                                                <span style={{ marginLeft: 44 }}>
                                                    AA probics Survey no 30/2
                                                </span>
                                                <br />
                                                <span style={{ marginLeft: 44 }}>
                                                    Siddalingpura Banglore - Mysore Highway
                                                </span>
                                                <br />
                                                <span style={{ marginLeft: 44 }}>
                                                    Mysore - 570003 Karnataka
                                                </span>
                                                <br />
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-12 col-lg-6">
                                        <h4 className="contact-us__header card-title">Leave us a Message</h4>

                                        <form>
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="form-name">Your Name</label>
                                                    <input type="text" id="form-name" className="form-control" placeholder="Your Name" />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="form-email">Email</label>
                                                    <input
                                                        type="email"
                                                        id="form-email"
                                                        className="form-control"
                                                        placeholder="Email Address"
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="form-subject">Subject</label>
                                                <input type="text" id="form-subject" className="form-control" placeholder="Subject" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="form-message">Message</label>
                                                <textarea id="form-message" className="form-control" rows="4" />
                                            </div>
                                            <button type="submit" className="btn btn-primary">Send Message</button>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PageHeader header="Find Us" />
            <div className="block">
                <div className="container">
                    <div className="card mb-0">
                        <div className="card-body contact-us">
                            <div className="block-map block">
                                <div className="block-map__body">
                                    <iframe
                                        title="Google Map"
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3897.272869488852!2d76.66023737411045!3d12.36464552789658!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3baf71f0e2073bd5%3A0x81f9662fbe9763d2!2sA%26A%20Probics!5e0!3m2!1sen!2sin!4v1708492428769!5m2!1sen!2sin"
                                        frameBorder="0"
                                        scrolling="no"
                                        marginHeight="0"
                                        marginWidth="0"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default SitePageContactUs;
