// react
/* eslint-disable */
/* eslint-disable prefer-template */
/* eslint-disable max-len */
// react
import React, { useState } from 'react';
import axios from 'axios';
import { MyApiUrl, ViewImg } from '../services/service';
// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// data stubs
import dataAddresses from '../../data/accountAddresses';
import theme from '../../data/theme';

export default function AccountPageAddresses() {
    const UserID = localStorage.getItem("UserID");
    const UserEmail = localStorage.getItem("UserEmail");
    const [CardData, setCardData] = useState([]);

    const GetAddress = () => {
        axios.get(MyApiUrl + "UserAddress/" + UserID + "").then((response) => {
            console.log(response);
            if (response.data.length > 0) {
                setCardData(response.data);
            }
            else {
                setCardData([]);
            }
        });
    }

    const SetDefaultAddress = (AddressPkid) => {
        axios.get(MyApiUrl + "UpdateDefaultAddress/" + AddressPkid + "/" + UserID + "").then((response) => {
            if (response.data == "true" || response.data == true) {
                toast.success('Address set to Default!', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'dark',
                });
                GetAddress();
            } else {
                toast.error('Failed to set as Default!', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'dark',
                });
            }
        });
    }
    const DeleteAddress = (val) => {
        axios.delete(MyApiUrl + "UserAddress/" + val + "").then((response) => {
            if (response.data == true) {
                toast.success('Deleted Succesfully!', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'dark',
                });
                GetAddress();
            } else {
                toast.error('Failed to Delete!', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'dark',
                });
            }
        });
    }
    React.useEffect(() => {
        GetAddress();
    }, []);
    const addresses = CardData.map((val, key) => (
        <React.Fragment key={val.USER_ADDRESS_PKID}>
            <div className="addresses-list__item card address-card">
                {val.USER_ADDRESS_DEFAULT && <div className="address-card__badge">Default</div>}

                <div className="address-card__body">
                    <div className="address-card__name">{val.USER_ADDRESS_NAME}</div>
                    <div className="address-card__row">
                        {val.USER_ADDRESS_HOUSE_NO}, {val.USER_ADDRESS_STREET}
                        ,
                        <br />
                        {val.AREA_NAME}, {val.CITY_NAME}, {val.STATE_NAME}
                        ,
                        <br />
                        {val.COUNTRY_NAME}, {val.USER_ADDRESS_ZIPCODE}
                        <br />
                    </div>
                    <div className="address-card__row">
                        <div className="address-card__row-title">Phone Number</div>
                        <div className="address-card__row-content">{val.USER_ADDRESS_PHONE}</div>
                    </div>
                    <div className="address-card__row">
                        <div className="address-card__row-title">Email Address</div>
                        <div className="address-card__row-content">{UserEmail}</div>
                    </div>
                    <div className="address-card__row">
                        <div className="row">
                            <div className="col-6">
                                <div className="address-card__row-title">Address Type</div>
                                <div className="address-card__row-content">{val.USER_ADDRESS_TYPE}</div>
                            </div>
                            <div className="col-6">
                                {val.USER_ADDRESS_DEFAULT == false ?
                                    <button onClick={() => {
                                        SetDefaultAddress(val.USER_ADDRESS_PKID);
                                    }}
                                        style={{
                                            padding: '2px 15px 2px 15px',
                                            height: '27px',
                                            fontWeight: '100',
                                            fontSize: 12,
                                            marginTop: 8
                                        }}
                                        className="btn btn-info">Set Default</button>
                                    : ""
                                }
                            </div>
                        </div>
                    </div>
                    <div className="address-card__footer">
                        <Link to={{
                            pathname: "/account/EditAddress",
                            state: { pkid: val.USER_ADDRESS_PKID },
                        }}
                            className="btn btn-warning"
                            style={{
                                padding: '4px 15px',
                                height: '27px',
                                fontWeight: '200',
                                width: '47%',
                                fontSize: 13,
                            }}
                        >Edit</Link>
                        &nbsp;&nbsp;&nbsp;
                        {val.ADDRESS_LINKED_STATUS == "1" || val.ADDRESS_LINKED_STATUS == 1 ?
                            ""
                            :
                            <button onClick={() => {
                                DeleteAddress(val.USER_ADDRESS_PKID);
                            }}
                                style={{
                                    padding: '4px 15px',
                                    height: '27px',
                                    fontWeight: '100',
                                    width: '47%',
                                    fontSize: 12,
                                }}
                                className="btn btn-danger">Remove</button>
                        }

                    </div>
                </div>
            </div>
            <div className="addresses-list__divider" />
        </React.Fragment>
    ));

    return (
        <React.Fragment>
            <div className="addresses-list">
                <Helmet>
                    <title>{`Address List — ${theme.name}`}</title>
                </Helmet>

                <Link to="/account/AddAddress" className="addresses-list__item addresses-list__item--new">
                    <div className="addresses-list__plus" />
                    <div className="btn btn-secondary btn-sm">Add New</div>
                </Link>
                <div className="addresses-list__divider" />
                {addresses}
            </div>
            <div style={{
                marginTop: 15,
                fontSize: 14,
                textAlign: 'center'
            }}>
                <p><span style={{ fontWeight: 'bold' }}>Note: </span>If Delete Option is not Available, then the address is linked to your Placed Orders</p>
            </div>
        </React.Fragment>
    );
}
