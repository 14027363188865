// react
/* eslint-disable */
/* eslint-disable prefer-template */
/* eslint-disable max-len */
// react
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { MyApiUrl } from '../services/service';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../scss/custom.css';
// third-party
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

// application
import Indicator from './Indicator';
import { Person20Svg } from '../../svg';
import Dropdown from './Dropdown';

export default function IndicatorAccount() {
    const [LoginField, setLoginField] = useState({
        Email: '',
        Password: '',
    });
    const login = () => {
        const ValidEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
        if (LoginField.Email === '' || LoginField.Email === null) {
            toast.warn('Please Enter Email!', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
            });
        } else if (ValidEmail.test(LoginField.Email) === false) {
            toast.warn('Please Enter Valid Email!', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
            });
        } else if (LoginField.Password === '' || LoginField.Password === null) {
            toast.warn('Please Enter Password!', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
            });
        } else {
            const obj = {
                REGISTERED_USERS_EMAIL: LoginField.Email,
                REGISTERED_USERS_PASSWORD: LoginField.Password,
            };
            console.log(obj);
            axios.get(MyApiUrl + "UserLogin/" + LoginField.Email + "/" + LoginField.Password + "").then((response) => {
                if (response.data == false) {
                    toast.error('Please Enter Valid Credentials!', {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'dark',
                    });
                } else {
                    console.log(response);
                    toast.success('Login Successfully!', {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'dark',
                    });
                    clear();
                    localStorage.setItem("UserEmail", response.data[0].REGISTERED_USERS_EMAIL);
                    localStorage.setItem("UserName", response.data[0].REGISTERED_USERS_NAME);
                    localStorage.setItem("UserPassword", response.data[0].REGISTERED_USERS_PASSWORD);
                    localStorage.setItem("UserID", response.data[0].REGISTERED_USERS_PKID);
                    localStorage.setItem("UserEmail", response.data[0].REGISTERED_USERS_EMAIL);
                    localStorage.setItem("UserLoggedIN", true);
                    window.location.href = "/account/dashboard";
                }
            });
        }
    };

    const clear = () => {
        setLoginField({
            ...LoginField,
            Email: '',
            Password: '',
        });
    }
    const dropdown = (
        <div className="account-menu">
            <form className="account-menu__form">
                <div className="account-menu__form-title">Log In to Your Account</div>
                <div className="form-group">
                    <label htmlFor="header-signin-email" className="sr-only">Email</label>
                    <input
                        id="header-signin-email"
                        type="email"
                        className="form-control form-control-sm designInputs"
                        placeholder="Enter Email"
                        value={LoginField.Email}
                        onChange={(event) => {
                            setLoginField({
                                ...LoginField,
                                Email: event.target.value,
                            });
                        }}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="header-signin-password" className="sr-only">Password</label>
                    <div className="account-menu__form-forgot">
                        <input
                            id="header-signin-password"
                            type="password"
                            className="form-control form-control-sm designInputs"
                            placeholder="Enter Password"
                            value={LoginField.Password}
                            onChange={(event) => {
                                setLoginField({
                                    ...LoginField,
                                    Password: event.target.value,
                                });
                            }}
                        />
                        <Link to="/ForgetPassword" className="account-menu__form-forgot-link">Forgot?</Link>
                    </div>
                </div>
                <div className="form-group account-menu__form-button">
                    <button type="button" className="btn btn-primary btn-sm" onClick={login}>Login</button>
                </div>
                <div className="account-menu__form-link">
                    <Link to="/register" style={{ color: 'blue' }}>Create An Account</Link>
                </div>
            </form>
            <div className="account-menu__divider" />
        </div>
    );

    const UserLoggedIN = localStorage.getItem('UserLoggedIN');
    return (
        UserLoggedIN == null || UserLoggedIN == "null" ?
            <Indicator url="/account" dropdown={dropdown} icon={<Person20Svg />} />
            : <Indicator url="/account/dashboard" icon={<Person20Svg />} />
    );
}
