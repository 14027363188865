/* eslint-disable quotes */
/* eslint-disable */
/* eslint-disable prefer-template */
/* eslint-disable max-len */
// react
import React, { Component, useState } from "react";
import axios from "axios";
import { MyApiUrl, ViewImg } from "../services/service";
// third-party
import classNames from "classnames";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// application
import AsyncAction from "../shared/AsyncAction";
import Currency from "../shared/Currency";
import InputNumber from "../shared/InputNumber";
import PageHeader from "../shared/PageHeader";
import { cartRemoveItem, cartUpdateQuantities, cartUpdateCoupons, cartRemoveCoupon } from "../../store/cart";
import { Cross12Svg, Info } from "../../svg";
import { url } from "../../services/utils";

// data stubs
import theme from "../../data/theme";

class ShopPageCart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            /** example: [{itemId: 8, value: 1}] */
            quantities: [],
            Coupon: "",
            CouponDetails: [],
            AvailableCouponDetails: [],
        };
    }

    getItemQuantity(item) {
        const { quantities } = this.state;
        const quantity = quantities.find((x) => x.itemId === item.id);

        return quantity ? quantity.value : item.quantity;
    }

    handleChangeQuantity = (item, quantity) => {
        const { cartUpdateQuantities } = this.props;
        const { quantities } = this.state;
        this.setState((state) => {
            const stateQuantity = state.quantities.find((x) => x.itemId === item.id);

            if (!stateQuantity) {
                state.quantities.push({ itemId: item.id, value: quantity });
            } else {
                stateQuantity.value = quantity;
            }

            return {
                quantities: state.quantities,
            };
        });
        !this.cartNeedUpdate();
        console.log(quantities);
        cartUpdateQuantities(quantities);
    };

    cartNeedUpdate() {
        const { quantities } = this.state;
        const { cart } = this.props;

        return (
            quantities.filter((x) => {
                const item = cart.items.find((item) => item.id === x.itemId);

                return item && item.quantity !== x.value && x.value !== "";
            }).length > 0
        );
    }

    renderItems() {
        const { cart, cartRemoveItem } = this.props;

        return cart.items.map((item) => {
            let image;
            let options;

            if (item.product.images.length > 0) {
                image = (
                    <div className="product-image">
                        <Link to={url.product(item.product)} className="product-image__body">
                            <img className="product-image__img" src={ViewImg + item.product.images[0]} alt="" />
                        </Link>
                    </div>
                );
            }

            if (item.options.length > 0) {
                options = (
                    <ul className="cart-table__options">
                        {item.options.map((option, index) => (
                            <li key={index}>{`${option.optionTitle}: ${option.valueTitle}`}</li>
                        ))}
                    </ul>
                );
            }

            const RemoveItem = async (id) => {
                await axios.get(MyApiUrl + "GetGeneralDiscount").then((response) => {
                    if (response.data.length != 0) {
                        cartRemoveItem(id, response.data);
                    } else {
                        cartRemoveItem(id, []);
                    }
                });
            };

            const removeButton = (
                <AsyncAction
                    // action={this.RemoveItem(item.id)}
                    action={() => RemoveItem(item.id)}
                    render={({ run, loading }) => {
                        const classes = classNames("btn btn-light btn-sm btn-svg-icon", {
                            "btn-loading": loading,
                        });

                        return (
                            <button type="button" onClick={run} className={classes}>
                                <Cross12Svg />
                            </button>
                        );
                    }}
                />
            );

            return (
                <tr key={item.id} className="cart-table__row">
                    <td className="cart-table__column cart-table__column--image">{image}</td>
                    <td className="cart-table__column cart-table__column--product">
                        <Link to={url.product(item.product)} className="cart-table__product-name">
                            {item.product.name}
                        </Link>
                        {options}
                    </td>
                    <td className="cart-table__column cart-table__column--quantity" data-title="Unit">
                        {item.unit}
                    </td>
                    <td className="cart-table__column cart-table__column--quantity" data-title="Price">
                        <Currency value={item.price} />
                    </td>
                    <td className="cart-table__column cart-table__column--quantity" data-title="Quantity">
                        <InputNumber
                            onChange={(quantity) => this.handleChangeQuantity(item, quantity)}
                            value={this.getItemQuantity(item)}
                            min={1}
                        />
                    </td>
                    <td className="cart-table__column cart-table__column--quantity" data-title="Total">
                        <Currency value={item.total} />
                    </td>
                    <td className="cart-table__column cart-table__column--remove">{removeButton}</td>
                </tr>
            );
        });
    }

    renderTotals() {
        const { cart } = this.props;

        return (
            <React.Fragment>
                <thead>
                    <tr>
                        <th>Subtotal</th>
                        <td>
                            <Currency value={cart.subtotal} />
                        </td>
                    </tr>
                </thead>
            </React.Fragment>
        );
    }

    ShippingCharge() {
        const { cart } = this.props;

        if (cart.extraLines.length <= 0) {
            return null;
        }

        const extraLines = cart.extraLines.map((extraLine, index) => {
            // let calcShippingLink;

            return (
                <tr key={index}>
                    <th>
                        {extraLine.title}
                        <div style={{ float: "right" }} className="tooltipp">
                            {/* <Link to="/shop/pricecal"></Link> */}
                            <Info />
                            <span className="tooltiptextt">
                                <table style={{ width: "100%" }}>
                                    <thead>
                                        <tr>
                                            <th style={{ textAlign: "center" }}>Price Range</th>
                                            <th style={{ textAlign: "center" }}>Discount (%)</th>
                                        </tr>
                                    </thead>
                                    <tbody className="cart-table__body">
                                        <tr>
                                            <td style={{ textAlign: "center" }}>0 to 1000</td>
                                            <td style={{ textAlign: "center" }}>8%</td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: "center" }}>1.000 to 3.000</td>
                                            <td style={{ textAlign: "center" }}>7%</td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: "center" }}>3.000 to 5.000</td>
                                            <td style={{ textAlign: "center" }}>5%</td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: "center" }}>5.000 to 10.000</td>
                                            <td style={{ textAlign: "center" }}>8%</td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: "center" }}>More Than 10.000</td>
                                            <td style={{ textAlign: "center" }}>Free</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </span>
                        </div>
                    </th>
                    <td>
                        <Currency value={extraLine.price} />
                    </td>
                </tr>
            );
        });
        return <React.Fragment>{extraLines}</React.Fragment>;
    }

    ProceedCheckOut = () => {
        toast.error("Please Login to Checkout!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });
    };

    ApplayCouponAuto = (Coupon) => {
        this.setState({ Coupon: Coupon });
    };

    ApplyCoupon = () => {
        const { Coupon, CouponDetails } = this.state;
        const { cartUpdateCoupons } = this.props;
        if (Coupon == "" || Coupon == null) {
            toast.warn("Please Enter Coupon Code!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        } else {
            const obj = {
                COUPON_CODE: Coupon,
            };
            axios.post(MyApiUrl + "VerifyCoupon", obj).then((response) => {
                console.log(response.data);
                if (response.data.length > 0) {
                    toast.success("Coupon Applied!", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                    this.setState({ CouponDetails: response.data });
                    !this.cartNeedUpdate();
                    cartUpdateCoupons(response.data[0].Discount, response.data[0].Type, response.data[0].Pkid, Coupon);
                } else {
                    toast.error("Invalid Coupon!", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                    this.setState({ CouponDetails: [] });
                }
            });
        }
    };

    renderCart() {
        const { cart, cartRemoveCoupon } = this.props;
        const { quantities } = this.state;
        const UserLoggedIN = localStorage.getItem("UserLoggedIN");
        const CouponDetails = () => {
            axios.get(MyApiUrl + "CouponsForWeb").then((response) => {
                if (response.data.length != 0) {
                    this.setState({ AvailableCouponDetails: response.data });
                } else {
                    this.setState({ AvailableCouponDetails: [] });
                }
            });
        };
        const removeCouponButton = (
            <AsyncAction
                action={() => cartRemoveCoupon()}
                render={({ run, loading }) => {
                    const classes = classNames("btn btn-light btn-sm btn-svg-icon", {
                        "btn-loading": loading,
                    });

                    return (
                        <button
                            type="button"
                            onClick={run}
                            className={classes}
                            style={{ backgroundColor: "#cf0202", fill: "#fff !important" }}
                        >
                            <Cross12Svg />
                        </button>
                    );
                }}
            />
        );
        return (
            <div className="cart block">
                <div className="container">
                    <table className="cart__table cart-table" style={{ textAlign: "center" }}>
                        <thead className="cart-table__head">
                            <tr className="cart-table__row">
                                <th className="cart-table__column cart-table__column--image">Image</th>
                                <th className="cart-table__column cart-table__column--product">Product</th>
                                <th className="cart-table__column cart-table__column--quantity">Unit</th>
                                <th className="cart-table__column cart-table__column--quantity">Price</th>
                                <th className="cart-table__column cart-table__column--quantity">Quantity</th>
                                <th className="cart-table__column cart-table__column--quantity">Total</th>
                                <th className="cart-table__column cart-table__column--remove" aria-label="Remove" />
                            </tr>
                        </thead>
                        <tbody className="cart-table__body">{this.renderItems()}</tbody>
                    </table>
                    <div className="row justify-content">
                        <div className="col-12 col-md-12 col-lg-12 col-xl-12" style={{ marginTop: "2%" }}>
                            <div className="card">
                                <div className="card-body">
                                    <h3 className="card-title">Important Notes</h3>
                                    <form>
                                        <div className="row">
                                            <div className="col-12">
                                                <ul>
                                                    <li>
                                                        In case of return related queries or if you want to return your
                                                        order please contact to admin through contact us.
                                                    </li>
                                                    <li style={{ lineHeight: "2.5" }}>
                                                        10 Gummies are only for trail, for better result purchase 30
                                                        Gummies.
                                                    </li>
                                                    <li style={{ lineHeight: "1.3" }}>
                                                        For a good result, use the gummies for a minimum of 3 months.
                                                    </li>
                                                    <li style={{ lineHeight: "2.5" }}>
                                                        All products are inclusive of all taxes.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content" style={{ marginTop: "2%" }}>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                            {this.state.AvailableCouponDetails.length > 0 ? (
                                <div className="card" style={{ marginBottom: 10 }}>
                                    <div className="card-body">
                                        <h3 className="card-title">Available Coupons</h3>
                                        <table style={{ width: "100%", marginBottom: 20 }}>
                                            <thead>
                                                <th>Name</th>
                                                <th>Coupon Code</th>
                                                <th>Coupon Discount</th>
                                            </thead>
                                            <tfoot>
                                                {this.state.AvailableCouponDetails.map((val, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td style={{ textAlign: "left" }}>{val.COUPON_NAME}</td>
                                                            <td
                                                                style={{ cursor: "pointer", textAlign: "left" }}
                                                                onClick={() => this.ApplayCouponAuto(val.COUPON_CODE)}
                                                            >
                                                                {val.COUPON_CODE}
                                                            </td>
                                                            <td style={{ textAlign: "left" }}>
                                                                {val.COUPON_DISCOUNT} %
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            ) : (
                                CouponDetails()
                            )}
                            {cart.couponPkid == "" || cart.couponPkid == null ? (
                                <div className="card">
                                    <div className="card-body">
                                        <h3 className="card-title">Apply Coupon Details</h3>
                                        <form className="cart__coupon-form" style={{ width: "100%" }}>
                                            <label htmlFor="input-coupon-code" className="sr-only">
                                                Password
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="input-coupon-code"
                                                placeholder="Enter Coupon Code"
                                                value={this.state.Coupon}
                                                onChange={(e) => this.setState({ Coupon: e.target.value })}
                                            />
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={this.ApplyCoupon}
                                            >
                                                Apply Coupon
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            ) : (
                                <div className="card">
                                    <div className="card-body">
                                        <h3 className="card-title">Applied Coupon Details</h3>
                                        <form>
                                            <div className="row">
                                                <div className="col-10">
                                                    <input
                                                        type="text"
                                                        readOnly={true}
                                                        className="form-control"
                                                        id="input-coupon-code"
                                                        placeholder="Enter Coupon Code"
                                                        value={cart.couponCode}
                                                        style={{ cursor: "no-drop" }}
                                                    />
                                                </div>
                                                <div className="col-2">{removeCouponButton}</div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                            <div className="card">
                                <div className="card-body">
                                    <h3 className="card-title">Cart Totals</h3>
                                    <table style={{ width: "100%", marginBottom: 20 }}>
                                        {this.renderTotals()}
                                        <tfoot>
                                            <tr>
                                                <th>
                                                    General Discount
                                                    <div style={{ float: "right" }} className="tooltipp_g">
                                                        <Link to="/shop/pricecal">
                                                            <Info />
                                                        </Link>

                                                        <span className="tooltiptextt_g">
                                                            View General Discount Details
                                                        </span>
                                                    </div>
                                                </th>
                                                <td>
                                                    <span style={{ fontSize: "15px", fontWeight: "900" }}>-</span>
                                                    &nbsp;&nbsp;
                                                    <Currency value={cart.GeneralDiscount} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Coupon Discount</th>
                                                <td>
                                                    <span style={{ fontSize: "15px", fontWeight: "900" }}>-</span>
                                                    &nbsp;&nbsp;
                                                    <Currency value={cart.couponPrice} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Total</th>
                                                <td>
                                                    <Currency value={cart.total} />
                                                </td>
                                            </tr>
                                            {this.ShippingCharge()}
                                            <tr>
                                                <th>Grand Total</th>
                                                <td>
                                                    <Currency value={cart.grandTotal} />
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>

                                    {UserLoggedIN == "null" || UserLoggedIN == null ? (
                                        <Link
                                            onClick={this.ProceedCheckOut}
                                            className="btn btn-primary btn-xl btn-block cart__checkout-button"
                                        >
                                            Proceed to checkout
                                        </Link>
                                    ) : (
                                        <Link
                                            to={{
                                                pathname: "/shop/address",
                                                state: { CouponDetails: this.state.CouponDetails },
                                            }}
                                            className="btn btn-primary btn-xl btn-block cart__checkout-button"
                                        >
                                            Proceed to checkout
                                        </Link>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { cart } = this.props;
        const breadcrumb = [
            { title: "Home", url: "" },
            { title: "Shopping Cart", url: "" },
        ];

        let content;

        if (cart.quantity) {
            content = this.renderCart();
        } else {
            content = (
                <div className="block block-empty">
                    <div className="container">
                        <div className="block-empty__body">
                            <div className="block-empty__message">Your shopping cart is empty!</div>
                            <div className="block-empty__actions">
                                <Link to="/Products/OurProducts" className="btn btn-primary btn-sm">
                                    Continue
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <React.Fragment>
                <Helmet>
                    <title>{`Shopping Cart — ${theme.name}`}</title>
                </Helmet>

                <PageHeader header="Shopping Cart" breadcrumb={breadcrumb} />

                {content}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    cart: state.cart,
});

const mapDispatchToProps = {
    cartRemoveItem,
    cartUpdateQuantities,
    cartUpdateCoupons,
    cartRemoveCoupon,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageCart);
