/* eslint-disable quotes */
/* eslint-disable */
/* eslint-disable prefer-template */
/* eslint-disable max-len */
// react
import React, { Component } from "react";

import axios from "axios";
import { MyApiUrl, ViewImg } from "../services/service";
// third-party
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import { Link, Redirect, useLocation } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// application
import Collapse from "../shared/Collapse";
import Currency from "../shared/Currency";
import PageHeader from "../shared/PageHeader";
import { Info } from "../../svg";

// data stubs
import payments from "../../data/shopPayments";
import theme from "../../data/theme";
import { cartEmptyItem } from "../../store/cart";

class ShopPageCheckout extends Component {
    payments = payments;

    constructor(props) {
        super(props);

        this.state = {
            payment: "bank",
            cartDetails: [],
            quantities: [],
        };
    }

    handlePaymentChange = (event) => {
        if (event.target.checked) {
            this.setState({ payment: event.target.value });
        }
    };

    renderTotals() {
        const { cart } = this.props;

        return (
            <React.Fragment>
                <tbody className="checkout__totals-subtotals">
                    <tr>
                        <th>Subtotal</th>
                        <td>
                            <Currency value={cart.subtotal} />
                        </td>
                    </tr>
                </tbody>
            </React.Fragment>
        );
    }

    ShippingCharge() {
        const { cart } = this.props;

        if (cart.extraLines.length <= 0) {
            return null;
        }

        const extraLines = cart.extraLines.map((extraLine, index) => {
            // let calcShippingLink;

            return (
                <tr key={index}>
                    <th>
                        {extraLine.title}
                        <div style={{ float: "right" }} className="tooltipp">
                            {/* <Link to="/shop/pricecal"></Link> */}
                            <Info />
                            <span className="tooltiptextt">
                                <table style={{ width: "100%" }}>
                                    <thead>
                                        <tr>
                                            <th style={{ textAlign: "center" }}>Price Range</th>
                                            <th style={{ textAlign: "center" }}>Discount (%)</th>
                                        </tr>
                                    </thead>
                                    <tbody className="cart-table__body">
                                        <tr>
                                            <td style={{ textAlign: "center" }}>0 to 1000</td>
                                            <td style={{ textAlign: "center" }}>8%</td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: "center" }}>1.000 to 3.000</td>
                                            <td style={{ textAlign: "center" }}>7%</td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: "center" }}>3.000 to 5.000</td>
                                            <td style={{ textAlign: "center" }}>5%</td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: "center" }}>5.000 to 10.000</td>
                                            <td style={{ textAlign: "center" }}>8%</td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: "center" }}>More Than 10.000</td>
                                            <td style={{ textAlign: "center" }}>Free</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </span>
                        </div>
                    </th>
                    <td>
                        <Currency value={extraLine.price} />
                    </td>
                </tr>
            );
        });
        return <React.Fragment>{extraLines}</React.Fragment>;
    }

    renderCart() {
        const { cart } = this.props;

        const items = cart.items.map((item) => (
            <tr key={item.id}>
                <td>{`${item.product.name} - ${item.unit} × ${item.quantity}`}</td>
                <td>
                    <Currency value={item.total} />
                </td>
            </tr>
        ));

        return (
            <table style={{ width: "100%", marginBttom: 20 }}>
                <thead>
                    <tr>
                        <th>Product</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>{items}</tbody>
                {this.renderTotals()}
                <tfoot>
                    <tr>
                        <th>
                            General Discount
                            <div style={{ float: "right" }} className="tooltipp_g">
                                <Link to="/shop/pricecal">
                                    <Info />
                                </Link>

                                <span className="tooltiptextt_g">View General Discount Details</span>
                            </div>
                        </th>
                        <td>
                            <span style={{ fontSize: "15px", fontWeight: "900" }}>-</span>
                            &nbsp;&nbsp;
                            <Currency value={cart.GeneralDiscount} />
                        </td>
                    </tr>
                    <tr>
                        <th>Coupon Discount</th>
                        <td>
                            <span style={{ fontSize: "15px", fontWeight: "900" }}>-</span>
                            &nbsp;&nbsp;
                            <Currency value={cart.couponPrice} />
                        </td>
                    </tr>
                    <tr>
                        <th>Total</th>
                        <td>
                            <Currency value={cart.total} />
                        </td>
                    </tr>
                    {this.ShippingCharge()}
                    <tr>
                        <th>Grand Total</th>
                        <td>
                            <Currency value={cart.grandTotal} />
                        </td>
                    </tr>
                </tfoot>
            </table>
        );
    }

    renderPaymentsList() {
        const { payment: currentPayment } = this.state;

        const payments = this.payments.map((payment) => {
            const renderPayment = ({ setItemRef, setContentRef }) => (
                <li className="payment-methods__item" ref={setItemRef} style={{ marginTop: 15 }}>
                    <label className="payment-methods__item-header">
                        <span className="payment-methods__item-radio input-radio">
                            <span className="input-radio__body">
                                <input
                                    type="radio"
                                    className="input-radio__input"
                                    name="checkout_payment_method"
                                    value={payment.key}
                                    checked={currentPayment === payment.key}
                                    onChange={this.handlePaymentChange}
                                />
                                <span className="input-radio__circle" />
                            </span>
                        </span>
                        <span className="payment-methods__item-title">{payment.title}</span>
                    </label>
                    <div className="payment-methods__item-container" ref={setContentRef}>
                        <div className="payment-methods__item-description text-muted">{payment.description}</div>
                    </div>
                </li>
            );

            return (
                <Collapse
                    key={payment.key}
                    open={currentPayment === payment.key}
                    toggleClass="payment-methods__item--active"
                    render={renderPayment}
                />
            );
        });

        return (
            <div className="payment-methods">
                <ul className="payment-methods__list">{payments}</ul>
            </div>
        );
    }
    cartNeedUpdate() {
        const { quantities } = this.state;
        const { cart } = this.props;

        return (
            quantities.filter((x) => {
                const item = cart.items.find((item) => item.id === x.itemId);

                return item && item.quantity !== x.value && x.value !== "";
            }).length > 0
        );
    }
    ConfirmPlaceOrder = () => {
        const UserLoggedIN = localStorage.getItem("UserLoggedIN");
        if (UserLoggedIN == null || UserLoggedIN == "null") {
            toast.error("Please Login to Checkout!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        } else {
            const { cart, cartEmptyItem } = this.props;
            const address = [this.props.location.state.address];
            const UserID = localStorage.getItem("UserID");
            const productDetails = [];
            for (let i = 0; i < cart.items.length; i++) {
                const value = {
                    ORDER_ITEM_PRODUCT_FKID: cart.items[i].product.pkid,
                    ORDER_ITEM_UNIT_FKID: cart.items[i].unitPkid,
                    ORDER_ITEM_QUANTITY: cart.items[i].quantity,
                    ORDER_ITEM_AMOUNT: cart.items[i].total,
                };
                productDetails.push(value);
            }
            const obj = {
                ORDERS_CUSTOMER_FKID: UserID,
                ORDERS_ADDRESS_FKID: address[0].USER_ADDRESS_PKID,
                ORDERS_COUPON_TYPE: cart.couponType,
                ORDERS_SHIPPING_AMOUNT: cart.extraLines[0].price,
                ORDERS_PAYMENT_MODE: "PayU",
                ORDERS_NO_OF_ITEMS: cart.lastItemId,
                ORDERS_COUPON_FKID: cart.couponPkid,
                ORDERS_COUPON_DISCOUNT: cart.couponPrice,
                ORDERS_GENERAL_DISCOUNT: cart.GeneralDiscount,
                ORDERS_GRAND_TOTAL: cart.grandTotal,
                ORDERS_SUB_TOTAL: cart.subtotal,
                ORDERS_TOTAL_AMOUNT: cart.total,
                OrderItems: productDetails,
            };
            axios.post(MyApiUrl + "PlaceOrder", obj).then((response) => {
                console.log(response);
                if (response.data == true) {
                    toast.success("Order Has been Placed...Please Check Order Details in Your Account!", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                    !this.cartNeedUpdate();
                    cartEmptyItem();
                    if (cart.items.length > 0) {
                    } else {
                        window.location.href = "/account/orders";
                    }
                } else {
                    toast.error("Failed to Place Order!", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                }
            });
        }
    };

    render() {
        const { cart } = this.props;
        console.log(this.props);
        const address = [this.props.location.state.address];
        console.log(address);
        if (cart.items.length < 1) {
            return <Redirect to="cart" />;
        }

        const breadcrumb = [
            { title: "Home", url: "" },
            { title: "Shopping Cart", url: "/shop/cart" },
            { title: "Shipping Details", url: "/shop/address" },
            { title: "Checkout", url: "" },
        ];
        const UserEmail = localStorage.getItem("UserEmail");
        const addresses = address.map((val, key) => (
            <React.Fragment key={val.USER_ADDRESS_PKID}>
                <div className="addresses-list__item card address-card" style={{ maxWidth: "100%" }}>
                    <div className="address-card__body">
                        <div className="address-card__name">{val.USER_ADDRESS_NAME}</div>
                        <div className="address-card__row">
                            {val.USER_ADDRESS_HOUSE_NO}, {val.USER_ADDRESS_STREET}
                            ,
                            <br />
                            {val.CITY_NAME}, {val.STATE_NAME}
                            ,
                            <br />
                            {val.COUNTRY_NAME}, {val.USER_ADDRESS_ZIPCODE}
                            <br />
                        </div>
                        <div className="address-card__row">
                            <div className="address-card__row-title">Phone Number</div>
                            <div className="address-card__row-content">{val.USER_ADDRESS_PHONE}</div>
                        </div>
                        <div className="address-card__row">
                            <div className="address-card__row-title">Email Address</div>
                            <div className="address-card__row-content">{UserEmail}</div>
                        </div>
                        <div className="address-card__row">
                            <div className="address-card__row-title">Address Type</div>
                            <div className="address-card__row-content">{val.USER_ADDRESS_TYPE}</div>
                        </div>
                    </div>
                </div>
                <div className="addresses-list__divider" />
            </React.Fragment>
        ));
        return (
            <React.Fragment>
                <Helmet>
                    <title>{`Checkout — ${theme.name}`}</title>
                </Helmet>

                <PageHeader header="Checkout" breadcrumb={breadcrumb} />

                <div className="checkout block">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-12 col-xl-12">
                                <div className="card mb-0">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-12 col-md-6">
                                                <h4 className="card-title">Your Order</h4>
                                                {this.renderCart()}
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <h4 style={{ marginBottom: 27 }}>Shipping Address</h4>
                                                {addresses}

                                                {this.renderPaymentsList()}

                                                <button
                                                    style={{ marginTop: 30 }}
                                                    type="button"
                                                    className="btn btn-primary btn-xl btn-block"
                                                    onClick={this.ConfirmPlaceOrder}
                                                >
                                                    Place Order
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    cart: state.cart,
    address: state.address,
});

const mapDispatchToProps = {
    cartEmptyItem,
};
export default connect(mapStateToProps, mapDispatchToProps)(ShopPageCheckout);
