/* eslint-disable max-len */
// react
import React from 'react';

// third-party
import { Link } from 'react-router-dom';

export default function BlockBanner() {
    return (
        <div className="block block-banner">
            <div className="container">
                <Link to="/" className="block-banner__body">
                    <div
                        className="block-banner__image block-banner__image--desktop"
                        style={{
                            backgroundImage: 'url("images/banners/banner-1.jpg")',
                            backgroundPosition: 'bottom',
                            boxShadow: '0px 0px 5px 1px #9f9f9f',
                            borderRadius: '10px',
                        }}
                    />
                    <div
                        className="block-banner__image block-banner__image--mobile"
                        style={{ backgroundImage: 'url("images/banners/banner-1-mobile.jpg")' }}
                    />
                    <div className="block-banner__title">
                        <p className="bannerpara">
                            AA Probics commenced its entrepreneur journey from Mysore, Karnataka, India, in 2019. It is
                            the first industry to receive Nutraceutical License in Mysore. As a Nutraceutical
                            Developers, manufacturers and supplier, AA Probics has created a niche for itself in this
                            field. We have expertise and capabilities in providing high-quality Nutraceutical Solutions
                            aimed at improving health and wellness through microbiota management. AA Probics
                            collaborates with business partners, research institutes, and hospitals not only in India
                            but across the globe, to provide documented, safe, and clinically proven formulations.
                        </p>
                        <p className="bannerpara">
                            AA Probics is believer and perpetrator of One Health concept. By One health they recognize
                            strong natural mutualism amongst health of humans, animals, and the mother nature. It
                            recognizes that the health of each of these components is inextricably linked, and a small
                            imbalance may bring significant impacts on the others.
                        </p>
                        <p className="bannerpara">
                            The importance of One Health lies in the fact that numerous health threats in terms of
                            infectious diseases are shared by humans, animals and the environment. The emergence of
                            diseases such as SARS, Ebola, and COVID-19, which have originated in animals and then spread
                            to humans, enunciates the criticality of One Health.
                        </p>
                        <p className="bannerpara">
                            One Health distinctness to health challenges are scientific and interdisciplinary, taking on
                            board human and animal health professionals, ecologists, environmental scientists and other
                            experts. This collaborative approach can lead to proactive and natural solutions to health
                            problems and help secure the health-bridge amongst humans, animals and the environment.
                        </p>
                    </div>
                </Link>
            </div>
        </div>
    );
}
