// react
/* eslint-disable */
/* eslint-disable prefer-template */
/* eslint-disable max-len */
/* eslint linebreak-style: ["error", "windows"] */
// react
import React, { Component, useState } from 'react';
import axios from 'axios';
import { MyApiUrl, ViewImg } from '../services/service';

// third-party
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import Currency from '../shared/Currency';
// data stubs
// import dataOrders from '../../data/accountOrders';
import theme from '../../data/theme';
import Rating from "../shared/Rating";

export default function AccountPageViewFeedback() {
    const UserID = localStorage.getItem("UserID");
    const [CardData, setCardData] = useState([]);

    const GetAddress = () => {
        axios.get(MyApiUrl + "getUserReviews/" + UserID + "").then((response) => {
            console.log(response);
            if (response.data.length != "0") {
                setCardData(response.data);
            }
            else {
                setCardData([]);
            }
        });
    };
    React.useEffect(() => {
        GetAddress();
    }, []);

    const getMonthName = (monthNumber) => {
        const date = new Date();
        date.setMonth(monthNumber - 1);

        return date.toLocaleString('en-US', { month: 'long' });
    }

    const SplitDate = (OrderDate) => {
        const MainDate = OrderDate.split("T");
        const SplitT = MainDate[0];
        const OrderDates = SplitT.split("-");
        const FinalDate = OrderDates[2] + " " + getMonthName(OrderDates[1]) + ", " + OrderDates[0];
        return FinalDate;
    }
    return (
        <div className="card">
            <Helmet>
                <title>{`My Orders — ${theme.name}`}</title>
            </Helmet>

            <div className="card-header">
                <h5>My Feedback & Reviews</h5>
            </div>
            <div className="card-divider" />
            <div className="card-table">
                <div className="table-responsive-sm">
                    <table>
                        <thead>
                            <tr>
                                <th>Product</th>
                                <th>Posted Date</th>
                                <th>Rating</th>
                                <th>Review</th>
                            </tr>
                        </thead>
                        <tbody>
                            {CardData.map((val, key) => {
                                return (
                                    <tr key={val.ORDERS_PKID}>
                                        <td>
                                            {val.PRODUCT_NAME}
                                        </td>
                                        <td>{SplitDate(val.PRODUCT_REVIEWS_DATE)}</td>
                                        <td><Rating value={val.PRODUCT_REVIEWS_STAR_RATINGS} /></td>
                                        <td>{val.PRODUCT_REVIEWS_COMMENTS}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="card-divider" />
        </div>
    );
}

