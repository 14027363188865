/* eslint-disable comma-dangle */
/* eslint-disable quotes */
/* eslint-disable max-len */
// react
import React from "react";

// third-party
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// application
import AsyncAction from "./AsyncAction";
import Rating from "./Rating";
import { cartAddItem } from "../../store/cart";
import { Quickview16Svg } from "../../svg";
import { compareAddItem } from "../../store/compare";
import { quickviewOpen } from "../../store/quickview";
import { url } from "../../services/utils";
import { wishlistAddItem } from "../../store/wishlist";
import { ViewImg } from "../services/service";

function ProductCard(props) {
    const {
        product,
        layout,
        quickviewOpen,
        // cartAddItem,
        // wishlistAddItem,
        // compareAddItem,
    } = props;
    const containerClasses = classNames("product-card", {
        "product-card--layout--grid product-card--size--sm": layout === "grid-sm",
        "product-card--layout--grid product-card--size--nl": layout === "grid-nl",
        "product-card--layout--grid product-card--size--lg": layout === "grid-lg",
        "product-card--layout--list": layout === "list",
        "product-card--layout--horizontal": layout === "horizontal",
    });

    let badges = [];
    let image;
    let features;

    // if (product.badges.includes("sale")) {
    //     badges.push(
    //         <div key="sale" className="product-card__badge product-card__badge--sale">
    //             Sale
    //         </div>
    //     );
    // }
    // if (product.badges.includes("hot")) {
    //     badges.push(
    //         <div key="hot" className="product-card__badge product-card__badge--hot">
    //             Hot
    //         </div>
    //     );
    // }
    // if (product.badges.includes("new")) {
    // }

    badges.push(
        <div key="new" className="product-card__badge product-card__badge--new">
            {product.badges}
        </div>
    );

    badges = product.badges === "0% off" ? null : <div className="product-card__badges-list">{badges}</div>;

    if (product.images && product.images.length > 0) {
        image = (
            <div className="product-card__image product-image">
                <Link to={url.product(product)} className="product-image__body">
                    <img className="product-image__img" src={ViewImg + product.images[0]} alt="" />
                </Link>
            </div>
        );
    }

    return (
        <React.Fragment>
            <div className={containerClasses}>
                <AsyncAction
                    action={() => quickviewOpen(product.pkid)}
                    render={({ run, loading }) => (
                        <button
                            type="button"
                            onClick={run}
                            className={classNames("product-card__quickview", {
                                "product-card__quickview--preload": loading,
                            })}
                        >
                            <Quickview16Svg />
                        </button>
                    )}
                />
                {badges}
                {image}
                <div className="product-card__info">
                    <div className="product-card__name">
                        <Link to={url.product(product)}>{product.name}</Link>
                    </div>
                    {layout === "horizontal" ? (
                        <React.Fragment>
                            <div>
                                <p>{product.desc}</p>
                            </div>
                        </React.Fragment>
                    ) : (
                        <p>&nbsp;</p>
                    )}
                    <div className="product-card__rating">
                        <Rating value={product.rating} />
                        <div className=" product-card__rating-legend">{`${product.reviews} Reviews`}</div>
                    </div>
                    {features}
                </div>
                <div className="product-card__actions">
                    <div className="product-card__availability">
                        Availability:
                        <span className="text-success">In Stock</span>
                    </div>
                    <div className="product-card__prices">
                        {product.badges === "0% off" ? (
                            <React.Fragment>
                                <span>{`${"₹"}${product.price}`}</span>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <span>{`${"₹"}${product.price}`}</span>
                                <span
                                    style={{
                                        fontSize: "12px",
                                        marginLeft: "4%",
                                        textDecoration: "line-through",
                                        color: "#747474",
                                    }}
                                >
                                    <mark style={{ color: "#747474" }}>{`${"₹"}${product.Actualprice}`}</mark>
                                </span>
                            </React.Fragment>
                        )}
                    </div>
                    <div className="product-card__buttons">
                        <Link to={url.product(product)} className="btn btn-primary product-card__addtocart">
                            Details
                        </Link>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

ProductCard.propTypes = {
    /**
     * product object
     */
    product: PropTypes.object.isRequired,
    /**
     * product card layout
     * one of ['grid-sm', 'grid-nl', 'grid-lg', 'list', 'horizontal']
     */
    layout: PropTypes.oneOf(["grid-sm", "grid-nl", "grid-lg", "list", "horizontal"]),
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    cartAddItem,
    wishlistAddItem,
    compareAddItem,
    quickviewOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductCard);
