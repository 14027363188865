// react
/* eslint-disable */
/* eslint-disable prefer-template */
/* eslint-disable max-len */
// react
import React, { useState } from 'react';
import axios from 'axios';
import { MyApiUrl } from '../services/service';

// third-party
import { Helmet } from 'react-helmet-async';
// import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// application
import PageHeader from '../shared/PageHeader';
import BlockLoader from '../blocks/BlockLoader';
// import { Check9x7Svg } from '../../svg';

// data stubs
import theme from '../../data/theme';
import '../../scss/custom.css';

import { useHistory } from "react-router-dom";

export default function ForgetPassword() {
    
  let history = useHistory();
    const breadcrumb = [
        { title: 'Home', url: '' },
        { title: 'Forget Password', url: '' },
    ];
    const [RegisterFields, setRegisterFields] = useState({
        Email: '',
    });

    const [forgetPasswordLoading, setforgetPasswordLoading] = useState(false);
    if (forgetPasswordLoading) {
        return <BlockLoader />;
    }

    const forgetPassword = () => {
        const ValidEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
        if (RegisterFields.Email === '' || RegisterFields.Email === null) {
            toast.warn('Please Enter Email!', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
            });
        } else if (ValidEmail.test(RegisterFields.Email) === false) {
            toast.warn('Please Enter Valid Email!', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
            });
        } else {
            setforgetPasswordLoading(true);
            const obj = {
                FORGET_PASSWORD_EMAIL: RegisterFields.Email,
            };
            axios.post(MyApiUrl + "forgotPassword", obj).then((response) => {
                if (response.data == true) {
                    setforgetPasswordLoading(false);
                    toast.success('OTP Sent Successfully. Please Check Your Mail!', {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'dark',
                    });
                    clear();
                    history.push("/ForgetPasswordOTPScreen", {
                        Email: RegisterFields.Email,
                    });
                } else if (response.data == false) {
                    setforgetPasswordLoading(false);
                    toast.error('Email Not Found!', {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'dark',
                    });
                }
            });
        }
    };

    const clear = () => {
        setRegisterFields({
            ...RegisterFields,
            Email: '',
        });
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Login — ${theme.name}`}</title>
            </Helmet>

            <PageHeader header="Forget Password" breadcrumb={breadcrumb} />

            <div className="block">
                <div className="container">
                    <div className="row">
                        <div className="col-md-2">&nbsp;</div>
                        <div className="col-md-8 d-flex mt-4 mt-md-0">
                            <div className="card flex-grow-1 mb-0">
                                <div className="card-body">
                                    <h3 className="card-title">Forget Password</h3>
                                    <form>
                                        <div className="row">
                                            <div className="col-md-8">
                                                <div className="form-group">
                                                    <label htmlFor="register-email" className="designLabel">
                                                        Email Address&nbsp;
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </label>
                                                    <input
                                                        type="email"
                                                        className="form-control designInputs"
                                                        placeholder="Enter Email Address"
                                                        value={RegisterFields.Email}
                                                        onChange={(event) => {
                                                            setRegisterFields({
                                                                ...RegisterFields,
                                                                Email: event.target.value,
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <button
                                                    onClick={clear}
                                                    type="button"
                                                    className="btn btn-warning mt-2 mt-md-3 mt-lg-4"
                                                    style={{ float: 'right' }}
                                                >
                                                    Clear
                                                </button>
                                                <button
                                                    type="button"
                                                    onClick={forgetPassword}
                                                    className="btn btn-success mt-2 mt-md-3 mt-lg-4"
                                                    style={{ float: 'right', marginRight: 10 }}
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2">&nbsp;</div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
