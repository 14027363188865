// react
/* eslint-disable */
/* eslint-disable prefer-template */
/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
/* eslint-disable object-curly-spacing */
/* eslint-disable quotes */
/* eslint-disable import/order */
/*eslint linebreak-style: ["error", "windows"]*/
// react
import React, { useState } from 'react';
import axios from 'axios';
import { MyApiUrl, ViewImg } from '../services/service';
// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import PageHeader from '../shared/PageHeader';
// data stubs
import dataAddresses from '../../data/accountAddresses';
import theme from '../../data/theme';

export default function ShopPageAddress() {
    const UserID = localStorage.getItem("UserID");
    const UserEmail = localStorage.getItem("UserEmail");
    const [CardData, setCardData] = useState([]);

    const GetAddress = () => {
        axios.get(MyApiUrl + "UserAddress/" + UserID + "").then((response) => {
            console.log(response);
            if (response.data.length != "0") {
                setCardData(response.data);
            }
        });
    };
    React.useEffect(() => {
        GetAddress();
    }, []);
    const addresses = CardData.map((val, key) => (
        <React.Fragment key={val.USER_ADDRESS_PKID}>
            <div className="addresses-list__item card address-card">
                <Link
                    to={{
                        pathname: "/shop/checkout",
                        state: { address: val },
                    }}
                    style={{ color: 'black' }}
                >
                    {val.USER_ADDRESS_DEFAULT && <div className="address-card__badge">Default</div>}
                    <div className="address-card__body">
                        <div className="address-card__name">{val.USER_ADDRESS_NAME}</div>
                        <div className="address-card__row">
                            {val.USER_ADDRESS_HOUSE_NO}, {val.USER_ADDRESS_STREET}
                            ,
                            <br />
                            {val.AREA_NAME}, {val.CITY_NAME}, {val.STATE_NAME}
                            ,
                            <br />
                            {val.COUNTRY_NAME}, {val.USER_ADDRESS_ZIPCODE}
                            <br />
                        </div>
                        <div className="address-card__row">
                            <div className="address-card__row-title">Phone Number</div>
                            <div className="address-card__row-content">{val.USER_ADDRESS_PHONE}</div>
                        </div>
                        <div className="address-card__row">
                            <div className="address-card__row-title">Email Address</div>
                            <div className="address-card__row-content">{UserEmail}</div>
                        </div>
                        <div className="address-card__row">
                            <div className="address-card__row-title">Address Type</div>
                            <div className="address-card__row-content">{val.USER_ADDRESS_TYPE}</div>
                        </div>
                    </div>
                </Link>
            </div>
            <div className="addresses-list__divider" />
        </React.Fragment>
    ));

    const breadcrumb = [
        { title: 'Home', url: '' },
        { title: 'Shopping Cart', url: '/shop/cart' },
        { title: 'Shipping Details', url: '/shop/address' },
    ];

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Our Products — ${theme.name}`}</title>
            </Helmet>
            <PageHeader header="Shipping Details" breadcrumb={breadcrumb} />
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h5 style={{ textAlign: 'center' }}>Select Your Shipping Address</h5>
                    </div>
                </div>

                <div className="addresses-list" style={{ padding: 10 }}>
                    {addresses}
                </div>
            </div>

        </React.Fragment>
    );
}
