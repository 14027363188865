/* eslint-disable linebreak-style */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable quotes */
/* eslint-disable linebreak-style */
/* eslint-disable prefer-template */
/* eslint-disable max-len */
// react
import React, { useState } from "react";

// third-party
import { Helmet } from "react-helmet-async";
import axios from "axios";
import { MyApiUrl } from "../services/service";
import PageHeader from "../shared/PageHeader";
// import { Link } from 'react-router-dom';

// application
// import StroykaSlick from '../shared/StroykaSlick';

// data stubs
import theme from "../../data/theme";

function ScienceData(props) {
    const ScienceID = props.match.params.pkid;
    const [ScienceData, setScienceData] = useState("");
    const [ScienceHeading, setScienceHeading] = useState("");
    const [SciencePkid, setSciencePkid] = useState("");
    const breadcrumb = [
        { title: "Home", url: "" },
        { title: "Science Data", url: "" },
    ];
    const GetAboutUs = () => {
        axios.get(MyApiUrl + "ScienceData/" + ScienceID + "").then((response) => {
            if (response.data.length > 0) {
                setSciencePkid(response.data[0].SCIENCE_DATA_PKID);
                setScienceData(response.data[0].SCIENCE_DATA);
                setScienceHeading(response.data[0].SCIENCE_TYPE_NAME);
            } else {
                setScienceData("");
                setScienceHeading("No Data Available");
            }
        })
            .catch((error) => {
                console.log(error);
            });
    };
    if (SciencePkid !== ScienceID) {
        GetAboutUs();
    }
    React.useEffect(React.useCallback(() => {
        GetAboutUs();
    }), []);
    return (
        <React.Fragment>
            <Helmet>
                <title>{`Contact Us — ${theme.name}`}</title>
            </Helmet>
            <PageHeader header={ScienceHeading} breadcrumb={breadcrumb} />
            <div className="block">
                <div className="container">
                    <div className="card mb-0" style={{ background: "#fbfbfb", boxShadow: "5px 5px 5px 0px #cbcbcb" }}>
                        <div className="card-body contact-us">
                            <div className="contact-us__container">
                                <div className="row">
                                    <div className="col-12 col-lg-12 pb-4 pb-lg-0">
                                        <h4 className="contact-us__header card-title">{ScienceHeading}</h4>
                                        <div dangerouslySetInnerHTML={{ __html: ScienceData }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ScienceData;
