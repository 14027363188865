// react
/* eslint-disable */
/* eslint-disable prefer-template */
/* eslint-disable max-len */
// react
import React, { useState } from 'react';
import axios from 'axios';
import { MyApiUrl, ViewImg } from '../services/service';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import '../../scss/custom.css';

// data stubs
import theme from '../../data/theme';

function SocialLinks(props) {
    const { shape, className } = props;
    const [facebook, setfacebook] = useState();
    const [insta, setinsta] = useState();
    const [linkedIn, setlinkedIn] = useState();
    const [whatsapp, setwhatsapp] = useState();
    const [twitter, settwitter] = useState();
    // const [CardView, setCardView] = useState(false);
    const GetAddress = async () => {
        axios.get(MyApiUrl + "ContactUs").then((response) => {
            if (response.data.length != 0) {
                console.log(response.data);
                setfacebook(response.data[0].CONTACT_FB_LINK);
                setinsta(response.data[0].CONTACT_INSTA_LINK);
                setlinkedIn(response.data[0].CONTACT_LINKEDIN_LINK);
                settwitter(response.data[0].CONTACT_TWITTER_LINK);
                setwhatsapp(response.data[0].CONTACT_WHATSAPP_LINK);
                // setCardView(true);
            }
            else {
                setfacebook("https://www.facebook.com/");
                setinsta("https://www.instagram.com/");
                setlinkedIn("https://in.linkedin.com/");
                setwhatsapp("https://wa.me/");
                settwitter("https://twitter.com/");
            }
        });
    };
    React.useEffect(() => {
        GetAddress();
    }, []);
    const classes = classNames(className, 'social-links', {
        'social-links--shape--circle': shape === 'circle',
        'social-links--shape--rounded': shape === 'rounded',
    });

    const items = [
        { type: 'facebook', url: facebook, icon: 'fab fa-facebook-f' },
        { type: 'twitter', url: twitter, icon: 'fab fa-twitter' },
        { type: 'whatsapp', url: whatsapp, icon: 'fab fa-whatsapp whatsapp' },
        { type: 'instagram', url: insta, icon: 'fab fa-instagram instagram' },
        { type: 'linkedin', url: linkedIn, icon: 'fab fa-linkedin linkedin' },
    ].map((item) => (
        <li key={item.type} className="social-links__item">
            <a
                className={`social-links__link social-links__link--type--${item.type}`}
                href={item.url}
                target="_blank"
                // rel="noopener noreferrer"
            >
                <i className={item.icon} />
            </a>
        </li>
    ));

    return (
        <div className={classes}>
            <ul className="social-links__list">
                { items }
            </ul>
        </div>
    );
}

SocialLinks.propTypes = {
    /**
     * rating value
     */
    shape: PropTypes.oneOf(['circle', 'rounded']),
    className: PropTypes.string,
};
SocialLinks.defaultProps = {
    shape: null,
};

export default SocialLinks;
