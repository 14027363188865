/* eslint-disable arrow-body-style */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable object-curly-newline */
/* eslint-disable max-len */
/* eslint-disable quotes */
// react
import React, { useState } from "react";
import { Link } from "react-router-dom";
// third-party
import classNames from "classnames";
// import PropTypes from "prop-types";
// application
import axios from "axios";
import Collapse from "../shared/Collapse";

import { MyApiUrl } from "../services/service";
import { url } from '../../services/utils';

function WidgetFilters(props) {
    const { title, offcanvas } = props;
    const [ProductCategory, setProductCategory] = useState([]);
    const GetAboutUs = () => {
        axios({
            method: "GET",
            url: `${MyApiUrl}getAllCategoryAndSubCategory`,
            headers: {
                "content-type": "application/json",
            },
        })
            .then((response) => {
                setProductCategory(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };
    // const GetProductsByCate = (CategoryID) => {
    //     console.log(CategoryID);
    // };
    React.useEffect(() => {
        GetAboutUs();
    }, []);
    const filtersList = ProductCategory.map((filter) => {
        return (
            <div key="category__" className="widget-filters__item">
                <Collapse
                    toggleClass="filter--opened"
                    render={({ toggle, setItemRef, setContentRef }) => (
                        <div className="filter filter--opened" ref={setItemRef}>
                            <button type="button" className="filter__title" onClick={toggle}>
                                {filter.PRODUCT_CATEGORY_NAME}
                            </button>
                            <div className="filter__body" ref={setContentRef}>
                                <div className="filter__container">
                                    <div className="filter-categories">
                                        <ul className="filter-categories__list">
                                            {filter.SubCategoryArr.map((parent) => (
                                                <li className="filter-categories__item filter-categories__item--parent">
                                                    <Link to={url.category(parent.PRODUCT_SUB_CATEGORY_PKID)}>
                                                        {parent.PRODUCT_SUB_CATEGORY_NAME}
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                />
            </div>
        );
    });

    const classes = classNames("widget-filters widget", {
        "widget-filters--offcanvas--always": offcanvas === "always",
        "widget-filters--offcanvas--mobile": offcanvas === "mobile",
    });

    return (
        <div className={classes}>
            <h4 className="widget-filters__title widget__title">{title}</h4>

            <div className="widget-filters__list">{filtersList}</div>

            <div className="widget-filters__actions d-flex mb-n2">
                {/* <button
                    type="button"
                    className="btn btn-secondary btn-sm"
                    onClick={handleResetFilters}
                >
                    Reset
                </button> */}
            </div>
        </div>
    );
}

WidgetFilters.defaultProps = {
    offcanvas: "mobile",
};

export default WidgetFilters;
