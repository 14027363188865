/* eslint-disable */
/* eslint-disable prefer-template */
/* eslint-disable max-len */
/* eslint linebreak-style: ["error", "windows"] */
// react
import React, { useState } from 'react';
import axios from 'axios';
import { MyApiUrl, ViewImg } from '../services/service';

import Currency from '../shared/Currency';
import { Link, useLocation, useHistory } from 'react-router-dom';
// third-party
import { Helmet } from 'react-helmet-async';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// application
import PageHeader from '../shared/PageHeader';

// data stubs
import theme from '../../data/theme';
import '../../scss/order_tracking.css';
import '../../scss/custom.css';

function SiteOrderDetails(props) {
    const history = useHistory();
    const breadcrumb = [
        { title: 'Home', url: '/' },
        { title: 'Track Order', url: '/shop/track-order' },
        { title: 'Order Details', url: '' },
    ];

    const [CardData, setCardData] = useState([]);
    const [CardView, setCardView] = useState(false);
    const [OrderStatus, setOrderStatus] = useState({
        One: "step",
        Two: "step",
        Three: "step",
        Four: "step",
        Five: "step",
    });

    const orderDetails = props.location.state.OrderDetails[0];

    const getMonthName = (monthNumber) => {
        const date = new Date();
        date.setMonth(monthNumber - 1);

        return date.toLocaleString('en-US', { month: 'long' });
    }

    const SplitDate = (OrderDate) => {
        const MainDate = OrderDate.split("T");
        const SplitT = MainDate[0];
        const OrderDates = SplitT.split("-");
        const FinalDate = OrderDates[2] + " " + getMonthName(OrderDates[1]) + ", " + OrderDates[0];
        return FinalDate;
    }

    const GetAddress = async () => {
        await axios.get(MyApiUrl + "OrderItems/" + orderDetails.ORDERS_PKID + "").then((response) => {
            console.log(response);
            if (response.data.length > 0) {
                setCardData(response.data);
                console.log(CardData);
                setCardView(true);
                if (orderDetails.ORDERS_STATUS_ID == 0) {
                    setOrderStatus({
                        ...OrderStatus,
                        One: "step completed",
                        Two: "step",
                        Three: "step",
                        Four: "step",
                        Five: "step",
                    });
                } else if (orderDetails.ORDERS_STATUS_ID == 1) {
                    setOrderStatus({
                        ...OrderStatus,
                        One: "step completed",
                        Two: "step completed",
                        Three: "step",
                        Four: "step",
                        Five: "step",
                    });
                } else if (orderDetails.ORDERS_STATUS_ID == 2) {
                    setOrderStatus({
                        ...OrderStatus,
                        One: "step completed",
                        Two: "step completed",
                        Three: "step completed",
                        Four: "step",
                        Five: "step",
                    });
                } else if (orderDetails.ORDERS_STATUS_ID == 3) {
                    setOrderStatus({
                        ...OrderStatus,
                        One: "step completed",
                        Two: "step completed",
                        Three: "step completed",
                        Four: "step completed",
                        Five: "step",
                    });
                } else if (orderDetails.ORDERS_STATUS_ID == 4) {
                    setOrderStatus({
                        ...OrderStatus,
                        One: "step completed",
                        Two: "step completed",
                        Three: "step completed",
                        Four: "step completed",
                        Five: "step completed",
                    });
                }
            }
            else {
                setCardData([]);
            }
        });
    };
    React.useEffect(() => {
        GetAddress();
    }, []);

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Order Details — ${theme.name}`}</title>
            </Helmet>
            <PageHeader breadcrumb={breadcrumb} />
            <div className="container">
                {CardView == true ?
                    <div>
                        {orderDetails.ORDERS_STATUS_ID == 5 ?
                            <div className="card">
                                <div className="card-body">
                                    <div class="steps d-flex flex-wrap flex-sm-nowrap justify-content-between padding-top-2x padding-bottom-1x">
                                        <div class="step completed">
                                            <div class="step-icon-wrap">
                                                <div class="step-icon"><i class="pe-7s-cart"></i></div>
                                            </div>
                                            <h4 class="step-title">Order Placed</h4>
                                        </div>
                                        <div class="step rejected">
                                            <div class="step-icon-wrap">
                                                <div class="step-icon"><i class="pe-7s-close"></i></div>
                                            </div>
                                            <h4 class="step-title">Order Rejected</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="card">
                                <div className="card-body">
                                    <div class="steps d-flex flex-wrap flex-sm-nowrap justify-content-between padding-top-2x padding-bottom-1x">
                                        <div class={OrderStatus.One}>
                                            <div class="step-icon-wrap">
                                                <div class="step-icon"><i class="pe-7s-cart"></i></div>
                                            </div>
                                            <h4 class="step-title">Order Placed</h4>
                                        </div>
                                        <div class={OrderStatus.Two}>
                                            <div class="step-icon-wrap">
                                                <div class="step-icon"><i class="pe-7s-check"></i></div>
                                            </div>
                                            <h4 class="step-title">Order Confirmed</h4>
                                        </div>
                                        <div class={OrderStatus.Three}>
                                            <div class="step-icon-wrap">
                                                <div class="step-icon"><i class="pe-7s-shopbag"></i></div>
                                            </div>
                                            <h4 class="step-title">Packed</h4>
                                        </div>
                                        <div class={OrderStatus.Four}>
                                            <div class="step-icon-wrap">
                                                <div class="step-icon"><i class="pe-7s-car"></i></div>
                                            </div>
                                            <h4 class="step-title">Dispatched</h4>
                                        </div>
                                        <div class={OrderStatus.Five}>
                                            <div class="step-icon-wrap">
                                                <div class="step-icon"><i class="pe-7s-home"></i></div>
                                            </div>
                                            <h4 class="step-title">Delivered</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="card" style={{ marginTop: 10 }}>
                            <div className="order-header">
                                <div className="order-header__actions">
                                    <Link to="/shop/track-order" className="btn btn-xs btn-secondary">Back to Track Order</Link>
                                </div>
                                <h5 className="order-header__title" style={{ paddingright: 0 }}>Order <span style={{ color: '#0484cb' }}>
                                    {orderDetails.ORDERS_NUMBER}
                                    {" "}
                                    {(orderDetails.ORDERS_STATUS_ID > "2" || orderDetails.ORDERS_STATUS_ID > 2) && (orderDetails.ORDERS_STATUS_ID < "5" || orderDetails.ORDERS_STATUS_ID < 5) ?
                                        <a href={ViewImg + orderDetails.ORDERS_DOC} download target="_blank">
                                            <i className="fas fa-download" />
                                        </a>
                                        :
                                        ""
                                    }
                                </span></h5>
                                <div className="order-header__subtitle">
                                    Was placed on
                                    {' '}
                                    <mark className="order-header__date">{SplitDate(orderDetails.ORDERS_DATE)}</mark>
                                    {' '}
                                    and is currently
                                    {' '}
                                    <mark className="order-header__status">{orderDetails.ORDER_STATUS}</mark>
                                    .
                                </div>
                            </div>
                            <div className="card-divider" />
                            <div className="card-table">
                                <div className="table-responsive-sm">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <th>PRODUCT</th>
                                                <th>TOTAL</th>
                                            </tr>
                                        </tbody>
                                        <tbody className="card-table__body card-table__body--merge-rows">
                                            {CardData[0].ORDERS_ITEMS.map((order, key) => {
                                                return (
                                                    <tr key={order.ORDER_ITEM_PKID}>
                                                        <td>{order.PRODUCT_NAME} - {order.PRODUCT_UNIT_QTY} × {order.ORDER_ITEM_QUANTITY}</td>
                                                        <td><Currency value={order.ORDER_ITEM_AMOUNT} /></td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                        <tbody className="card-table__body card-table__body--merge-rows">
                                            <tr>
                                                <th>Subtotal</th>
                                                <th><Currency value={CardData[0].ORDERS_SUB_TOTAL} /></th>
                                            </tr>
                                            <tr>
                                                <th>Shipping</th>
                                                <th><Currency value={CardData[0].ORDERS_SHIPPING_AMOUNT} /></th>
                                            </tr>
                                            <tr>
                                                <th>Total</th>
                                                <th><Currency value={CardData[0].ORDERS_TOTAL_AMOUNT} /></th>
                                            </tr>
                                            <tr>
                                                <th>Coupon Discount</th>
                                                <th><Currency value={CardData[0].ORDERS_COUPON_DISCOUNT} /></th>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th>Grand Total</th>
                                                <th><Currency value={CardData[0].ORDERS_GRAND_TOTAL} /></th>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-3 no-gutters mx-n2">
                            <div className="col-sm-6 col-12 px-2">
                                <div className="card address-card address-card--featured">
                                    <div className="address-card__body">
                                        {CardData[0].ORDERS_ADDRESS.map((val, key) => {
                                            return (
                                                <div>
                                                    <div className="address-card__badge address-card__badge--muted">Shipping Address</div>
                                                    <div className="address-card__name">{val.USER_ADDRESS_NAME}</div>
                                                    <div className="address-card__row">
                                                        {val.USER_ADDRESS_HOUSE_NO}, {val.USER_ADDRESS_STREET}
                                                        ,
                                                        <br />
                                                        {val.AREA_NAME}, {val.CITY_NAME}, {val.STATE_NAME}
                                                        ,
                                                        <br />
                                                        {val.COUNTRY_NAME}, {val.USER_ADDRESS_ZIPCODE}
                                                        <br />
                                                    </div>
                                                    <div className="address-card__row">
                                                        <div className="address-card__row-title">Phone Number</div>
                                                        <div className="address-card__row-content">{val.USER_ADDRESS_PHONE}</div>
                                                    </div>
                                                    <div className="address-card__row">
                                                        <div className="address-card__row-title">Address Type</div>
                                                        <div className="address-card__row-content">{val.USER_ADDRESS_TYPE}</div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                            {CardData[0].ORDERS_COUPONS.length > 0 ?

                                <div className="col-sm-6 col-12 px-2 mt-sm-0 mt-3">
                                    <div className="card address-card address-card--featured">
                                        <div className="address-card__body" style={{ minHeight: '16.7em' }}>
                                            {CardData[0].ORDERS_COUPONS.map((val, key) => {
                                                return (
                                                    <div>
                                                        <div className="address-card__badge address-card__badge--muted">Coupon Details</div>
                                                        <div className="address-card__row">
                                                            <div className="address-card__row-title">Coupon Type</div>
                                                            <div className="address-card__row-content">{val.MEMBER_COUPON_TYPE}</div>
                                                        </div>
                                                        <div className="address-card__row">
                                                            <div className="address-card__row-title">Coupon Discount</div>
                                                            <div className="address-card__row-content">{val.COUPON_DISCOUNT}% ( upto <Currency value={CardData[0].ORDERS_COUPON_DISCOUNT} /> )</div>
                                                        </div>
                                                        <div className="address-card__row">
                                                            <div className="address-card__row-title">Coupon Code</div>
                                                            <div className="address-card__row-content">{val.COUPON_CODE}</div>
                                                        </div>
                                                        <div className="address-card__row">
                                                            <div className="address-card__row-title">Coupon Reference</div>
                                                            <div className="address-card__row-content">{val.MEMBER_NAME}</div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="col-sm-6 col-12 px-2 mt-sm-0 mt-3">
                                    <div className="card address-card address-card--featured">
                                        <div className="address-card__body" style={{ minHeight: '16.6em !important' }}>
                                            <div>
                                                <div className="address-card__badge address-card__badge--muted">Coupon Details</div>
                                                <div className="address-card__row">
                                                    <div className="address-card__row-content">Coupon Not Applied</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    :
                    ""
                }
            </div>
        </React.Fragment>
    );
}

export default SiteOrderDetails;
