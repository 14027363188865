/* eslint-disable */
/* eslint-disable prefer-template */
/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
/* eslint-disable object-curly-spacing */
/* eslint-disable quotes */
/* eslint-disable import/order */
// react
import React, { useState } from "react";

// third-party
import axios from "axios";
import { MyApiUrl, ViewImg } from "../services/service";

// application
// import Pagination from '../shared/Pagination';
import Rating from '../shared/Rating';

// data stubs
import reviews from '../../data/shopProductReviews';

function ProductTabReviews(props) {
    const { product } = props;
    const [ReviewData, setReviewData] = useState();
    const [ReviewView, setReviewView] = useState(false);
    const GetProductsList = async () => {
        await axios({
            method: "GET",
            url: `${MyApiUrl}UserReviewsID/${product.pkid}`,
            headers: {
                "content-type": "application/json",
            },
        })
            .then((response) => {
                setReviewData(response.data);
                setReviewView(true);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    React.useEffect(() => {
        GetProductsList();
    }, []);

    // const reviewsList = ReviewData.map((review, index) => (
    //     <li key={index} className="reviews-list__item">
    //         <div className="review">
    //             <div className="review__avatar"><img src={review.avatar} alt="" /></div>
    //             <div className=" review__content">
    //                 <div className=" review__author">{review.author}</div>
    //                 <div className=" review__rating">
    //                     <Rating value={review.rating} />
    //                 </div>
    //                 <div className=" review__text">{review.text}</div>
    //                 <div className=" review__date">{review.date}</div>
    //             </div>
    //         </div>
    //     </li>
    // ));
    const getMonthName = (monthNumber) => {
        const date = new Date();
        date.setMonth(monthNumber - 1);

        return date.toLocaleString('en-US', { month: 'long' });
    }

    const SplitDate = (OrderDate) => {
        const MainDate = OrderDate.split("T");
        const SplitT = MainDate[0];
        const OrderDates = SplitT.split("-");
        const FinalDate = OrderDates[2] + " " + getMonthName(OrderDates[1]) + ", " + OrderDates[0];
        return FinalDate;
    }
    return (
        <div className="reviews-view">
            <div className="reviews-view__list">
                <h3 className="reviews-view__header">Customer Reviews</h3>

                <div className="reviews-list">
                    <ol className="reviews-list__content">
                        {ReviewView == true ?
                            ReviewData.map((review, index) => (
                                <li key={index} className="reviews-list__item">
                                    <div className="review">
                                        <div className="review__avatar"><img src={ViewImg + review.REGISTERED_USERS_PROFILE} alt="" /></div>
                                        <div className=" review__content">
                                            <div className=" review__author">{review.REGISTERED_USERS_NAME}</div>
                                            <div className=" review__rating">
                                                <Rating value={review.PRODUCT_REVIEWS_STAR_RATINGS} />
                                            </div>
                                            <div className=" review__text">{review.PRODUCT_REVIEWS_COMMENTS}</div>
                                            <div className=" review__date">{SplitDate(review.PRODUCT_REVIEWS_DATE)}</div>
                                        </div>
                                    </div>
                                </li>
                            ))
                            : ""}
                    </ol>
                    {/* <div className="reviews-list__pagination">
                        <Pagination current={1} siblings={2} total={10} />
                    </div> */}
                </div>
            </div>
        </div>
    );
}

export default ProductTabReviews;
