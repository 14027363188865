// react
/* eslint-disable */
/* eslint-disable prefer-template */
/* eslint-disable max-len */
// react
import React from 'react';
// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { ViewImg } from '../services/service';

function PostCard(props) {
    const { post, layout } = props;
    const cardClasses = classNames(
        'post-card',
        {
            'post-card--layout--grid': ['grid-nl', 'grid-lg'].includes(layout),
            'post-card--layout--list': ['list-nl', 'list-sm'].includes(layout),
            'post-card--size--nl': ['grid-nl', 'list-nl'].includes(layout),
            'post-card--size--lg': layout === 'grid-lg',
            'post-card--size--sm': layout === 'list-sm',
        },
    );
    // const categories = post.categories.map((category, index) => (
    //     <Link key={index} to="/">{category}</Link>
    // ));
    const getMonthName = (monthNumber) => {
        const date = new Date();
        date.setMonth(monthNumber - 1);

        return date.toLocaleString('en-US', { month: 'long' });
    };

    const SplitDate = (OrderDate) => {
        const MainDate = OrderDate.split('T');
        const SplitT = MainDate[0];
        const OrderDates = SplitT.split('-');
        const FinalDate = OrderDates[2] + ' ' + getMonthName(OrderDates[1]) + ', ' + OrderDates[0];
        return FinalDate;
    }
    return (
        <div className={cardClasses}>
            <div className="post-card__image">
                <img src={ViewImg + post.AWARD_IMAGE} alt="" />
            </div>
            <div className="post-card__info">
                {/* <div className="post-card__category">
                    {categories}
                </div> */}
                <div className="post-card__name">
                    {post.AWARD_NAME}
                </div>
                <div className="post-card__date">{SplitDate(post.AWARD_DATE)}</div>
                <div className="post-card__content">
                    {/* In one general sense, philosophy is associated with wisdom,
                    intellectual culture and a search for knowledge.
                    In that sense, all cultures... */}
                </div>
                <div className="post-card__read-more">
                    <Link to="/" className="btn btn-secondary btn-sm">Read More</Link>
                </div>
            </div>
        </div>
    );
}

PostCard.propTypes = {
    /**
     * post data object
     */
    post: PropTypes.object,
    /**
     * post card layout
     * one of ['grid-nl', 'grid-lg', 'list-nl', 'list-sm']
     */
    layout: PropTypes.oneOf(['grid-nl', 'grid-lg', 'list-nl', 'list-sm']),
};

export default PostCard;
