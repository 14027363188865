/* eslint-disable quotes */
/* eslint-disable */
/* eslint-disable prefer-template */
/* eslint-disable max-len */
/* eslint linebreak-style: ["error", "windows"] */
// react
import React, { useState } from 'react';
import axios from 'axios';
import { MyApiUrl } from '../services/service';

// third-party
import { Helmet } from 'react-helmet-async';
// import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// application
import PageHeader from '../shared/PageHeader';
import BlockLoader from '../blocks/BlockLoader';
// import { Check9x7Svg } from '../../svg';

// data stubs
import theme from '../../data/theme';
import '../../scss/custom.css';
import { Link, useLocation, useHistory } from "react-router-dom";

export default function ForgetPasswordOTPScreen(props) {
    const location = useLocation();
    let history = useHistory();
    const Email = props.location.state.Email;
    const breadcrumb = [
        { title: 'Home', url: '' },
        { title: 'Forget Password', url: '/ForgetPassword' },
        { title: 'Verify OTP', url: '' },
    ];
    const [RegisterFields, setRegisterFields] = useState({
        OTP: '',
    });

    const forgetPasswordOTP = () => {
        if (RegisterFields.OTP === '' || RegisterFields.OTP === null) {
            toast.warn('Please Enter OTP!', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
            });
        } else {
            const obj = {
                FORGET_PASSWORD_EMAIL: Email,
                FORGET_PASSWORD_OTP: RegisterFields.OTP,
            };
            axios.post(MyApiUrl + "checkOTP", obj).then((response) => {
                if (response.data == true) {
                    toast.success('OTP Verified Successfully. Please Change Your Password!', {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'dark',
                    });
                    clear();
                    history.push("/ForgetPasswordChangePassword", {
                        Email: Email,
                    });
                } else if (response.data == false) {
                    toast.error('Failed to Verify OTP!', {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'dark',
                    });
                }
            });
        }
    };

    const clear = () => {
        setRegisterFields({
            ...RegisterFields,
            OTP: '',
        });
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Login — ${theme.name}`}</title>
            </Helmet>

            <PageHeader header="Verify OTP" breadcrumb={breadcrumb} />

            <div className="block">
                <div className="container">
                    <div className="row">
                        <div className="col-md-2">&nbsp;</div>
                        <div className="col-md-8 d-flex mt-4 mt-md-0">
                            <div className="card flex-grow-1 mb-0">
                                <div className="card-body">
                                    <h3 className="card-title">Verify OTP</h3>
                                    <form>
                                        <div className="row">
                                            <div className="col-md-8">
                                                <div className="form-group">
                                                    <label htmlFor="register-email" className="designLabel">
                                                        OTP&nbsp;
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </label>
                                                    <input
                                                        type="number"
                                                        className="form-control designInputs"
                                                        placeholder="Enter OTP"
                                                        value={RegisterFields.OTP}
                                                        onChange={(event) => {
                                                            setRegisterFields({
                                                                ...RegisterFields,
                                                                OTP: event.target.value,
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <button
                                                    onClick={clear}
                                                    type="button"
                                                    className="btn btn-warning mt-2 mt-md-3 mt-lg-4"
                                                    style={{ float: 'right' }}
                                                >
                                                    Clear
                                                </button>
                                                <button
                                                    type="button"
                                                    onClick={forgetPasswordOTP}
                                                    className="btn btn-success mt-2 mt-md-3 mt-lg-4"
                                                    style={{ float: 'right', marginRight: 10 }}
                                                >
                                                    Verify
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2">&nbsp;</div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
