/* eslint-disable */
/* eslint-disable prefer-template */
/* eslint-disable max-len */
/* eslint linebreak-style: ["error", "windows"] */
// react
import React, { useState } from 'react';
import axios from 'axios';
import { MyApiUrl, ViewImg } from '../services/service';

import { Link, useLocation, useHistory } from 'react-router-dom';
// third-party
import { Helmet } from 'react-helmet-async';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// application
import PageHeader from '../shared/PageHeader';

// data stubs
import theme from '../../data/theme';

function ShopPageTrackOrder() {
    const history = useHistory();
    const breadcrumb = [
        { title: 'Home', url: '' },
        { title: 'Track Order', url: '' },
    ];
    const [OrderID, setOrderID] = useState();

    const orderChange = (event) => {
        setOrderID(event.target.value);
    };

    const trackOrder = () => {
        if (OrderID == '' || OrderID == null) {
            toast.warn('Please Enter Order ID/Number!', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
            });
        } else {
            console.log('Here ....');
            axios.get(MyApiUrl + "getOrdersByOrderNum/" + OrderID + "").then((response) => {
                if (response.data.length > 0) {
                    console.log(response.data)
                    history.push('/orderDetails', {
                        OrderDetails: response.data,
                    });
                } else {
                    toast.error('Invalid Order ID!', {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'dark',
                    });
                }
            });
        }
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Track Order — ${theme.name}`}</title>
            </Helmet>

            <PageHeader breadcrumb={breadcrumb} />

            <div className="block">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-5 col-lg-6 col-md-8">
                            <div className="card flex-grow-1 mb-0 mt-lg-4 mt-md-3 mt-2">
                                <div className="card-body">
                                    <div className="card-title text-center"><h1 className="pt-lg-0 pt-2">Track Order</h1></div>
                                    <p className="mb-4 pt-2" style={{ textAlign: 'center' }}>
                                        Track Your Order Status Using Order ID/Number
                                    </p>
                                    <form>
                                        <div className="form-group">
                                            <label htmlFor="track-order-id">
                                                Order ID&nbsp;
                                                <span style={{ color: 'red' }}>*</span>
                                            </label>
                                            <input
                                                id="track-order-id"
                                                type="text"
                                                className="form-control"
                                                placeholder="Order ID"
                                                value={OrderID}
                                                onChange={orderChange}
                                            />
                                        </div>
                                        <div className="pt-3">
                                            <button
                                                type="button"
                                                className="btn btn-primary btn-lg btn-block"
                                                onClick={trackOrder}
                                            >
                                                Track
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ShopPageTrackOrder;
