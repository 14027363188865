// react
/* eslint-disable */
/* eslint-disable prefer-template */
/* eslint-disable max-len */
// react
import React, { Component, useState } from 'react';
import axios from 'axios';
import { MyApiUrl, ViewImg } from '../services/service';

// third-party
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import Currency from '../shared/Currency';
// data stubs
// import dataOrders from '../../data/accountOrders';
import theme from '../../data/theme';

export default function AccountPageOrders() {
    const UserID = localStorage.getItem("UserID");
    const [CardData, setCardData] = useState([]);

    const getMonthName = (monthNumber) => {
        const date = new Date();
        date.setMonth(monthNumber - 1);

        return date.toLocaleString('en-US', { month: 'long' });
    }

    const SplitDate = (OrderDate) => {
        const MainDate = OrderDate.split("T");
        const SplitT = MainDate[0];
        const OrderDates = SplitT.split("-");
        const FinalDate = OrderDates[2] + " " + getMonthName(OrderDates[1]) + ", " + OrderDates[0];
        return FinalDate;
    }

    const GetAddress = () => {
        axios.get(MyApiUrl + "MyOrders/" + UserID + "").then((response) => {
            console.log(response);
            if (response.data.length != "0") {
                setCardData(response.data);
            }
            else {
                setCardData([]);
            }
        });
    };
    React.useEffect(() => {
        GetAddress();
    }, []);

    return (
        <div className="card">
            <Helmet>
                <title>{`My Orders — ${theme.name}`}</title>
            </Helmet>

            <div className="card-header">
                <h5>My Orders</h5>
            </div>
            <div className="card-divider" />
            <div className="card-table">
                <div className="table-responsive-sm">
                    <table>
                        <thead>
                            <tr>
                                <th>Order</th>
                                <th>Date</th>
                                <th>Status</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {CardData.map((order, key) => {
                                return (
                                    <tr key={order.ORDERS_PKID}>
                                        <td>
                                            <Link to={{
                                                pathname: "/account/orderDetails",
                                                state: { orderDetails: order },
                                            }}>
                                                {order.ORDERS_NUMBER}
                                            </Link>
                                        </td>
                                        <td>{SplitDate(order.ORDERS_DATE)}</td>
                                        <td>{order.ORDER_STATUS}
                                            {" "}
                                            {(order.ORDERS_STATUS > "2" || order.ORDERS_STATUS > 2) && (order.ORDERS_STATUS < "5" || order.ORDERS_STATUS < 5) ?
                                                <a href={ViewImg + order.ORDERS_DOC} download target="_blank">
                                                    <i className="fas fa-download" />
                                                </a>
                                                :
                                                ""
                                            }
                                        </td>
                                        <td><Currency value={order.ORDERS_GRAND_TOTAL} /> for {order.ORDERS_NO_OF_ITEMS} item(s)</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="card-divider" />
        </div>
    );
}

