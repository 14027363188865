/* eslint-disable indent */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-indent */
/* eslint-disable object-curly-newline */
/* eslint-disable quotes */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable max-len */
/* eslint-disable */
/* eslint-disable prefer-template */
/* eslint-disable max-len */
// react
import React, { useState } from "react";
import { toast } from "react-toastify";

// third-party
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import { MyApiUrl } from "../services/service";

// application
import AsyncAction from "./AsyncAction";
import InputNumber from "./InputNumber";
import ProductGallery from "./ProductGallery";
import Rating from "./Rating";
import { cartAddItem } from "../../store/cart";
import { compareAddItem } from "../../store/compare";
import { wishlistAddItem } from "../../store/wishlist";
import "../../scss/custom.css";

function Product(props) {
    const { product, layout, cartAddItem } = props;
    const [Quantity, setQuantity] = useState("");
    const [UnitAmount, setUnitAmount] = useState("");
    const [Unit, setUnit] = useState("");
    const [ProductDetails, setProductDetails] = useState({});
    const [UnitDetails, setUnitDetails] = useState([]);
    const [ProductUnits, setProductUnits] = useState([]);
    const [RequiredUnit, setRequiredUnit] = useState("");
    const [ShowUnitDetails, setShowUnitDetails] = useState(false);
    const [ShowPhotoGallery, setShowPhotoGallery] = useState(false);
    const [GeneralDiscount, setGeneralDiscount] = useState([]);

    const GetProductsList = async () => {
        await axios({
            method: "GET",
            url: `${MyApiUrl}Products/${product.pkid}`,
            headers: {
                "content-type": "application/json",
            },
        })
            .then((response) => {
                console.log(response.data);
                setProductDetails(response.data[0]);
                setShowPhotoGallery(true);
                axios({
                    method: "GET",
                    url: `${MyApiUrl}ProductUnit/${product.pkid}`,
                    headers: {
                        "content-type": "application/json",
                    },
                })
                    .then((res) => {
                        setProductUnits(res.data);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const GetGeneralDiscount = () => {
        axios.get(MyApiUrl + "GetGeneralDiscount").then((response) => {
            setGeneralDiscount(response.data);
        });
    };

    React.useEffect(() => {
        GetProductsList();
        GetGeneralDiscount();
    }, []);

    const GetUnits = (e) => {
        const ReQty = e.target.value;
        console.log(ReQty);
        const spltedval = ReQty.split("-");
        setUnit(spltedval[2]);
        setUnitAmount(spltedval[1]);
        setRequiredUnit(spltedval[0]);
        axios({
            method: "GET",
            url: `${MyApiUrl}ProductUnitDetails/${spltedval[0]}`,
            headers: {
                "content-type": "application/json",
            },
        })
            .then((res) => {
                if (res.data.length === 0) {
                    setShowUnitDetails(false);
                } else {
                    setUnitDetails(res.data);
                    setShowUnitDetails(true);
                    console.log(RequiredUnit);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const ManageQuantity = (e) => {
        if (ShowUnitDetails === false) {
            toast.error(`Please select the product unit.`, { theme: "colored" });
        } else {
            setQuantity(e);
        }
    };

    const UnitDetailsView = () => (
        <form className="product__options">
            <div className="form-group product__option">
                <label htmlFor="product-quantity" className="product__option-label">
                    Unit Details
                </label>
                <div>
                    <table className="UnitTable" style={{ width: "100%" }}>
                        {UnitDetails.map((obj) => (
                            <React.Fragment>
                                <tr>
                                    <th>Actual Price</th>
                                    <td>
                                        <p style={{ textDecoration: "line-through", padding: "0px", margin: "0px" }}>
                                            ₹{obj.PRODUCT_UNIT_ACTUAL_PRICE}
                                        </p>
                                    </td>
                                    <th>Discount</th>
                                    <td>{obj.PRODUCT_UNIT_GENERAL_DISCOUNT}%</td>
                                </tr>
                                <tr>
                                    <th>Selling Price</th>
                                    <td>₹{obj.PRODUCT_UNIT_SELLING_PRICE}</td>
                                    <th>Weight</th>
                                    <td>{obj.PRODUCT_UNIT_WEIGHT}</td>
                                </tr>
                            </React.Fragment>
                        ))}
                    </table>
                </div>
            </div>
        </form>
    );

    return (
        <div className={`product product--layout--${layout}`}>
            <div className="product__content">
                {ShowPhotoGallery == true ? <ProductGallery layout={layout} images={ProductDetails.images} /> : ""}

                <div className="product__info">
                    <h1 className="product__name">{ProductDetails.name}</h1>
                    <div className="product__rating">
                        <div className="product-card__rating">
                            <Rating value={ProductDetails.rating} />
                            <div className=" product-card__rating-legend">{`${ProductDetails.reviews} Reviews`}</div>
                        </div>
                    </div>
                    <div className="product__description">
                        <div dangerouslySetInnerHTML={{ __html: ProductDetails.description }} />
                    </div>
                    <ul className="product__meta">
                        <li className="product__meta-availability">
                            Availability : <span className="text-success">In Stock</span>
                        </li>
                        <li>
                            Brand : <Link to="/">AA Probics</Link>
                        </li>
                        <li>SKU : 83690/32</li>
                    </ul>
                </div>

                <div className="product__sidebar" style={{ borderTop: "1px solid #ebebeb", marginTop: "2%" }}>
                    <form className="product__options">
                        <div className="form-group product__option" style={{ marginTop: "2%" }}>
                            <label htmlFor="product-quantity" className="product__option-label">
                                Required Units
                            </label>
                            <div className="product__actions">
                                <div className="main-container">
                                    <div className="radio-buttons">
                                        {ProductUnits.map((obj) => (
                                            <React.Fragment>
                                                <label className="custom-radio">
                                                    <input
                                                        type="radio"
                                                        name="radio"
                                                        onClick={GetUnits}
                                                        value={`${obj.PRODUCT_UNIT_PKID}-${obj.PRODUCT_UNIT_SELLING_PRICE}-${obj.PRODUCT_UNIT_QTY}`}
                                                    />
                                                    <span className="radio-btn" style={{ marginLeft: "0px" }}>
                                                        <div className="hobbies-icon">
                                                            <h3 className="">
                                                                {obj.PRODUCT_UNIT_QTY} - ₹
                                                                {obj.PRODUCT_UNIT_SELLING_PRICE}
                                                            </h3>
                                                        </div>
                                                    </span>
                                                </label>
                                            </React.Fragment>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    {ShowUnitDetails && <UnitDetailsView />}
                    <form className="product__options" style={{ marginTop: "3%" }}>
                        <div className="form-group product__option">
                            <label htmlFor="product-quantity" className="product__option-label">
                                Required Quantity
                            </label>
                            <div className="product__actions">
                                <div className="product__actions-item">
                                    <InputNumber
                                        id="product-quantity"
                                        aria-label="Quantity"
                                        className="product__quantity"
                                        size="lg"
                                        min={1}
                                        value={Quantity}
                                        onChange={ManageQuantity}
                                    />
                                </div>
                                <div className="product__actions-item product__actions-item--addtocart">
                                    <AsyncAction
                                        action={() =>
                                            cartAddItem(product, [], Quantity, UnitAmount, Unit, RequiredUnit, GeneralDiscount)
                                        }
                                        render={({ run, loading }) => (
                                            <button
                                                type="button"
                                                onClick={run}
                                                disabled={!Quantity}
                                                className={classNames("btn btn-primary btn-lg", {
                                                    "btn-loading": loading,
                                                })}
                                            >
                                                Add to cart
                                            </button>
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

Product.propTypes = {
    /** product object */
    product: PropTypes.object.isRequired,
    /** one of ['standard', 'sidebar', 'columnar', 'quickview'] (default: 'standard') */
    layout: PropTypes.oneOf(["standard", "sidebar", "columnar", "quickview"]),
};

Product.defaultProps = {
    layout: "standard",
};

const mapDispatchToProps = {
    cartAddItem,
    wishlistAddItem,
    compareAddItem,
};

export default connect(() => ({}), mapDispatchToProps)(Product);
