// react
/* eslint-disable */
/* eslint-disable prefer-template */
/* eslint-disable max-len */
// react
import React from 'react';

// application
import FooterContacts from './FooterContacts';
import FooterLinks from './FooterLinks';
import FooterNewsletter from './FooterNewsletter';
import ToTop from './ToTop';

// data stubs

export default function Footer() {
    const informationLinks = [
        { title: 'About Us', url: '/site/about-us' },
        { title: 'Contact Us', url: '/site/contact-us' },
        { title: 'Site Map', url: '/site/contact-us' },
        { title: 'Career', url: '/site/SitePageCareer' },
    ];
    const UserLoggedIN = localStorage.getItem('UserLoggedIN');
    let accountLinks;
    if (UserLoggedIN == null || UserLoggedIN == "null") {
        accountLinks = [
            { title: 'Our Products', url: '/Products/OurProducts' },
            { title: 'Sign Up', url: '/register' },
            { title: 'Forget Password', url: '/ForgetPassword' },
        ];
    }
    else {
        accountLinks = [
            { title: 'Our Products', url: '/Products/OurProducts' },
            { title: 'My Account', url: '/account/dashboard' },
            { title: 'Orders', url: '/account/orders' },
        ];
    }


    return (
        <div className="site-footer">
            <div className="container">
                <div className="site-footer__widgets">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-4">
                            <FooterContacts />
                        </div>
                        <div className="col-6 col-md-3 col-lg-2">
                            <FooterLinks title="Information" items={informationLinks} />
                        </div>
                        <div className="col-6 col-md-3 col-lg-2">
                            <FooterLinks title="My Account" items={accountLinks} />
                        </div>
                        <div className="col-12 col-md-12 col-lg-4">
                            <FooterNewsletter />
                        </div>
                    </div>
                </div>

                <div className="site-footer__bottom">
                    <div className="site-footer__copyright">
                        Powered by
                        {' '}
                        <a href="https://www.vssitcompany.com" target="_blank" rel="noreferrer">Veriteam Software Solution</a>
                        {' '}
                    </div>
                    <div className="site-footer__payments">
                        <img src="images/payments.png" alt="" />
                    </div>
                </div>
            </div>
            <ToTop />
        </div>
    );
}
