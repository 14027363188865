// react
/* eslint-disable */
/* eslint-disable prefer-template */
/* eslint-disable max-len */
// react
import React, { useState } from "react";
import axios from "axios";
import { MyApiUrl } from "../services/service";

// third-party
import { Helmet } from "react-helmet-async";
// import { Link } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// application
import PageHeader from "../shared/PageHeader";
// import { Check9x7Svg } from '../../svg';

// data stubs
import theme from "../../data/theme";
import "../../scss/custom.css";

export default function AccountPageRegister() {
    const breadcrumb = [
        { title: "Home", url: "" },
        { title: "Registration", url: "" },
    ];
    const [Countrydata, setCountryData] = useState([]);
    const [StateData, setStateData] = useState([]);
    const [CityData, setCityData] = useState([]);
    const [AreaData, setAreaData] = useState([]);
    const [CityId, setCityId] = useState("-1");
    const [AreaId, setAreaId] = useState("-1");
    const [RegisterFields, setRegisterFields] = useState({
        FName: "",
        LName: "",
        Email: "",
        Phno: "",
        Password: "",
        RepeatPassword: "",
        HouseNo: "",
        StreetAddress: "",
        Country: "",
        State: "",
        SelectedCity: "",
        SelectedArea: "",
        AddressType: "",
        Pincode: "",
    });

    const AddressTypeChange = (event) => {
        setRegisterFields({
            ...RegisterFields,
            AddressType: event.target.value,
        });
    };

    const CountryChange = (event) => {
        setRegisterFields({
            ...RegisterFields,
            Country: event.target.value,
        });
        GetState(event.target.value);
    };

    const StateChange = (event) => {
        setRegisterFields({
            ...RegisterFields,
            State: event.target.value,
        });
        GetCity(event.target.value);
    };

    const CityChange = (event) => {
        setCityId(event.target.value);
        GetArea(event.target.value);
    };

    const AreaChange = (event) => {
        setAreaId(event.target.value);
        if (event.target.value == "-1") {
            setRegisterFields({
                ...RegisterFields,
                Pincode: "",
            });
        } else {
            ChangePincode(event.target.value);
        }
    };

    const GetCountry = React.useCallback(() => {
        axios.get(MyApiUrl + "Country").then((response) => {
            const CountryOption = response.data.map((item) => (
                <option value={item.COUNTRY_PKID}>{item.COUNTRY_NAME}</option>
            ));
            setCountryData(CountryOption);
        });
    }, []);

    const GetState = React.useCallback((Countrypkid) => {
        axios.get(MyApiUrl + "StateByCountry/" + Countrypkid + "").then((response) => {
            const StateOption = response.data.map((item) => <option value={item.STATE_PKID}>{item.STATE_NAME}</option>);
            setStateData(StateOption);
        });
    }, []);

    const GetCity = React.useCallback((Stateid) => {
        axios.get(MyApiUrl + "CityByState/" + Stateid + "").then((response) => {
            const CityOption = response.data.map((item) => <option value={item.CITY_PKID}>{item.CITY_NAME}</option>);
            setCityData(CityOption);
        });
    }, []);

    const GetArea = React.useCallback((CityId) => {
        axios.get(MyApiUrl + "AreaByCityID/" + CityId + "").then((response) => {
            const AreaOption = response.data.map((item) => <option value={item.AREA_PKID}>{item.AREA_NAME}</option>);
            setAreaData(AreaOption);
        });
    }, []);

    const ChangePincode = async (AreaID) => {
        await axios.get(MyApiUrl + "PincodeByCity/" + AreaID + "").then((response) => {
            setRegisterFields({
                ...RegisterFields,
                Pincode: response.data[0].AREA_PINCODE,
            });
        });
    };

    const register = () => {
        const ValidEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
        if (RegisterFields.FName === "" || RegisterFields.FName === null) {
            toast.warn("Please Enter First Name!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        } else if (RegisterFields.LName === "" || RegisterFields.LName === null) {
            toast.warn("Please Enter Last Name!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        } else if (RegisterFields.Email === "" || RegisterFields.Email === null) {
            toast.warn("Please Enter Email!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        } else if (ValidEmail.test(RegisterFields.Email) === false) {
            toast.warn("Please Enter Valid Email!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }
        // else if (RegisterFields.Phno === '' || RegisterFields.Phno === null) {
        //     toast.warn('Please Enter Contact Number!', {
        //         position: 'top-right',
        //         autoClose: 5000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //         theme: 'dark',
        //     });
        // }
        // else if (RegisterFields.Phno.length < 10 || RegisterFields.Phno.length > 10) {
        //     toast.warn('Contact Number Should Be 10 Digits!', {
        //         position: 'top-right',
        //         autoClose: 5000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //         theme: 'dark',
        //     });
        // }
        else if (RegisterFields.Password === "" || RegisterFields.Password === null) {
            toast.warn("Please Enter Password!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        } else if (RegisterFields.RepeatPassword === "" || RegisterFields.RepeatPassword === null) {
            toast.warn("Please Enter Password Again!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        } else if (RegisterFields.Password != RegisterFields.RepeatPassword) {
            toast.warn("Password Mismatch..Please Enter Same Password!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        } else {
            const obj = {
                REGISTERED_USERS_NAME: RegisterFields.FName + " " + RegisterFields.LName,
                REGISTERED_USERS_EMAIL: RegisterFields.Email,
                REGISTERED_USERS_PHONE: RegisterFields.Phno,
                REGISTERED_USERS_PASSWORD: RegisterFields.Password,
                UserAddress: [
                    {
                        HouseNo: RegisterFields.HouseNo,
                        StreetAddress: RegisterFields.StreetAddress,
                        Country: RegisterFields.Country,
                        State: RegisterFields.State,
                        City: CityId,
                        Area: AreaId,
                        Pincode: RegisterFields.Pincode,
                        AddressType: RegisterFields.AddressType,
                    },
                ],
            };
            console.log(obj);
            axios.post(MyApiUrl + "RegisteredUsers", obj).then((response) => {
                if (response.data == "0") {
                    toast.error("Email Already Exist. Please Login To Your Account!", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                } else if (response.data == "1") {
                    toast.success("Registered Successfully. Please Login!", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                    clear();
                    window.location.href = "/";
                } else if (response.data == "2") {
                    toast.error("Failed To Register. Please Contact Customer Support!", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                }
            });
        }
    };

    const clear = () => {
        setRegisterFields({
            ...RegisterFields,
            FName: "",
            LName: "",
            Email: "",
            Phno: "",
            Password: "",
            RepeatPassword: "",
            HouseNo: "",
            StreetAddress: "",
            Country: "",
            State: "",
            SelectedCity: "",
            SelectedArea: "",
            AddressType: "",
            Pincode: "",
        });
    };

    React.useEffect(() => {
        GetCountry();
    }, []);

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Login — ${theme.name}`}</title>
            </Helmet>

            <PageHeader header="Registration" breadcrumb={breadcrumb} />

            <div className="block">
                <div className="container">
                    <div className="row">
                        <div className="col-md-1">&nbsp;</div>
                        <div className="col-md-10 d-flex mt-4 mt-md-0">
                            <div className="card flex-grow-1 mb-0">
                                <div className="card-body">
                                    <h3 className="card-title">Register</h3>
                                    <form>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="register-email" className="designLabel">
                                                        First Name&nbsp;
                                                        <span style={{ color: "red" }}>*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control designInputs"
                                                        placeholder="Enter Your First Name"
                                                        value={RegisterFields.FName}
                                                        onChange={(event) => {
                                                            const re = /^[A-Z a-z]+$/;
                                                            if (
                                                                event.target.value === "" ||
                                                                re.test(event.target.value)
                                                            ) {
                                                                setRegisterFields({
                                                                    ...RegisterFields,
                                                                    FName: event.target.value,
                                                                });
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="register-email" className="designLabel">
                                                        Last Name&nbsp;
                                                        <span style={{ color: "red" }}>*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control designInputs"
                                                        placeholder="Enter Your Last Name"
                                                        value={RegisterFields.LName}
                                                        onChange={(event) => {
                                                            const re = /^[A-Z a-z]+$/;
                                                            if (
                                                                event.target.value === "" ||
                                                                re.test(event.target.value)
                                                            ) {
                                                                setRegisterFields({
                                                                    ...RegisterFields,
                                                                    LName: event.target.value,
                                                                });
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="register-email" className="designLabel">
                                                        Email&nbsp;
                                                        <span style={{ color: "red" }}>*</span>
                                                    </label>
                                                    <input
                                                        type="email"
                                                        className="form-control designInputs"
                                                        placeholder="Enter Your Email"
                                                        value={RegisterFields.Email}
                                                        onChange={(event) => {
                                                            setRegisterFields({
                                                                ...RegisterFields,
                                                                Email: event.target.value,
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="register-email" className="designLabel">
                                                        Contact Number
                                                    </label>
                                                    <input
                                                        type="number"
                                                        className="form-control designInputs"
                                                        placeholder="Enter Your Contact Number"
                                                        value={RegisterFields.Phno}
                                                        onChange={(event) => {
                                                            const re = /^[0-9]+$/;
                                                            if (
                                                                event.target.value === "" ||
                                                                re.test(event.target.value)
                                                            ) {
                                                                setRegisterFields({
                                                                    ...RegisterFields,
                                                                    Phno: event.target.value,
                                                                });
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="register-password" className="designLabel">
                                                        Password&nbsp;
                                                        <span style={{ color: "red" }}>*</span>
                                                    </label>
                                                    <input
                                                        type="password"
                                                        className="form-control designInputs"
                                                        placeholder="Enter Password"
                                                        value={RegisterFields.Password}
                                                        onChange={(event) => {
                                                            setRegisterFields({
                                                                ...RegisterFields,
                                                                Password: event.target.value,
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="register-confirm" className="designLabel">
                                                        Repeat Password&nbsp;
                                                        <span style={{ color: "red" }}>*</span>
                                                    </label>
                                                    <input
                                                        type="password"
                                                        className="form-control designInputs"
                                                        placeholder="Enter Password Again"
                                                        value={RegisterFields.RepeatPassword}
                                                        onChange={(event) => {
                                                            setRegisterFields({
                                                                ...RegisterFields,
                                                                RepeatPassword: event.target.value,
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="register-confirm" className="designLabel">
                                                        House No.,Building Name
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control designInputs"
                                                        placeholder="Enter House No.,Building Name"
                                                        value={RegisterFields.HouseNo}
                                                        onChange={(event) => {
                                                            setRegisterFields({
                                                                ...RegisterFields,
                                                                HouseNo: event.target.value,
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="register-confirm" className="designLabel">
                                                        Street Address
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control designInputs"
                                                        placeholder="Enter Street Address"
                                                        value={RegisterFields.StreetAddress}
                                                        onChange={(event) => {
                                                            setRegisterFields({
                                                                ...RegisterFields,
                                                                StreetAddress: event.target.value,
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="register-confirm" className="designLabel">
                                                        Country
                                                    </label>
                                                    <select
                                                        id="checkout-country"
                                                        value={RegisterFields.Country}
                                                        className="form-control form-control-select2 designInputs"
                                                        onChange={CountryChange}
                                                    >
                                                        <option value="-1">Select Country</option>
                                                        {Countrydata}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="register-confirm" className="designLabel">
                                                        State
                                                    </label>
                                                    <select
                                                        id="checkout-country"
                                                        value={RegisterFields.State}
                                                        className="form-control form-control-select2 designInputs"
                                                        onChange={StateChange}
                                                    >
                                                        <option value="-1">Select State</option>
                                                        {StateData}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="register-confirm" className="designLabel">
                                                        City
                                                    </label>
                                                    <select
                                                        id="checkout-country"
                                                        value={CityId}
                                                        onChange={CityChange}
                                                        className="form-control form-control-select2 designInputs"
                                                    >
                                                        <option value="-1">Select City</option>
                                                        {CityData}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="register-confirm" className="designLabel">
                                                        Area
                                                    </label>
                                                    <select
                                                        id="checkout-country"
                                                        value={AreaId}
                                                        onChange={AreaChange}
                                                        className="form-control form-control-select2 designInputs"
                                                    >
                                                        <option value="-1">Select Area</option>
                                                        {AreaData}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="register-confirm" className="designLabel">
                                                        Pincode
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control designInputs"
                                                        placeholder="Pincode"
                                                        readOnly
                                                        value={RegisterFields.Pincode}
                                                        onChange={(event) => {
                                                            setRegisterFields({
                                                                ...RegisterFields,
                                                                Pincode: event.target.value,
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <div className="form-row">
                                                        <div className="form-group col-md-12">
                                                            <label htmlFor="checkout-country" className="designLabel">
                                                                Address Type
                                                            </label>
                                                            <br />
                                                            <input
                                                                type="radio"
                                                                name="AddressType"
                                                                value="Home"
                                                                id="exp2"
                                                                onChange={AddressTypeChange}
                                                            />
                                                            <label
                                                                htmlFor="exp2"
                                                                style={{ marginLeft: 10 }}
                                                                className="designLabel"
                                                            >
                                                                {" "}
                                                                Home
                                                            </label>
                                                            <input
                                                                style={{ marginLeft: 20 }}
                                                                type="radio"
                                                                name="AddressType"
                                                                id="exp1"
                                                                value="Work"
                                                                onChange={AddressTypeChange}
                                                            />
                                                            <label
                                                                htmlFor="exp1"
                                                                style={{ marginLeft: 10 }}
                                                                className="designLabel"
                                                            >
                                                                {" "}
                                                                Work
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <button
                                            onClick={clear}
                                            type="button"
                                            className="btn btn-warning mt-2 mt-md-3 mt-lg-4"
                                            style={{ float: "right" }}
                                        >
                                            Clear
                                        </button>
                                        <button
                                            type="button"
                                            onClick={register}
                                            className="btn btn-success mt-2 mt-md-3 mt-lg-4"
                                            style={{ float: "right", marginRight: 10 }}
                                        >
                                            Register
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-1">&nbsp;</div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
