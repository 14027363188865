/* eslint-disable quotes */
/* eslint-disable */
/* eslint-disable prefer-template */
/* eslint-disable max-len */
// react
import React, { useState } from "react";

// third-party
import classNames from "classnames";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import { MyApiUrl, ViewImg } from "../services/service";

// application
import AsyncAction from "../shared/AsyncAction";
import Currency from "../shared/Currency";
import Indicator from "./Indicator";
import { Cart20Svg, Cross10Svg, Info } from "../../svg";
import { cartRemoveItem } from "../../store/cart";
import { url } from "../../services/utils";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function IndicatorCart(props) {
    const { cart, cartRemoveItem } = props;
    console.log("props");
    console.log(props);
    let dropdown;
    let totals;
    let totalsShipping;

    const [GeneralDiscount, setGeneralDiscount] = useState([]);

    const GetGeneralDiscount = () => {
        axios.get(MyApiUrl + "GetGeneralDiscount").then((response) => {
            setGeneralDiscount(response.data);
        });
    };

    React.useEffect(() => {
        GetGeneralDiscount();
    }, []);

    if (cart.extraLines.length > 0) {
        totalsShipping = cart.extraLines.map((extraLine, index) => (
            <tr key={index}>
                <th>
                    {extraLine.title}
                    <div style={{ float: "right" }} className="tooltipp">
                        {/* <Link to="/shop/pricecal"></Link> */}
                        <Info />
                        <span className="tooltiptextt">
                            <table>
                                <thead>
                                    <tr>
                                        <th style={{ textAlign: "center" }}>Price Range</th>
                                        <th style={{ textAlign: "center" }}>Discount (%)</th>
                                    </tr>
                                </thead>
                                <tbody className="cart-table__body">
                                    <tr>
                                        <td style={{ textAlign: "center" }}>0 to 1000</td>
                                        <td style={{ textAlign: "center" }}>8%</td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "center" }}>1.000 to 3.000</td>
                                        <td style={{ textAlign: "center" }}>7%</td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "center" }}>3.000 to 5.000</td>
                                        <td style={{ textAlign: "center" }}>5%</td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "center" }}>5.000 to 10.000</td>
                                        <td style={{ textAlign: "center" }}>8%</td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "center" }}>More Than 10.000</td>
                                        <td style={{ textAlign: "center" }}>Free</td>
                                    </tr>
                                </tbody>
                            </table>
                        </span>
                    </div>
                </th>
                <td>
                    <Currency value={extraLine.price} />
                </td>
            </tr>
        ));

        totals = (
            <React.Fragment>
                <tr>
                    <th>Subtotal</th>
                    <td>
                        <Currency value={cart.subtotal} />
                    </td>
                </tr>
                {/* {extraLines} */}
            </React.Fragment>
        );
    }

    const items = cart.items.map((item) => {
        let options;
        let image;

        if (item.options) {
            options = (
                <ul className="dropcart__product-options">
                    {item.options.map((option, index) => (
                        <li key={index}>{`${option.optionTitle}: ${option.valueTitle}`}</li>
                    ))}
                </ul>
            );
        }

        if (item.product.images.length) {
            image = (
                <div className="product-image dropcart__product-image">
                    <Link to={url.product(item.product)} className="product-image__body">
                        <img className="product-image__img" src={ViewImg + item.product.images[0]} alt="" />
                    </Link>
                </div>
            );
        }

        const removeButton = (
            <AsyncAction
                action={() => cartRemoveItem(item.id, GeneralDiscount)}
                render={({ run, loading }) => {
                    const classes = classNames("dropcart__product-remove btn btn-light btn-sm btn-svg-icon", {
                        "btn-loading": loading,
                    });

                    return (
                        <button type="button" onClick={run} className={classes}>
                            <Cross10Svg />
                        </button>
                    );
                }}
            />
        );

        return (
            <div key={item.id} className="dropcart__product">
                {image}
                <div className="dropcart__product-info">
                    <div className="dropcart__product-name">
                        <Link to={url.product(item.product)}>{item.product.name}</Link>
                    </div>
                    {options}
                    <div className="dropcart__product-meta">
                        <span className="dropcart__product-quantity">{item.quantity}</span>
                        {" × "}
                        <span className="dropcart__product-price">
                            <Currency value={item.price} />
                        </span>
                    </div>
                </div>
                {removeButton}
            </div>
        );
    });

    if (cart.quantity) {
        dropdown = (
            <div className="dropcart">
                <div className="dropcart__products-list">{items}</div>

                <div className="dropcart__totals">
                    <table>
                        <tbody>
                            {totals}
                            <tr>
                                <th>
                                    General Discount
                                    <div style={{ float: "right" }} className="tooltipp_g">
                                        <Link to="/shop/pricecal">
                                            <Info />
                                        </Link>

                                        <span className="tooltiptextt_g">View General Discount Details</span>
                                    </div>
                                </th>
                                <td>
                                    <span style={{ fontSize: "15px", fontWeight: "900" }}>-</span>&nbsp;&nbsp;
                                    <Currency value={cart.GeneralDiscount} />
                                </td>
                            </tr>

                            <tr>
                                <th>Coupon Discount</th>
                                <td>
                                    <span style={{ fontSize: "15px", fontWeight: "900" }}>-</span>&nbsp;&nbsp;
                                    <Currency value={cart.couponPrice} />
                                </td>
                            </tr>
                            <tr>
                                <th>Total</th>
                                <td>
                                    <Currency value={cart.total} />
                                </td>
                            </tr>
                            {totalsShipping}
                            <tr>
                                <th>Grand Total</th>
                                <td>
                                    <Currency value={cart.grandTotal} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="dropcart__buttons">
                    <Link className="btn btn-secondary" to="/shop/cart">
                        View Cart
                    </Link>
                </div>
            </div>
        );
    } else {
        dropdown = (
            <div className="dropcart">
                <div className="dropcart__empty">Your shopping cart is empty!</div>
            </div>
        );
    }

    return <Indicator url="/shop/cart" dropdown={dropdown} value={cart.quantity} icon={<Cart20Svg />} />;
}

const mapStateToProps = (state) => ({
    cart: state.cart,
});

const mapDispatchToProps = {
    cartRemoveItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(IndicatorCart);
