export default {
    name: 'AA Probics',
    fullName: 'AA Probics specializes in developing nutraceutical products of the best quality',
    url: 'https://aaprobics.com/',
    author: {
        name: 'VSS',
    },
    contacts: {
        address: 'AA probics Survey no 30/2 Siddalingpura Banglore- mysore highway Mysore -570003 Karnataka',
        email: 'info@aaprobics.com',
        phone: '+91 9041835625',
    },
};
