/* eslint-disable prefer-template */
/* eslint-disable max-len */
// react
import React, { useState } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import axios from 'axios';
import { MyApiUrl } from '../services/service';
// import { Link } from 'react-router-dom';

// application
// import StroykaSlick from '../shared/StroykaSlick';

// data stubs
import theme from '../../data/theme';

const mystyle = {
    card: {
        backgroundColor: '#e4e1ff',
        borderRadius: 10,
        padding: 10,
        marginLeft: 50,
        float: 'left',
    },
    card1: {
        backgroundColor: '#e1f1ff',
        borderRadius: 10,
        padding: 10,
        marginLeft: 50,
        marginRight: 50,
        float: 'right',
    },
};

function SitePageAboutUs() {
    const [AboutUs, setAboutUs] = useState('');
    const [Mission, setMission] = useState('');
    const [Vission, setVission] = useState('');
    const [Philosophy, setPhilosophy] = useState('');
    const GetAboutUs = () => {
        axios({
            method: 'GET',
            url: MyApiUrl + 'About',
            headers: {
                'content-type': 'application/json',
            },
        })
            .then((response) => {
                console.log(response.data);
                setAboutUs(response.data[0].ABOUT_US);
                setMission(response.data[0].ABOUT_MISSION);
                setVission(response.data[0].ABOUT_VISION);
                setPhilosophy(response.data[0].ABOUT_PHILOSOPHY);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    React.useEffect(() => {
        GetAboutUs();
    }, []);
    return (
        <div className="block about-us">
            <Helmet>
                <title>{`About Us — ${theme.name}`}</title>
            </Helmet>

            <div
                className="about-us__image"
                style={{
                    background:
                        '#e7e7e7',
                }}
            />
            <div className="container">
                <div className="row justify-content-center">
                    <div
                        className="col-12 col-xl-10"
                        style={{
                            borderLeft: '1px solid rgb(210 210 210)',
                            paddingLeft: '0%',
                            borderRight: '1px solid rgb(210 210 210)',
                            paddingRight: '0%',
                        }}
                    >
                        <div className="about-us__body">
                            <h1 className="about-us__title">About Us</h1>
                            <h3 className="about-us__team-title" style={{ textAlign: 'left', color: '#0484cc' }}>
                                AA PROBICS
                            </h3>
                            <div className="about-us__text typography">
                                <div dangerouslySetInnerHTML={{ __html: AboutUs }} style={{ textAlign: 'justify' }} />
                                {/* <p style={{ textAlign: 'justify', color: 'black' }}>
                                    AA probics , situated in Mysore , Karnataka, India, is a pioneer in the
                                    nutraceutical industry since 2019. As one of the nutraceutical manufacturers and
                                    supplier, we have expertise and capabilities in providing high-quality nutraceutical
                                    solutions aimed at improving health and wellness through microbiota management.
                                </p>
                                <p style={{ textAlign: 'justify', color: 'black' }}>
                                    AA Probics collaborates with business partners, research institutes, and hospitals
                                    across the globe, to provide documented, safe, and clinically proven formulations
                                </p> */}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-5" style={mystyle.card}>
                                <h4 className="about-us__team-title" style={{ textAlign: 'left', color: '#0484cc' }}>
                                    VISION
                                </h4>
                                <div dangerouslySetInnerHTML={{ __html: Vission }} style={{ textAlign: 'justify' }} />
                                {/* <p style={{ textAlign: 'justify', color: 'black' }}>
                                    Align research to One Health Concept ensuring sustainability through environmentally
                                    friendly practices and reducing its carbon footprint.
                                </p> */}
                            </div>
                            <div className="col-md-5" style={mystyle.card1}>
                                <h4 className="about-us__team-title" style={{ textAlign: 'left', color: '#0484cc' }}>
                                    MISSION
                                </h4>
                                <div dangerouslySetInnerHTML={{ __html: Mission }} style={{ textAlign: 'justify' }} />
                                {/* <p style={{ textAlign: 'justify', color: 'black' }}>
                                    One Health focus commits AA Probics to relentless pursuit of natural sustainable
                                    solutions to overcome health challenges by humans, animals and environment through
                                    profound research and best manufacturing practises.
                                </p> */}
                            </div>
                        </div>
                        <div
                            className="about-us__body"
                            style={{
                                marginTop: '0px',
                                padding: '30px',
                                minHeight: '280px',
                                borderBottom: '1px solid rgb(210 210 210)',
                            }}
                        >
                            <div className="about-us__team">
                                <h2 className="about-us__team-title" style={{ textAlign: 'left', color: '#0484cc' }}>
                                    OUR PHILOSOPHY
                                </h2>
                                {/* <div className="about-us__team-subtitle text-muted">
                                    Want to work in our friendly team?
                                    <br />
                                    and we will consider your candidacy.
                                </div> */}
                                <div className="about-us__text typography">
                                    <div dangerouslySetInnerHTML={{ __html: Philosophy }} style={{ textAlign: 'justify' }} />
                                    {/* <p style={{ textAlign: 'justify', color: 'black' }}>
                                        AA Probics specializes in developing nutraceutical products of the best quality,
                                        backed by scientific research to enhance health and wellness in a natural way.
                                    </p>
                                    <p style={{ textAlign: 'justify', color: 'black' }}>
                                        As one of the nutraceutical manufacturers and formulator, we provide end-to-end
                                        solutions, from sourcing to finished formulations in various dosage forms.
                                    </p>
                                    <p style={{ textAlign: 'justify', color: 'black' }}>
                                        Our ideation, dedicated research and expertise in nutraceuticals have brought us
                                        to where we are today, with a diversified portfolio and its applications in the
                                        food, supplement, nutraceutical and animal nutrition industries. Without
                                        compromising on quality, AA Probics offers formulations with guaranteed
                                        stability and viability till the end of their shelf life.
                                    </p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SitePageAboutUs;
