// react
/* eslint-disable */
/* eslint-disable prefer-template */
/* eslint-disable max-len */
// react
import React, { useState, useMemo } from 'react';
import axios from 'axios';
import { MyApiUrl, ViewImg } from '../services/service';

// third-party
import { Helmet } from "react-helmet-async";

// application
import shopApi from "../../api/shop";
import { useProductTabs } from "../../services/hooks";

// blocks
import BlockBanner from "../blocks/BlockBanner";
// import BlockBrands from "../blocks/BlockBrands";
// import BlockCategories from "../blocks/BlockCategories";
import BlockPosts from "../blocks/BlockPosts";
// import BlockProductColumns from "../blocks/BlockProductColumns";
// import BlockProducts from "../blocks/BlockProducts";
import BlockProductsCarousel from "../blocks/BlockProductsCarousel";
import BlockSlideShow from "../blocks/BlockSlideShow";

// data stubs
// import categories from "../../data/shopBlockCategories";
import posts from "../../data/blogPosts";
import theme from "../../data/theme";

function HomePageOne() {
    /**
     * Featured products.
     */
    const [CardData, setCardData] = useState();
    const [ProductData, setProductData] = useState();
    const [CardView, setCardView] = useState(false);
    const [ProductView, setProductView] = useState(false);
    const GetAddress = async () => {
        await axios.get(MyApiUrl + "Award").then((response) => {
            if (response.data.length > 0) {
                setCardData(response.data);
                setCardView(true);
            }
            else {
                setCardData([]);
            }
        });
        await axios.get(MyApiUrl + "Products").then((response) => {
            if (response.data.length > 0) {
                setProductData(response.data);
                setProductView(true);
            }
            else {
                setProductData([]);
            }
        });
    };

    React.useEffect(() => {
        GetAddress();
    }, []);

    const featuredProducts = useProductTabs(
        useMemo(
            () => [
                { id: 1, name: "All", categorySlug: undefined },
                { id: 2, name: "Human Nutrition", categorySlug: "Human Nutrition" },
                { id: 3, name: "Animal Nutrition", categorySlug: "Animal Nutrition" },
            ],
            [],
        ),
        (tab) => shopApi.getPopularProducts({ limit: 8, category: tab.categorySlug }),
    );

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Home Page One — ${theme.name}`}</title>
            </Helmet>

            {useMemo(() => <BlockSlideShow />, [])}

            {useMemo(
                () => (
                    <BlockBanner />
                ),
                [],
            )}

            {ProductView == true ?
                <BlockProductsCarousel
                    title="Featured Products"
                    layout="grid-4"
                    products={ProductData}
                    // loading={featuredProducts.isLoading}
                    // groups={featuredProducts.tabs}
                    // onGroupClick={featuredProducts.handleTabChange}
                />
                : ""}

            {/* {useMemo(
                () => (
                    <BlockProductsCarousel
                        title="New Arrivals"
                        layout="horizontal"
                        rows={2}
                        products={latestProducts.data}
                        loading={latestProducts.isLoading}
                        groups={latestProducts.tabs}
                        onGroupClick={latestProducts.handleTabChange}
                    />
                ),
                [latestProducts],
            )} */}

            {CardView == true ?
                <BlockPosts title="Awards & Certificates" layout="list-sm" posts={CardData} />
                : ""}
        </React.Fragment>
    );
}

export default HomePageOne;
