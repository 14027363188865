// react
import React from 'react';

// data stubs
import theme from '../../data/theme';

export default function FooterContacts() {
    return (
        <div className="site-footer__widget footer-contacts">
            <h5 className="footer-contacts__title">Contact Us</h5>

            {/* <div className="footer-contacts__text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer in feugiat
                lorem. Pellentque ac placerat tellus.
            </div> */}

            <ul className="footer-contacts__contacts">
                <li>
                    <div className="row">
                        <div className="col-md-1">
                            <i className="footer-contacts__icon fas fa-globe-americas" />
                        </div>
                        <div className="col-md-11">
                            {theme.contacts.address}
                        </div>
                    </div>
                </li>
                <li>
                    <div className="row">
                        <div className="col-md-1">
                            <i className="footer-contacts__icon far fa-envelope" />
                        </div>
                        <div className="col-md-11">
                            {theme.contacts.email}
                        </div>
                    </div>
                </li>
                <li>
                    <div className="row">
                        <div className="col-md-1">
                            <i className="footer-contacts__icon fas fa-mobile-alt" />
                        </div>
                        <div className="col-md-11">
                            {`${theme.contacts.phone}`}
                        </div>
                    </div>
                </li>
                <li>
                    <div className="row">
                        <div className="col-md-1">
                            <i className="footer-contacts__icon far fa-clock" />
                        </div>
                        <div className="col-md-11">
                            Mon-Sat 9:00am - 5:00pm
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    );
}
