/* eslint-disable linebreak-style */
// Local
// const MyApiUrl = 'http://localhost:7765/api/';
// const ViewImg = 'http://localhost:7765/';

// Live
// const MyApiUrl = 'https://api.aaprobics.com/api/';
// const ViewImg = 'https://api.aaprobics.com/';

const MyApiUrl = 'https://aaprobics-api.onrender.com/api/';
const ViewImg = 'https://aaprobics-api.onrender.com/';

export { MyApiUrl, ViewImg };
