/* eslint-disable */
/* eslint-disable prefer-template */
/* eslint-disable max-len */
// react
import React, { useState } from 'react';
import axios from 'axios';
import { MyApiUrl, ViewImg } from '../services/service';

// third-party
import { Helmet } from 'react-helmet-async';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// data stubs
import theme from '../../data/theme';

export default function AccountPagePassword() {
    const UserID = localStorage.getItem("UserID");
    const UserPassword = localStorage.getItem("UserPassword");


    const [CurrentPassword, setCurrentPassword] = useState();
    const [Password, setPassword] = useState();
    const [PasswordAgain, setPasswordAgain] = useState();

    const ChangeCurrentPassword = (event) => {
        setCurrentPassword(event.target.value);
    };
    const ChangeNewPassword = (event) => {
        setPassword(event.target.value);
    };
    const ChangePasswordAgain = (event) => {
        setPasswordAgain(event.target.value);
    };
    const ChangePassword = () => {
        if (CurrentPassword == '' || CurrentPassword == null) {
            toast.warn('Please Enter Current Password!', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
            });
        } else if (CurrentPassword != UserPassword) {
            toast.warn('Please Enter Valid Current Password!', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
            });
        } else if (Password == '' || Password == null) {
            toast.warn('Please Enter New Password!', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
            });
        } else if (PasswordAgain == '' || PasswordAgain == null) {
            toast.warn('Please Re-enter New Password!', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
            });
        } else if (Password != PasswordAgain) {
            toast.warn('Re-entered Password & New Password Not Matched!', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
            });
        } else {
            const obj = {
                REGISTERED_USERS_PASSWORD: Password,
            };
            console.log(obj);
            axios.put(MyApiUrl + "UpdatePassword/" + UserID + "", obj).then((response) => {
                if (response.data == false) {
                    toast.error('Failed to Change Password!', {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'dark',
                    });
                } else {
                    toast.success('Passord Changed. Please Login Again!', {
                        position: 'top-right',
                        // autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'dark',
                    });
                    localStorage.clear();
                    window.location.href = "/";
                }
            });
        }
    }
    return (
        <div className="card">
            <Helmet>
                <title>{`Change Password — ${theme.name}`}</title>
            </Helmet>

            <div className="card-header">
                <h5>Change Password</h5>
            </div>
            <div className="card-divider" />
            <div className="card-body">
                <div className="row no-gutters">
                    <div className="col-12 col-lg-7 col-xl-6">
                        <div className="form-group">
                            <label htmlFor="password-current" className="designLabel">
                                Current Password&nbsp;
                                <span style={{ color: 'red' }}>*</span>
                            </label>
                            <input
                                type="password"
                                className="form-control designInputs"
                                id="password-current"
                                placeholder="Enter Current Password"
                                value={CurrentPassword}
                                onChange={ChangeCurrentPassword}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password-new" className="designLabel">
                                New Password&nbsp;
                                <span style={{ color: 'red' }}>*</span>
                            </label>
                            <input
                                type="password"
                                className="form-control designInputs"
                                id="password-new"
                                placeholder="Enter New Password"
                                value={Password}
                                onChange={ChangeNewPassword}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password-confirm" className="designLabel">
                                Re-enter New Password&nbsp;
                                <span style={{ color: 'red' }}>*</span>
                            </label>
                            <input
                                type="password"
                                className="form-control designInputs"
                                id="password-confirm"
                                placeholder="Re-enter New Password"
                                value={PasswordAgain}
                                onChange={ChangePasswordAgain}
                            />
                        </div>

                        <div className="form-group mt-5 mb-0">
                            <button type="button" className="btn btn-primary" onClick={ChangePassword}>Change</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
