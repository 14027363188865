/* eslint-disable quotes */
/* eslint-disable */
/* eslint-disable prefer-template */
/* eslint-disable max-len */
// react
import React, { useState } from "react";

// third-party
import { Helmet } from "react-helmet-async";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// application
import axios from "axios";
import PageHeader from "../shared/PageHeader";
import { MyApiUrl } from "../services/service";
import "../../scss/custom.css";
import SocialLinks from "../shared/SocialLinks";

// blocks
// import BlockMap from '../blocks/BlockMap';

// data stubs
import theme from "../../data/theme";

function SitePageContactUs() {
    const breadcrumb = [
        { title: "Home", url: "" },
        { title: "Contact Us", url: "" },
    ];
    const mystyle = {
        page: {
            marginRight: 10,
            backgroundColor: "#0484cc",
            color: "white",
            padding: 10,
            borderRadius: 100,
            width: 35,
        },
    };
    const [Email, setEmail] = useState("");
    const [Phone, setPhone] = useState("");
    const [Address, setAddress] = useState("");
    const [ContactUsFeilds, setContactUsFeilds] = useState({
        Name: "",
        Email: "",
        Subject: "",
        Message: "",
    });

    const GetContactUs = () => {
        axios({
            method: "GET",
            url: MyApiUrl + "ContactUs",
            headers: {
                "content-type": "application/json",
            },
        })
            .then((response) => {
                setEmail(response.data[0].CONTACT_EMAIL);
                setPhone(response.data[0].CONTACT_PHO);
                setAddress(response.data[0].CONTACT_ADDRESS);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const SendRequest = () => {
        const regmail = /^[a-zA-Z0-9]+@[a-zA-Z]+\.[a-zA-Z]+$/;
        if (ContactUsFeilds.Name === "" || ContactUsFeilds.Name == null) {
            toast.warn("Please Enter Your Name!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        } else if (ContactUsFeilds.Email === "" || ContactUsFeilds.Email == null) {
            toast.warn("Please Enter Your Email Address!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        } else if (!regmail.test(ContactUsFeilds.Email)) {
            toast.warn("Please Enter Valid Email Address!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        } else if (ContactUsFeilds.Subject === "" || ContactUsFeilds.Subject == null) {
            toast.warn("Please Enter Subject!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        } else if (ContactUsFeilds.Message === "" || ContactUsFeilds.Message == null) {
            toast.warn("Please Enter Valid Enquiry Message!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        } else {
            const obj = {
                CONTACT_ENQUIRY_NAME: ContactUsFeilds.Name,
                CONTACT_ENQUIRY_EMAIL: ContactUsFeilds.Email,
                CONTACT_ENQUIRY_SUBJECT: ContactUsFeilds.Subject,
                CONTACT_ENQUIRY_MESSAGE: ContactUsFeilds.Message,
            };
            axios.post(MyApiUrl + "ContactEnquiry", obj).then((response) => {
                if (response.data === true) {
                    toast.success("Your request has been sent successfully!", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                    setContactUsFeilds({
                        ...ContactUsFeilds,
                        Name: "",
                        Email: "",
                        Subject: "",
                        Message: "",
                    });
                } else {
                    toast.error("Failed to send a request!", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                    setContactUsFeilds({
                        ...ContactUsFeilds,
                        Name: "",
                        Email: "",
                        Subject: "",
                        Message: "",
                    });
                }
            });
        }
    };

    React.useEffect(() => {
        GetContactUs();
    }, []);
    return (
        <React.Fragment>
            <Helmet>
                <title>{`Contact Us — ${theme.name}`}</title>
            </Helmet>
            <PageHeader header="Contact Us" breadcrumb={breadcrumb} />
            <div className="block">
                <div className="container">
                    <div className="card mb-0">
                        <div className="card-body contact-us">
                            <div className="contact-us__container">
                                <div className="row">
                                    <div className="col-12 col-lg-6 pb-4 pb-lg-0">
                                        <h4 className="contact-us__header card-title">Get In Touch</h4>

                                        <div className="contact-us__address">
                                            <p>
                                                <i
                                                    className="footer-contacts__icon far fa-envelope"
                                                    style={mystyle.page}
                                                />
                                                <span style={{ fontSize: 20, color: "#0484cc", fontWeight: 600 }}>
                                                    Email
                                                </span>
                                                <br />
                                                <span style={{ marginLeft: 44 }}>
                                                    <a
                                                        href="mailto: info@aaprobics.com"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        {Email}
                                                    </a>
                                                </span>
                                                <br />
                                                <br />
                                                <i
                                                    className="footer-contacts__icon fas fa-mobile-alt"
                                                    style={mystyle.page}
                                                />
                                                <span style={{ fontSize: 20, color: "#0484cc", fontWeight: 600 }}>
                                                    Phone
                                                </span>
                                                <br />
                                                <span style={{ marginLeft: 44 }}>{Phone}</span>
                                                <br />
                                                <br />
                                                <i
                                                    className="footer-contacts__icon fas fa-globe-americas"
                                                    style={mystyle.page}
                                                />
                                                <span style={{ fontSize: 20, color: "#0484cc", fontWeight: 600 }}>
                                                    Address
                                                </span>
                                                <br />
                                                <p style={{ marginTop: "2%", marginLeft: "9%", width: "50%" }}>
                                                    {Address}
                                                </p>
                                                <br />
                                            </p>
                                            <div style={{ marginLeft: "9%" }}>
                                                <p>Follow us on social networks</p>
                                                <SocialLinks
                                                    className="footer-newsletter__social-links"
                                                    shape="circle"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-lg-6">
                                        <h4 className="contact-us__header card-title">Leave us a Message</h4>

                                        <form>
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="form-name" className="designLabel">
                                                        Your Name&nbsp;
                                                        <span style={{ color: "red" }}>*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="form-name"
                                                        className="form-control designInputs"
                                                        placeholder="Enter Your Name"
                                                        value={ContactUsFeilds.Name}
                                                        onChange={(event) => {
                                                            setContactUsFeilds({
                                                                ...ContactUsFeilds,
                                                                Name: event.target.value,
                                                            });
                                                        }}
                                                    />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="form-email" className="designLabel">
                                                        Email&nbsp;
                                                        <span style={{ color: "red" }}>*</span>
                                                    </label>
                                                    <input
                                                        type="email"
                                                        id="form-email"
                                                        className="form-control designInputs"
                                                        placeholder="Enter Email Address"
                                                        value={ContactUsFeilds.Email}
                                                        onChange={(event) => {
                                                            setContactUsFeilds({
                                                                ...ContactUsFeilds,
                                                                Email: event.target.value,
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="form-subject" className="designLabel">
                                                    Subject&nbsp;
                                                    <span style={{ color: "red" }}>*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    id="form-subject"
                                                    className="form-control designInputs"
                                                    placeholder="Enter Subject"
                                                    value={ContactUsFeilds.Subject}
                                                    onChange={(event) => {
                                                        setContactUsFeilds({
                                                            ...ContactUsFeilds,
                                                            Subject: event.target.value,
                                                        });
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="form-message" className="designLabel">
                                                    Enquiry Message&nbsp;
                                                    <span style={{ color: "red" }}>*</span>
                                                </label>
                                                <textarea
                                                    id="form-message"
                                                    value={ContactUsFeilds.Message}
                                                    placeholder="Enter Enquiry Message"
                                                    onChange={(event) => {
                                                        setContactUsFeilds({
                                                            ...ContactUsFeilds,
                                                            Message: event.target.value,
                                                        });
                                                    }}
                                                    className="form-control designInputs"
                                                    rows="4"
                                                />
                                            </div>
                                            <button type="button" onClick={SendRequest} className="btn btn-primary">
                                                Send Message
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PageHeader header="Find Us" />
            <div className="block">
                <div className="container">
                    <div className="card mb-0">
                        <div className="card-body contact-us">
                            <div className="block-map block">
                                <div className="block-map__body">
                                    <iframe
                                        title="Google Map"
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3897.272869488852!2d76.66023737411045!3d12.36464552789658!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3baf71f0e2073bd5%3A0x81f9662fbe9763d2!2sA%26A%20Probics!5e0!3m2!1sen!2sin!4v1708492428769!5m2!1sen!2sin"
                                        frameBorder="0"
                                        scrolling="no"
                                        marginHeight="0"
                                        marginWidth="0"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default SitePageContactUs;
