/* eslint-disable */
/* eslint-disable prefer-template */
/* eslint-disable max-len */
// react
import React, { useState } from 'react';
import axios from 'axios';
import { MyApiUrl, ViewImg } from '../services/service';

// third-party
import { Helmet } from 'react-helmet-async';

import PageHeader from '../shared/PageHeader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// data stubs
import theme from '../../data/theme';
import { Link, useLocation, useHistory } from "react-router-dom";

export default function ForgetPasswordChangePassword(props) {

    let history = useHistory();
    const location = useLocation();
    const Email = props.location.state.Email;
    const [Password, setPassword] = useState();
    const [PasswordAgain, setPasswordAgain] = useState();

    const ChangeNewPassword = (event) => {
        setPassword(event.target.value);
    };
    const ChangePasswordAgain = (event) => {
        setPasswordAgain(event.target.value);
    };
    const ChangePassword = () => {
        if (Password == '' || Password == null) {
            toast.warn('Please Enter New Password!', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
            });
        } else if (PasswordAgain == '' || PasswordAgain == null) {
            toast.warn('Please Re-enter New Password!', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
            });
        } else if (Password != PasswordAgain) {
            toast.warn('Re-entered Password & New Password Not Matched!', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
            });
        } else {
            const obj = {
                REGISTERED_USERS_EMAIL: Email,
                REGISTERED_USERS_PASSWORD: Password,
            };
            console.log(obj);
            axios.post(MyApiUrl + "ResetPassword", obj).then((response) => {
                if (response.data == false) {
                    toast.error('Failed to Change Password!', {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'dark',
                    });
                } else {
                    toast.success('Passord Changed. Please Login!', {
                        position: 'top-right',
                        // autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'dark',
                    });
                    history.push("/");
                }
            });
        }
    }

    const breadcrumb = [
        { title: 'Home', url: '' },
        { title: 'Forget Password', url: '/ForgetPassword' },
        { title: 'Verify OTP', url: '/ForgetPasswordOTPScreen' },
        { title: 'Change Password', url: '' },
    ];
    return (
        <React.Fragment>
            <Helmet>
                <title>{`Login — ${theme.name}`}</title>
            </Helmet>

            <PageHeader header="Change Password" breadcrumb={breadcrumb} />

            <div className="block">
                <div className="container">
                    <div className="row">
                        <div className="col-md-2">&nbsp;</div>
                        <div className="col-md-8 d-flex mt-4 mt-md-0">
                            <div className="card flex-grow-1 mb-0">
                                <div className="card-body">
                                    <h3 className="card-title">Change Password</h3>
                                    <form>
                                        <div className="row">
                                            <div className="col-12 col-lg-6 col-xl-6">
                                                <div className="form-group">
                                                    <label htmlFor="password-new" className="designLabel">
                                                        New Password&nbsp;
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </label>
                                                    <input
                                                        type="password"
                                                        className="form-control designInputs"
                                                        id="password-new"
                                                        placeholder="Enter New Password"
                                                        value={Password}
                                                        onChange={ChangeNewPassword}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-6 col-xl-6">
                                                <div className="form-group">
                                                    <label htmlFor="password-confirm" className="designLabel">
                                                        Re-enter New Password&nbsp;
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </label>
                                                    <input
                                                        type="password"
                                                        className="form-control designInputs"
                                                        id="password-confirm"
                                                        placeholder="Re-enter New Password"
                                                        value={PasswordAgain}
                                                        onChange={ChangePasswordAgain}
                                                    />
                                                </div>

                                                <div className="form-group mt-5 mb-0">
                                                    <button type="button" className="btn btn-primary" style={{ float: 'right' }} onClick={ChangePassword}>Change Password</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2">&nbsp;</div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
