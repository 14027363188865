/* eslint-disable */
/* eslint linebreak-style: ["error", "windows"] */
export default [
    // {
    //     key: 'bank',
    //     title: 'Direct bank transfer',
    //     description: 'Make your payment directly into our bank account. Please use your Order ID as the payment reference. '
    //         + 'Your order will not be shipped until the funds have cleared in our account.',
    // },
    // {
    //     key: 'check',
    //     title: 'Check payments',
    //     description: 'Please send a check to Store Name, Store Street, Store Town, Store State County, Store Postcode.',
    // },
    // {
    //     key: 'cash',
    //     title: 'Cash on delivery',
    //     description: 'Pay with cash upon delivery.',
    // },
    {
        key: 'PayU',
        title: 'PayU',
        description: 'PayU is the leading payments solution provider to 5 lakh+ businesses in India. PayU enables businesses to collect online & offline payments across 150+ payment.',
    },
];
