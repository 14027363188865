// react
/* eslint-disable */
/* eslint-disable prefer-template */
/* eslint-disable max-len */
// react
import {
    CART_ADD_ITEM,
    CART_REMOVE_ITEM,
    CART_UPDATE_QUANTITIES,
    CART_UPDATE_COUPONS,
    CART_REMOVE_COUPONS,
    EMPTY_CART_DETAILS,
} from "./cartActionTypes";
import axios from "axios";
import { MyApiUrl, ViewImg } from "../../components/services/service";

/**
 * @param {array} items
 * @param {object} product
 * @param {array} options
 * @return {number}
 */
function findItemIndex(items, product, options, Unit) {
    return items.findIndex((item) => {
        if (item.product.pkid !== product.pkid || item.unit !== Unit || item.options.length !== options.length) {
            return false;
        }

        for (let i = 0; i < options.length; i += 1) {
            const option = options[i];
            const itemOption = item.options.find(
                (itemOption) => itemOption.optionId === option.optionId && itemOption.valueId === option.valueId
            );

            if (!itemOption) {
                return false;
            }
        }

        return true;
    });
}

function search(ItemCount, myArray) {
    for (let i = 0; i < myArray.length; i++) {
        if (parseInt(myArray[i].DISCOUNT_NUMBER_OF_PRODUCTS) === parseInt(ItemCount)) {
            return myArray[i];
        } else {
            return 0;
        }
    }
}

function calcSubtotal(items) {
    return items.reduce((subtotal, item) => subtotal + item.total, 0);
}

function calcQuantity(items) {
    return items.reduce((quantity, item) => quantity + item.quantity, 0);
}

function calcTotal(subtotal, shippingCharge) {
    // return subtotal + extraLines.reduce((total, extraLine) => total + extraLine.price, 0);
    let amt = parseFloat(subtotal) + parseFloat(shippingCharge);
    return amt.toFixed(2);
}

function addItem(state, product, options, quantity, UnitAmount, Unit, unitPkid, GenDiscount) {
    const itemIndex = findItemIndex(state.items, product, options, Unit);

    let newItems;
    let { lastItemId } = state;

    if (itemIndex === -1) {
        lastItemId += 1;
        newItems = [
            ...state.items,
            {
                id: lastItemId,
                product: JSON.parse(JSON.stringify(product)),
                options: JSON.parse(JSON.stringify(options)),
                price: UnitAmount,
                total: UnitAmount * quantity,
                quantity,
                unit: Unit,
                unitPkid: unitPkid,
            },
        ];
    } else {
        const item = state.items[itemIndex];

        newItems = [
            ...state.items.slice(0, itemIndex),
            {
                ...item,
                quantity: item.quantity + quantity,
                total: (item.quantity + quantity) * item.price,
            },
            ...state.items.slice(itemIndex + 1),
        ];
    }

    const subtotal = calcSubtotal(newItems).toFixed(2);

    let extraLinesItems;

    let shippingCharge = 0;
    if (subtotal > 0 && subtotal <= 1000) {
        shippingCharge = (parseFloat(subtotal) / 100) * 8;
    } else if (subtotal > 1000 && subtotal <= 3000) {
        shippingCharge = (parseFloat(subtotal) / 100) * 7;
    } else if (subtotal > 3000 && subtotal <= 5000) {
        shippingCharge = (parseFloat(subtotal) / 100) * 5;
    } else if (subtotal > 5000 && subtotal <= 10000) {
        shippingCharge = (parseFloat(subtotal) / 100) * 3;
    } else {
        shippingCharge = 0;
    }

    const resultObject = search(newItems.length, GenDiscount);

    const GeneralDiscount = (
        (parseFloat(subtotal) / 100) *
        parseFloat(resultObject === 0 ? 0 : resultObject.DISCOUNT_PERCENTAGE)
    ).toFixed(2);

    const couponPercent = state.couponPercent;
    const couponPrice = ((parseFloat(subtotal) * parseFloat(couponPercent)) / 100).toFixed(2);

    // const total = calcTotal(subtotal, shippingCharge);

    const total = (parseFloat(subtotal) - (parseFloat(GeneralDiscount) + parseFloat(couponPrice))).toFixed(2);

    // const grandTotal =
    //     (parseFloat(total) - parseFloat(GeneralDiscount) - (parseFloat(total) * parseFloat(couponPercent)) / 100).toFixed(2);

    const grandTotal = (parseFloat(total) + parseFloat(shippingCharge)).toFixed(2);

    extraLinesItems = [
        {
            type: "shipping",
            title: "Shipping",
            price: shippingCharge.toFixed(2),
        },
    ];

    return {
        ...state,
        lastItemId,
        subtotal,
        total,
        items: newItems,
        extraLines: extraLinesItems,
        quantity: calcQuantity(newItems),
        couponPercent,
        couponPrice,
        grandTotal,
        GeneralDiscount,
    };
}

function removeItem(state, itemId, GenDiscount) {
    const { items } = state;
    const newItems = items.filter((item) => item.id !== itemId);
    const subtotal = calcSubtotal(newItems).toFixed(2);

    let extraLinesItems;

    let shippingCharge = 0;
    if (subtotal > 0 && subtotal <= 1000) {
        shippingCharge = (parseFloat(subtotal) / 100) * 8;
    } else if (subtotal > 1000 && subtotal <= 3000) {
        shippingCharge = (parseFloat(subtotal) / 100) * 7;
    } else if (subtotal > 3000 && subtotal <= 5000) {
        shippingCharge = (parseFloat(subtotal) / 100) * 5;
    } else if (subtotal > 5000 && subtotal <= 10000) {
        shippingCharge = (parseFloat(subtotal) / 100) * 3;
    } else {
        shippingCharge = 0;
    }

    const resultObject = search(newItems.length, GenDiscount);

    const GeneralDiscount = (
        (parseFloat(subtotal) / 100) *
        parseFloat(resultObject === 0 ? 0 : resultObject.DISCOUNT_PERCENTAGE)
    ).toFixed(2);

    const couponPercent = state.couponPercent;
    const couponPrice = ((parseFloat(subtotal) * parseFloat(couponPercent)) / 100).toFixed(2);

    // const total = calcTotal(subtotal, shippingCharge);

    const total = (parseFloat(subtotal) - (parseFloat(GeneralDiscount) + parseFloat(couponPrice))).toFixed(2);

    // const grandTotal =
    //     (parseFloat(total) - parseFloat(GeneralDiscount) - (parseFloat(total) * parseFloat(couponPercent)) / 100).toFixed(2);

    const grandTotal = (parseFloat(total) + parseFloat(shippingCharge)).toFixed(2);

    // const total = calcTotal(subtotal, shippingCharge);

    // const resultObject = search(newItems.length, GenDiscount);

    // const GeneralDiscount = (
    //     (parseFloat(total) / 100) *
    //     parseFloat(resultObject === 0 ? 0 : resultObject.DISCOUNT_PERCENTAGE)
    // ).toFixed(2);

    // const couponPercent = newItems.length > 0 ? state.couponPercent : 0;
    // const couponPrice = (newItems.length > 0 ? (parseFloat(total) * parseFloat(couponPercent)) / 100 : 0).toFixed(2);
    // const grandTotal = (
    //     parseFloat(total) -
    //     parseFloat(GeneralDiscount) -
    //     (parseFloat(total) * parseFloat(couponPercent)) / 100
    // ).toFixed(2);

    extraLinesItems = [
        {
            type: "shipping",
            title: "Shipping",
            price: shippingCharge.toFixed(2),
        },
    ];

    return {
        ...state,
        items: newItems,
        extraLines: extraLinesItems,
        quantity: calcQuantity(newItems),
        subtotal,
        total,
        couponPrice,
        couponPercent,
        grandTotal,
        GeneralDiscount,
    };
}

function removeCoupons(state) {
    const subtotal = state.subtotal;
    const couponPercent = 0;
    const couponPrice = 0;
    const couponPkid = "";
    const couponCode = "";
    const couponType = "";

    const GeneralDiscount = state.GeneralDiscount;

    let shippingCharge = 0;
    if (subtotal > 0 && subtotal <= 1000) {
        shippingCharge = (parseFloat(subtotal) / 100) * 8;
    } else if (subtotal > 1000 && subtotal <= 3000) {
        shippingCharge = (parseFloat(subtotal) / 100) * 7;
    } else if (subtotal > 3000 && subtotal <= 5000) {
        shippingCharge = (parseFloat(subtotal) / 100) * 5;
    } else if (subtotal > 5000 && subtotal <= 10000) {
        shippingCharge = (parseFloat(subtotal) / 100) * 3;
    } else {
        shippingCharge = 0;
    }

    // const total = calcTotal(subtotal, shippingCharge);

    const total = (parseFloat(subtotal) - (parseFloat(GeneralDiscount) + parseFloat(couponPrice))).toFixed(2);

    // const grandTotal =
    //     (parseFloat(total) - parseFloat(GeneralDiscount) - (parseFloat(total) * parseFloat(couponPercent)) / 100).toFixed(2);

    const grandTotal = (parseFloat(total) + parseFloat(shippingCharge)).toFixed(2);

    // const GeneralDiscount = state.GeneralDiscount;
    // const grandTotal = (
    //     parseFloat(total) -
    //     parseFloat(GeneralDiscount) -
    //     (parseFloat(total) * parseFloat(couponPercent)) / 100
    // ).toFixed(2);

    return {
        ...state,
        couponPrice,
        couponPercent,
        couponType,
        couponPkid,
        couponCode,
        grandTotal,
        total,
    };
}

function updateQuantities(state, quantities) {
    let needUpdate = false;

    const newItems = state.items.map((item) => {
        const quantity = quantities.find((x) => x.itemId === item.id && x.value !== item.quantity);

        if (!quantity) {
            return item;
        }

        needUpdate = true;

        return {
            ...item,
            quantity: quantity.value,
            total: quantity.value * item.price,
        };
    });

    if (needUpdate) {
        const subtotal = calcSubtotal(newItems).toFixed(2);

        let extraLinesItems;

        let shippingCharge = 0;
        if (subtotal > 0 && subtotal <= 1000) {
            shippingCharge = (parseFloat(subtotal) / 100) * 8;
        } else if (subtotal > 1000 && subtotal <= 3000) {
            shippingCharge = (parseFloat(subtotal) / 100) * 7;
        } else if (subtotal > 3000 && subtotal <= 5000) {
            shippingCharge = (parseFloat(subtotal) / 100) * 5;
        } else if (subtotal > 5000 && subtotal <= 10000) {
            shippingCharge = (parseFloat(subtotal) / 100) * 3;
        } else {
            shippingCharge = 0;
        }

        const GeneralDiscount = state.GeneralDiscount;

        const couponPercent = state.couponPercent;
        const couponPrice = ((parseFloat(subtotal) * parseFloat(couponPercent)) / 100).toFixed(2);

        // const total = calcTotal(subtotal, shippingCharge);

        const total = (parseFloat(subtotal) - (parseFloat(GeneralDiscount) + parseFloat(couponPrice))).toFixed(2);

        // const grandTotal =
        //     (parseFloat(total) - parseFloat(GeneralDiscount) - (parseFloat(total) * parseFloat(couponPercent)) / 100).toFixed(2);

        const grandTotal = (parseFloat(total) + parseFloat(shippingCharge)).toFixed(2);

        // const total = calcTotal(subtotal, shippingCharge);

        // const GeneralDiscount = state.GeneralDiscount;

        // const couponPercent = state.couponPercent;
        // const couponPrice = ((parseFloat(total) * parseFloat(couponPercent)) / 100).toFixed(2);
        // const grandTotal = (
        //     parseFloat(total) -
        //     parseFloat(GeneralDiscount) -
        //     (parseFloat(total) * parseFloat(couponPercent)) / 100
        // ).toFixed(2);

        extraLinesItems = [
            {
                type: "shipping",
                title: "Shipping",
                price: shippingCharge.toFixed(2),
            },
        ];

        return {
            ...state,
            items: newItems,
            extraLines: extraLinesItems,
            quantity: calcQuantity(newItems),
            subtotal,
            total,
            couponPrice,
            couponPercent,
            grandTotal,
        };
    }

    return state;
}

function updateCoupons(state, coupons, coupontype, couponpkid, couponcode) {
    let needUpdate = false;
    const newItems = state.items.map((item) => {
        needUpdate = true;
        return {
            ...item,
        };
    });

    if (needUpdate) {
        const subtotal = calcSubtotal(newItems);

        let extraLinesItems;

        let shippingCharge = 0;
        if (subtotal > 0 && subtotal <= 1000) {
            shippingCharge = (parseFloat(subtotal) / 100) * 8;
        } else if (subtotal > 1000 && subtotal <= 3000) {
            shippingCharge = (parseFloat(subtotal) / 100) * 7;
        } else if (subtotal > 3000 && subtotal <= 5000) {
            shippingCharge = (parseFloat(subtotal) / 100) * 5;
        } else if (subtotal > 5000 && subtotal <= 10000) {
            shippingCharge = (parseFloat(subtotal) / 100) * 3;
        } else {
            shippingCharge = 0;
        }

        const GeneralDiscount = state.GeneralDiscount;
        const couponPercent = coupons;

        const couponPrice = (parseFloat(subtotal) * parseFloat(couponPercent)) / 100;

        const total = (parseFloat(subtotal) - parseFloat(GeneralDiscount) - parseFloat(couponPrice)).toFixed(2);

        // const grandTotal =
        //     (parseFloat(total) - parseFloat(GeneralDiscount) - (parseFloat(total) * parseFloat(couponPercent)) / 100).toFixed(2);

        const grandTotal = (parseFloat(total) + parseFloat(shippingCharge)).toFixed(2);

        // const total = calcTotal(subtotal, shippingCharge);

        // const GeneralDiscount = state.GeneralDiscount;

        // const couponPercent = parseFloat(coupons);
        // const couponPrice = ((parseFloat(total) * parseFloat(coupons)) / 100).toFixed(2);
        // const grandTotal = (
        //     parseFloat(total) -
        //     parseFloat(GeneralDiscount) -
        //     (parseFloat(total) * parseFloat(couponPercent)) / 100
        // ).toFixed(2);

        extraLinesItems = [
            {
                type: "shipping",
                title: "Shipping",
                price: shippingCharge.toFixed(2),
            },
        ];

        return {
            ...state,
            items: newItems,
            extraLines: extraLinesItems,
            couponPrice: couponPrice,
            couponPercent: couponPercent,
            grandTotal: grandTotal,
            couponType: coupontype,
            couponPkid: couponpkid,
            couponCode: couponcode,
            total: total,
        };
    }

    return state;
}

function removeCart() {
    return {
        lastItemId: 0,
        quantity: 0,
        items: [],
        subtotal: 0,
        extraLines: [
            // {
            //     type: 'shipping',
            //     title: 'Shipping',
            //     price: 100,
            // },
        ],
        total: 0,
        couponPrice: 0,
        couponPercent: 0,
        GeneralDiscount: 0,
        grandTotal: 0,
        couponType: "",
        couponPkid: "",
        couponCode: "",
    };
}

const initialState = {
    lastItemId: 0,
    quantity: 0,
    items: [],
    subtotal: 0,
    extraLines: [
        // shipping, taxes, fees, .etc
        // {
        //     type: 'shipping',
        //     title: 'Shipping',
        //     price: 100,
        // },
    ],
    total: 0,
    couponPrice: 0,
    couponPercent: 0,
    grandTotal: 0,
    GeneralDiscount: 0,
    couponType: "",
    couponPkid: "",
    couponCode: "",
};

export default function cartReducer(state = initialState, action) {
    switch (action.type) {
        case CART_ADD_ITEM:
            return addItem(
                state,
                action.product,
                action.options,
                action.quantity,
                action.UnitAmount,
                action.Unit,
                action.unitPkid,
                action.GeneralDiscount
            );

        case CART_REMOVE_ITEM:
            return removeItem(state, action.itemId, action.GeneralDiscount);

        case CART_UPDATE_QUANTITIES:
            return updateQuantities(state, action.quantities);

        case CART_UPDATE_COUPONS:
            return updateCoupons(state, action.coupons, action.coupontype, action.couponpkid, action.couponcode);

        case CART_REMOVE_COUPONS:
            return removeCoupons(state);

        case EMPTY_CART_DETAILS:
            return removeCart();

        default:
            return state;
    }
}
