/* eslint-disable linebreak-style */
/* eslint-disable quotes */
/* eslint-disable */
/* eslint-disable prefer-template */
/* eslint-disable max-len */
// react
import React, { Component } from "react";
// third-party
import { Helmet } from "react-helmet-async";
import "react-toastify/dist/ReactToastify.css";
import PageHeader from "../shared/PageHeader";
import axios from "axios";
import { MyApiUrl, ViewImg } from "../services/service";

// data stubs
import theme from "../../data/theme";

class ShopPagePriceCalculation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            DiscountData: [],
        };
    }

    componentDidMount() {
        axios.get(MyApiUrl + "GetGeneralDiscount").then((response) => {
            if (response.data.length != 0) {
                this.setState({ DiscountData: response.data });
            } else {
                this.setState({ DiscountData: [] });
            }
        });
    }

    render() {
        const breadcrumb = [
            { title: "Home", url: "" },
            { title: "General Discount Calculation", url: "" },
        ];

        return (
            <React.Fragment>
                <Helmet>
                    <title>{`Price Calculation — ${theme.name}`}</title>
                </Helmet>

                <PageHeader header="General Discount Calculation" breadcrumb={breadcrumb} />
                <div className="cart block">
                    <div className="container">
                        <table className="cart__table cart-table" style={{ textAlign: "center" }}>
                            <thead className="cart-table__head">
                                <tr className="cart-table__row">
                                    <th className="cart-table__column" style={{ textAlign: "center" }}>
                                        Sl No
                                    </th>
                                    <th className="cart-table__column" style={{ textAlign: "center" }}>
                                        No Of Product
                                    </th>
                                    <th className="cart-table__column" style={{ textAlign: "center" }}>
                                        Discount (%)
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="cart-table__body">
                                {this.state.DiscountData.map((item, index) => {
                                    return (
                                        <tr>
                                            <td className="cart-table__column" style={{ textAlign: "center" }}>
                                                {index+1}
                                            </td>
                                            <td className="cart-table__column" style={{ textAlign: "center" }}>
                                                {item.DISCOUNT_NUMBER_OF_PRODUCTS} Products
                                            </td>
                                            <td className="cart-table__column" style={{ textAlign: "center" }}>
                                                {item.DISCOUNT_PERCENTAGE}%
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ShopPagePriceCalculation;
